import {AfterViewInit, Component} from '@angular/core';
import {ChartOrdersCountByRecipeBean, EntityNameBean} from '../../model/model';
import {RightsGuard} from '../../common/auth.guard';
import {KioskService} from '../../services/kiosk.service';
import {StoreManagerService} from '../../services/store-manager.service';
import {Helper} from '../../common/helper';
import {DashboardService} from '../../services/dashboard.service';

@Component({
    templateUrl: 'orders.charts.component.html',
})
export class OrdersChartsComponent implements AfterViewInit {

    selectedKiosk: EntityNameBean = {} as EntityNameBean;

    public lineChartOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        zoom: {
            enabled: true,
            mode: 'x',
            sensitivity: 0.00001,
            limits: {
                max: 10,
                min: 0.5
            }
        },
    };

    public colors: Array<any> = [
        {borderColor: 'rgb(255, 99, 132)'},
        {borderColor: 'rgb(255, 159, 64)'},
        {borderColor: 'rgb(255, 205, 86)'},
        {borderColor: 'rgb(75, 192, 192)'},
        {borderColor: 'rgb(54, 162, 235)'},
        {borderColor: 'rgb(153, 102, 255)'},
        {borderColor: 'rgb(201, 203, 207)'},
        {borderColor: 'rgb(154, 162, 235)'},
        {borderColor: 'rgb(2153, 102, 255)'},
        {borderColor: 'rgb(1, 203, 207)'},
    ];
    allKiosks = {} as EntityNameBean;

    constructor(private kioskService: KioskService,
                private storeManagerService: StoreManagerService,
                private dashboardService: DashboardService) {

        this.allKiosks.id = 0;
        this.selectedKiosk = this.allKiosks;
    }
    kiosks: EntityNameBean[];

    dateFrom: Date = new Date();
    dateTo: Date = new Date();

    compareBeans = Helper.compareBeans;
    ordersByDay: ChartOrdersCountByRecipeBean[];

    public notCustomizedChartData: Array<any>;
    public notCustomizedChartLabels: Array<any>;

    public customizedChartData: Array<any>;
    public customizedChartLabels: Array<any>;

    public earningsChartData: Array<any>;
    public earningsChartLabels: Array<any>;

    public notCustomizedRecipesChartData: Array<any>;
    public notCustomizedRecipesChartLabels: Array<any>;

    public customizedRecipesChartData: Array<any>;
    public customizedRecipesChartLabels: Array<any>;

    private static diff(date1: Date, date2: Date) {

        const timeDiff = date2.getTime() - date1.getTime();
        if (timeDiff < 0) {
            return 0;
        }
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            $('#select_kiosk').selectpicker('destroy').selectpicker();
        }, 1);

        this.dateFrom.setDate(this.dateTo.getDate() - 7);

        $('#presentation_from_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateFrom,
            format: 'DD/MM/YYYY'

        }).on('dp.change', (e) => {
            this.dateFrom = new Date(e.date);
            this.initCharts();
        });

        $('#presentation_to_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateTo,
            format: 'DD/MM/YYYY'

        }).on('dp.change', (e) => {
            this.dateTo = new Date(e.date);
            this.initCharts();
        });

        this.loadKiosks();
    }

    // private getDashboard() {
    //     this.dashboardService.getDashboard().subscribe(response => {
    //         this.dashboard = response.value;
    //         console.log(this.dashboard);
    //     });
    // }

    loadKiosks() {

        if (RightsGuard.isStoreManager()) {
            this.storeManagerService.getKiosks().subscribe(kiosks => {
                this.kiosks = kiosks.value;
                this.selectedKiosk = this.kiosks[0];
                this.onKioskChange();

                setTimeout(() => {
                    $('#select_kiosk').prop('disabled', false).selectpicker('destroy').selectpicker();
                }, 1);

            });

        } else {

            this.kioskService.getActiveKiosksNames().subscribe(kiosks => {
                this.kiosks = kiosks.value;
                this.kiosks.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
                this.selectedKiosk = this.kiosks[0];
                this.onKioskChange();

                setTimeout(() => {
                    $('#select_kiosk').prop('disabled', false).selectpicker('destroy').selectpicker();
                }, 1);
            });
        }

    }

    onKioskChange() {
        this.loadData();
    }

    private loadData() {
        this.dashboardService.getRecipes(
            this.selectedKiosk ? this.selectedKiosk.id : 0, this.dateFrom.getTime(), this.dateTo.getTime()).subscribe(response => {

            this.ordersByDay = response.value;
            this.initCharts();
        });
    }


    private initCharts() {

        this.notCustomizedChartData = [
            {data: [], label: 'Total', fill: false},
            {data: [], label: 'Kiosk', fill: false},
            {data: [], label: 'IOS', fill: false},
            {data: [], label: 'Android', fill: false},
            {data: [], label: 'Web', fill: false},
            {data: [], label: 'Cancelled', fill: false},
        ];

        this.customizedChartData = [
            {data: [], label: 'Total', fill: false},
            {data: [], label: 'Kiosk', fill: false},
            {data: [], label: 'IOS', fill: false},
            {data: [], label: 'Android', fill: false},
            {data: [], label: 'Web', fill: false},
        ];

        this.earningsChartData = [
            {data: [], label: 'Earnings', fill: false},
        ];

        this.notCustomizedRecipesChartData = [];
        this.customizedRecipesChartData = [];

        if (!this.notCustomizedChartLabels) {
            this.notCustomizedChartLabels = [];
        }
        this.notCustomizedChartLabels.length = 0;

        if (!this.customizedChartLabels) {
            this.customizedChartLabels = [];
        }
        this.customizedChartLabels.length = 0;

        if (!this.earningsChartLabels) {
            this.earningsChartLabels = [];
        }
        this.earningsChartLabels.length = 0;

        if (!this.customizedRecipesChartLabels) {
            this.customizedRecipesChartLabels = [];
        }
        this.customizedRecipesChartLabels.length = 0;

        if (!this.notCustomizedRecipesChartLabels) {
            this.notCustomizedRecipesChartLabels = [];
        }
        this.notCustomizedRecipesChartLabels.length = 0;


        for (const item of this.ordersByDay) {
            for (const recipe in item.data) {
                let found = false;
                for (const line of this.notCustomizedRecipesChartData) {
                    if (line.label == recipe) {
                        found = true;
                    }
                }
                if (!found) {
                    this.notCustomizedRecipesChartData.push({data: [], label: recipe, fill: false});
                    this.customizedRecipesChartData.push({data: [], label: recipe, fill: false});
                }
            }
        }

        if (this.notCustomizedRecipesChartData.length == 0) {
            this.notCustomizedRecipesChartData.push({
            data: [],
            label: '',
            fill: false
        });
        }
        if (this.customizedRecipesChartData.length == 0) {
            this.customizedRecipesChartData.push({
            data: [],
            label: '',
            fill: false
        });
        }

        const date = new Date(this.dateFrom);
        const dt = OrdersChartsComponent.diff(this.dateFrom, this.dateTo);

        for (let i = 0; i <= dt; i++) {

            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();

            const statsByDay = this.getOrderStatsByDay(year, month, day);
            const val = statsByDay ? statsByDay.totalCount : null;

            this.notCustomizedChartData[0].data.push(val ? val.count : 0);
            this.notCustomizedChartData[1].data.push(val ? val.countKiosk + val.countKioskCustomized : 0);
            this.notCustomizedChartData[2].data.push(val ? val.countAppIos + val.countAppIosCustomized : 0);
            this.notCustomizedChartData[3].data.push(val ? val.countAppAndroid + val.countAppAndroidCustomized : 0);
            this.notCustomizedChartData[4].data.push(val ? val.countWeb + val.countWebCustomized : 0);
            this.notCustomizedChartData[5].data.push(val ? val.countCancelled : 0);

            this.earningsChartData[0].data.push(val ? Math.round(val.price * 100) / 100 : 0);

            const totalCustomized = val ? val.countKioskCustomized + val.countAppIosCustomized + val.countAppAndroidCustomized
                + val.countWebCustomized : 0;

            this.customizedChartData[0].data.push(totalCustomized);
            this.customizedChartData[1].data.push(val ? val.countKioskCustomized : 0);
            this.customizedChartData[2].data.push(val ? val.countAppIosCustomized : 0);
            this.customizedChartData[3].data.push(val ? val.countAppAndroidCustomized : 0);
            this.customizedChartData[4].data.push(val ? val.countWebCustomized : 0);

            this.notCustomizedChartLabels.push(month + '/' + day);
            this.customizedChartLabels.push(month + '/' + day);
            this.earningsChartLabels.push(month + '/' + day);

            this.customizedRecipesChartLabels.push(month + '/' + day);
            this.notCustomizedRecipesChartLabels.push(month + '/' + day);

            for (const line of this.notCustomizedRecipesChartData) {
                line.data.push(0);
            }
            for (const line of this.customizedRecipesChartData) {
                line.data.push(0);
            }


            if (statsByDay) {
                for (const recipe in statsByDay.data) {
                    for (const line of this.customizedRecipesChartData) {
                        if (line.label == recipe) {
                            const value = statsByDay.data[recipe];
                            const totalCustomizedValue = value ? value.countKioskCustomized + value.countAppIosCustomized
                                + value.countAppAndroidCustomized + value.countWebCustomized : 0;

                            line.data.splice(-1, 1);
                            line.data.push(totalCustomizedValue);
                        }
                    }
                    for (const line of this.notCustomizedRecipesChartData) {
                        if (line.label == recipe) {
                            const value = statsByDay.data[recipe];

                            line.data.splice(-1, 1);
                            line.data.push(value ? value.count : 0);
                        }
                    }
                }
            }

            date.setDate(date.getDate() + 1);
        }
    }

    private getOrderStatsByDay(year: number, month: number, day: number): ChartOrdersCountByRecipeBean {

        for (const item of this.ordersByDay) {
            if (item.year == year && item.month == month && item.day == day) {
                return item;
            }
        }
        return null;
    }
}

