import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ImageBean, ResponseBean, ResponseItemBean} from '../model/model';
import {StorageAccessToken} from '../model/custom-model';

@Injectable()
export class UploadService extends BaseRequestService {

    getAccessToken(): Observable<StorageAccessToken> {
        return this.get('admin/upload/access_token');
    }

    delete(id: string): Observable<ResponseBean> {
        return this.del('admin/image/' + id + '/delete');
    }

    list(): Observable<ResponseItemBean<ImageBean[]>> {
        return this.get('admin/image/list');
    }

    uploadImage(data: any, tag: string): Observable<ResponseItemBean<string>> {
        return this.upload('admin/image/upload?tag=' + tag, data);
    }

    replaceImage(data: any, id: string, tag: string): Observable<ResponseItemBean<string>> {
        return this.upload('admin/image/' + id + '/replace?tag=' + tag, data);
    }

    replaceWith(id: string, idNew: string): Observable<ResponseItemBean<string>> {
        return this.get('admin/image/' + id + '/replace_with/' + idNew);
    }
}
