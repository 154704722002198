import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {OrderService} from '../../../services/order.service';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskBean, StepStateBean} from '../../../model/model';
import {Helper} from '../../../common/helper';

class StepStatusBean {
}

@Component({
    selector: 'kiosk-history-component',
    templateUrl: 'kiosk-history.component.html'
})
export class KioskHistoryComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    kiosk: KioskBean = {} as KioskBean;
    history: StepStatusBean[];
    selectedDate: Date;

    fields: Column[] = [
        new Column('date', 'Date').date(),
        new Column('orderId', 'Order Id'),
        new Column('stepId', 'Step Id'),
        new Column('step', 'Step'),
        new Column('stateCopy', 'State'),
        new Column('', 'Dispense Quantity').data(this, this.getDispenseQuantityString),
    ];


    constructor(private orderService: OrderService) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {

        this.kiosk = kiosk;

        this.selectedDate = new Date();
        this.selectedDate.setDate(this.selectedDate.getDate() - 1);

        $('#history_start_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.selectedDate

        }).on('dp.change', (e) => {

            this.selectedDate = new Date(e.date);
            this.getHistory();
        });

        this.getHistory();
    }

    getHistory() {
        this.table.setData([], this.fields);
    }

    getData(params) {

        this.orderService.getKioskStepsHistory(this.kiosk, this.selectedDate.getTime(), params.data.limit, params.data.offset)
            .subscribe(orders => {

                for (const order of orders.list) {
                    order['stateCopy'] = order.state;
                }

                params.success({
                    total: orders.pagesCount * params.data.limit,
                    rows: orders.list
                });
                params.complete();
            });
    }

    getDispenseQuantityString(step: StepStateBean) {

        if (!step.dispenseQuantity) {
            return '';
        }
        let result = '{';
        for (const key in step.dispenseQuantity) {
            result += (key + ': ' + step.dispenseQuantity[key] + '; ');
        }
        return result + '}';
    }

}
