import {publishReplay, refCount} from 'rxjs/operators';
import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomRecipeBean, PageBean, RecipeBean, ResponseBean} from '../model/model';

@Injectable()
export class RecipeService extends BaseRequestService {

    private allRecipes: Observable<any>;

    getAllRecipes(limit?: number, offset?: number): Observable<PageBean<RecipeBean>> {
        if (!this.allRecipes) {
            this.allRecipes = this.get('admin/recipe/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.allRecipes;
    }

    getRecipes(categoryId: number, limit?: number, offset?: number): Observable<PageBean<RecipeBean>> {
        return this.get('admin/recipe/category/' + categoryId + '/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    moveRecipe(categoryId: number, src: RecipeBean, dst: RecipeBean): Observable<ResponseBean> {
        return this.get('admin/recipe/category/' + categoryId + '/move_recipe/' + src.id + '/after/' + dst.id);
    }

    addRecipe(recipe: RecipeBean): Observable<ResponseBean> {
        return this.post('admin/recipe/add', recipe);
    }

    deleteRecipe(recipe: RecipeBean): Observable<ResponseBean> {
        return this.del('admin/recipe/' + recipe.id + '/delete');
    }

    updateRecipe(recipe: RecipeBean): Observable<ResponseBean> {
        return this.put('admin/recipe/update', recipe);
    }

    exportRecipes(recipes: RecipeBean[]): Observable<Blob> {
        let url = 'admin/recipe/export?';
        for (const recipe of recipes) {
            url += ('id=' + recipe.id + '&');
        }
        return this.blob(url);
    }

    deleteCustomRecipe(recipe: CustomRecipeBean): Observable<ResponseBean> {
        return this.del('admin/recipe/custom/' + recipe.id + '/delete');
    }

    getCustomRecipes(categoryId: number, limit?: number, offset?: number): Observable<PageBean<RecipeBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        p['custom'] = true;
        return this.get('admin/recipe/category/' + categoryId + '/list', p);
    }

    clear() {
        this.allRecipes = null;
    }

}
