import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {IngredientService} from '../../services/ingredient.service';
import {TableComponent} from '../../components/table.component';
import {IngredientsEditComponent} from './ingredients.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {IngredientBean} from '../../model/model';

@Component({
    templateUrl: 'ingredients.component.html',
})

export class IngredientsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(IngredientsEditComponent, {static: false}) modal: IngredientsEditComponent;

    fields: Column[] = [
        new Column('imageUrl', 'Image').thumbnail(),
        new Column('name', 'Name'),
        new Column('type', 'Type'),
        new Column('density', 'Density'),

        new Column('calories', 'Cals'),
        new Column('protein', 'Pro'),
        new Column('totalCarb', 'Carb'),
        new Column('dietaryFiber', 'Fiber'),
        new Column('sugar', 'Sugar'),
        new Column('totalFat', 'Fat'),
        new Column('saturatedFat', 'Sat. fat'),
        new Column('cholestrol', 'Cho'),
        new Column('vitaminA', 'Vit A'),
        new Column('vitaminC', 'Vit C'),
        new Column('calcium', 'Calc.'),
        new Column('iron', 'Iron'),
        new Column('pottasium', 'Pot.'),
        new Column('sodium', 'Sodium'),
        // new Column('polyunsaturatedFat', 'PUFA'),
        // new Column('monounsaturatedFat', 'MUFA'),
        // new Column('vitaminD', 'Vit D'),
        // new Column('vitaminE', 'Vit E'),
        // new Column('magnesium', 'Mag.'),
    ];

    constructor(private ingredientService: IngredientService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadIngredients();
    }

    loadIngredients() {
        this.ingredientService.getIngredients().subscribe(ingredients => {
            this.table.setData(ingredients.list, this.fields);
        });
    }

    onEdit(items: IngredientBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const ingredient of items) {

            this.ingredientService.deleteIngredient(ingredient).subscribe(response => {
                if (response.success) {
                    if (ingredient == items.pop()) {
                        this.loadIngredients();
                    }

                } else {
                    this.app.showError('Can\'t delete ingredient');
                }
            }, error => {
                this.app.showError('Can\'t delete ingredient');
            });
        }
    }

    onAdd() {
        this.modal.open(null, 'New Ingredient');
    }
}

