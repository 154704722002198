import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Helper} from '../common/helper';
import {ProgressService} from './progress.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'file-uploader',
    templateUrl: 'file-uploader.component.html',
    styleUrls: ['file-uploader.component.css']
})
export class FileUploaderComponent implements AfterViewInit {

    activeColor: 'green';
    baseColor: '#ccc';
    overlayColor: 'rgba(255,255,255,0.5)';

    dragging = false;
    loaded = false;
    imageLoaded = false;
    @Input() imageSrc: string = '';

    @Output() onImageRead = new EventEmitter<string>();

    @Input() name;
    @Input() height;
    @Output() onMediaLoaded = new EventEmitter<string>();

    @ViewChild('progress', {static: false}) progressElement: ElementRef;

    progress: JQuery;
    guid: string;

    totalLength: number;
    subscription: Subscription;
    uploading = false;
    postRequest: Subscription;
    fileData: any;

    constructor(private progressService: ProgressService) {
    }

    ngAfterViewInit(): void {

        this.guid = Helper.generateGUID();
        this.progress = $(this.progressElement.nativeElement);
        this.progress.attr('id', this.guid);

        this.uploading = false;
    }

    cancel(): void {
        this.uploading = false;
        if (this.postRequest) {
            this.postRequest.unsubscribe();
        }
    }

    subscribe() {
        $('#' + this.guid).width('0%');
        this.subscription = this.progressService.progressEventObservable.subscribe(
            (event) => {
                if (event.total == this.totalLength) {
                    const loadingProgress = Math.ceil(event.loaded / event.total * 100);
                    $('#' + this.guid).width('' + loadingProgress + '%');

                    if (event.loaded == event.total) {
                        this.uploading = false;
                        this.subscription.unsubscribe();
                    }
                }
            }
        );
    }

    handleDragEnter() {
        this.dragging = true;
    }

    handleDragLeave() {
        this.dragging = false;
    }

    handleDrop(e) {
        e.preventDefault();
        this.dragging = false;
        this.handleInputChange(e);
    }

    handleImageLoad() {
        this.imageLoaded = true;
    }

    handleInputChange(e) {
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        if (file) {
            const pattern = /image-*/;

            if (!file.type.match(pattern)) {
                alert('invalid format');
                return;
            }

            this.loaded = false;

            const fileReader = new FileReader();
            fileReader.onload = this.handleFileReaderLoaded.bind(this);
            fileReader.readAsArrayBuffer(file);

            const previewReader = new FileReader();
            previewReader.onload = this.handlePreviewReaderLoaded.bind(this);
            previewReader.readAsDataURL(file);
        }
    }

    handleFileReaderLoaded(e) {
        this.fileData = e.target.result;
    }

    handlePreviewReaderLoaded(e) {

        const reader = e.target;
        this.imageSrc = reader.result;
        this.loaded = true;
    }

}
