import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskBean} from '../../../model/model';

@Component({
    selector: 'kiosk-logs-component',
    templateUrl: 'kiosk-logs.component.html'
})
export class KioskLogsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    fields: Column[] = [
        new Column('date', 'Date').date(),
        new Column('state', 'State'),
        new Column('trigger', 'Trigger'),
        new Column('message', 'Message'),
    ];

    private params: any;
    private query: string;

    constructor(private kioskService: KioskService) {
    }

    ngAfterViewInit(): void {

    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        this.table.setData([], this.fields);
    }


    getData(params, query) {

        if (!query && params && this.params && params.data.offset == this.params.data.offset) {
            return;
        }

        if (params != null) {
            this.params = params;
        }
        if (query != null) {
            this.query = query;
        }

        this.kioskService.getStateHistory(this.kiosk.id, this.query).subscribe(response => {

            const lines = [];
            for (const order of response.list) {
                lines.push(order);
            }

            this.params.success({
                total: response.pagesCount * this.params.data.limit,
                rows: lines
            });
            this.params.complete();
        });
    }
}
