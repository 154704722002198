import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {CategoryService} from '../../services/category.service';
import {RecipeService} from '../../services/recipe.service';
import {Helper} from '../../common/helper';
import {CategoryBean} from '../../model/model';

@Component({
    templateUrl: 'custom-recipes.component.html',
})

export class CustomRecipesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    columns: Column[] = [
        new Column('creationDate', 'Date').date(),
        new Column('title', 'Title'),
    ];

    categories: CategoryBean[] = [];
    selectedCategory: CategoryBean = {} as CategoryBean;
    selectedCategoryId = 0;
    compareBeans = Helper.compareBeans;

    constructor(private customRecipeService: RecipeService,
                private categoryService: CategoryService,
                private route: ActivatedRoute,
                private location: Location,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadCategories();

        this.route.queryParams.subscribe(params => {
            this.selectedCategoryId = params['category'] != null ? params['category'] : 0;
        });
    }

    public loadCategories() {
        this.categoryService.getCategories().subscribe(categories => {

            this.categories = categories;
            if (this.selectedCategoryId == 0) {
                this.selectedCategory = categories[0];
            } else {
                for (const category of categories) {
                    if (category.id == this.selectedCategoryId) {
                        this.selectedCategory = category;
                    }
                }
            }

            setTimeout(() => {
                $('#selectpicker').selectpicker('destroy').selectpicker();
            }, 1);

            this.loadCustomRecipes();
        });
    }

    loadCustomRecipes() {
        this.table.setData([], this.columns);
    }

    onCategoryChange() {

        this.loadCustomRecipes();
        this.location.replaceState('/custom-recipes?category=' + this.selectedCategory.id);
    }

    onRemove(items) {
        for (const customRecipe of items) {

            this.customRecipeService.deleteCustomRecipe(customRecipe).subscribe(response => {
                if (response.success) {
                    this.loadCustomRecipes();

                } else {
                    this.app.showError('Can\'t delete Recipe');
                }
            }, error => {
                this.app.showError('Can\'t delete Recipe');
            });
        }
    }

    getData(params) {

        this.customRecipeService.getCustomRecipes(this.selectedCategory ? this.selectedCategory.id : 0,
            params.data.limit, params.data.offset).subscribe(customRecipes => {

            params.success({
                total: customRecipes.pagesCount * params.data.limit,
                rows: customRecipes.list
            });
            params.complete();
        });
    }


}

