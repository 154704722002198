import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {KioskService} from '../../../services/kiosk.service';
import {AppComponent} from '../../../app.component';
import {KioskBean, OrderThickness} from '../../../model/model';

@Component({
    selector: 'kiosk-actions-component',
    templateUrl: 'kiosk-actions.component.html'
})
export class KioskActionsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    ordersCount = 1;
    ordersThickness: OrderThickness = 'NORMAL';

    constructor(private dataService: DataService,
                private kioskService: KioskService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
    }

    restart() {
        this.kioskService.restartKiosk(this.kiosk).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Restarting...');
            } else {
                this.app.showNotification('Can\'t restart Kiosk');
            }
        }, error => {
            this.app.showNotification('Can\'t restart Kiosk');
        });
    }

    updateDeviceSW() {
        this.kioskService.updateDeviceSW(this.kiosk).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updating devices...');
            } else {
                this.app.showNotification('Can\'t update devices');
            }
        }, error => {
            this.app.showNotification('Can\'t update devices');
        });
    }

    updateConfig() {
        this.kioskService.updateConfig(this.kiosk).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Updating configuration...');
            } else {
                this.app.showNotification('Can\'t update configuration');
            }
        }, error => {
            this.app.showNotification('Can\'t update configuration');
        });
    }


    reset() {
        this.kioskService.resetKiosk(this.kiosk).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Resetting...');
            } else {
                this.app.showNotification('Can\'t reset Kiosk');
            }
        }, error => {
            this.app.showNotification('Can\'t reset Kiosk');
        });
    }

    placeOrders() {
        if (this.ordersCount <= 0 || this.ordersCount > 150) {
            this.app.showNotification('Wrong orders count');
            return;
        }

        this.kioskService.placeOrders(this.kiosk, this.ordersCount, this.ordersThickness).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Orders placed');
            } else {
                this.app.showNotification('Can\'t place orders');
            }
        }, error => {
            this.app.showNotification('Can\'t place orders');
        });
    }

    resumeTakingOrders() {
        this.kioskService.toggleTakingOrders(this.kiosk, false).subscribe(response => {
            if (response.success) {
                this.kiosk.takingOrdersPaused = false;
                this.app.showNotification('Orders taking resumed');
            } else {
                this.app.showNotification('Can\'t switch state');
            }
        }, error => {
            this.app.showNotification('Can\'t switch state');
        });
    }

    pauseTakingOrders() {
        this.kioskService.toggleTakingOrders(this.kiosk, true).subscribe(response => {
            if (response.success) {
                this.kiosk.takingOrdersPaused = true;
                this.app.showNotification('Orders taking paused');
            } else {
                this.app.showNotification('Can\'t switch state');
            }
        }, error => {
            this.app.showNotification('Can\'t switch state');
        });
    }
}
