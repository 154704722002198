import {AfterViewInit, Component, forwardRef, Inject, Input, OnDestroy} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {KioskBean} from '../../../model/model';
import {DiagnosticsStepService} from '../../../services/diagnosics-step.service';
import {KioskDataService} from '../../../services/kiosk-data.service';
import {AppSettings} from '../../../app.settings';
import {AppComponent} from '../../../app.component';
import {DataService} from '../../../services/data.service';

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    selector: 'kiosk-diagnostics-orders-component',
    templateUrl: 'kiosk-diagnostics-orders.component.html'
})
export class KioskDiagnosticsOrdersComponent implements AfterViewInit, OnDestroy {

    kiosk: KioskBean;
    channel: any;
    orders: any;
    loaded: boolean;
    @Input() diagnostics: boolean;

    constructor(private diagnosticsStepService: DiagnosticsStepService,
                private kioskService: KioskService,
                private dataService: DataService,
                private kioskDataService: KioskDataService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy() {
        try {
            firebase.app().delete();
        } catch (error) {
        }
    }

    onShow(kiosk: KioskBean) {
        if (this.kiosk) {
            return;
        }

        this.kiosk = kiosk;
        this.openFirebaseMessaging();
    }


    openFirebaseMessaging() {
        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            this.channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('kiosk')
                .child('' + this.kiosk.id)
                .child('order_steps_noc');

            this.channel.on('value', data => {
                if (data.val()) {
                    this.orders = data.val().value;

                } else {
                    this.orders = null;
                }

                this.loaded = true;
                console.log(this.orders);
            });
        });
    }

    restart(orderId: number, stepId: number) {
        this.diagnosticsStepService.startStep(this.kiosk.id, orderId, stepId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Started');
            } else {
                this.app.showError('Can\'t start step');
            }
        }, error => {
            this.app.showError('Can\'t start step');
        });
    }

    skip(orderId: number, stepId: number) {
        this.diagnosticsStepService.skipStep(this.kiosk.id, orderId, stepId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Skipped');
            } else {
                this.app.showError('Can\'t skip step');
            }
        }, error => {
            this.app.showError('Can\'t skip step');
        });
    }
}
