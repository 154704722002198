import {AfterViewInit, Component} from '@angular/core';
import {KioskBean, TemperatureChartBean} from '../../../model/model';
import {Helper} from '../../../common/helper';
import {KioskService} from '../../../services/kiosk.service';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

@Component({
    selector: 'kiosk-sensors-component',
    templateUrl: 'kiosk-sensors.component.html'
})
export class KioskSensorsComponent implements AfterViewInit {

    dateFrom: Date = new Date();
    dateTo: Date = new Date();
    kiosk: KioskBean;
    stats: TemperatureChartBean[];
    isCelsius = true;

    public lineChartOptions: any = {
        responsive: true,
    };

    public statsChartData: Array<any>;
    public statsChartLabels: Array<any>;

    constructor(private kioskService: KioskService) {
    }

    ngAfterViewInit(): void {

        this.dateFrom.setDate(this.dateFrom.getDate() - 3);

        $('#charts_from_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateFrom
        }).on('dp.change', (e) => {
            this.dateFrom = new Date(e.date);
            this.loadCharts();
        });

        $('#charts_to_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateTo
        }).on('dp.change', (e) => {
            this.dateTo = new Date(e.date);
            this.loadCharts();
        });

    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        setTimeout(() => {
            this.loadCharts();
        });
    }

    loadCharts() {

        console.log(this.dateFrom.getTime());
        console.log(this.dateTo.getTime());

        this.kioskService.getKioskTemperatureCharts(this.kiosk.id, this.dateFrom.getTime(), this.dateTo.getTime()).subscribe(response => {
            if (response.success) {

                this.stats = response.value;
                this.updateChart();
            }
        });
    }

    selectData(e) {
    }

    download() {
        this.kioskService.getKioskTemperatureChartsCSV(this.kiosk.id, this.dateFrom.getTime(), this.dateTo.getTime(),
            this.isCelsius ? 'c' : 'f').subscribe(response => {
            FileSaver.saveAs(response, this.kiosk.name + ' - Sensors' + '.csv');
        });
    }

    scaleChange($event) {
        this.updateChart();
    }

    private updateChart() {
        this.statsChartData = [];
        this.statsChartLabels = [];

        for (const data of this.stats) {
            this.statsChartData.push({data: [], label: data.deviceName, fill: false});
        }

        this.statsChartLabels.push('0');

        if (this.stats.length == 0) {
            this.statsChartData.push({data: [], label: 'No Data', fill: false});
            this.statsChartLabels.push('1');
        }


        for (let i = 0; i < this.stats.length; i++) {
            for (const key in this.stats[i].data) {

                if (this.stats[i]) {

                    const date = moment(new Date(+key)).tz('America/Los_Angeles');

                    if (i == 0) {
                        this.statsChartLabels.push(date.format('hh:mm A'));
                    }

                    const celsius = this.stats[i].data[key];
                    const fahrenheit = (celsius * 9 / 5) + 32;

                    this.statsChartData[i].data.push(this.isCelsius ? celsius : fahrenheit);
                }
            }
        }
    }
}
