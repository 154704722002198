import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {OrderService} from '../../services/order.service';
import {ActivatedRoute} from '@angular/router';
import {StepStateBean} from '../../model/model';
import {Helper} from '../../common/helper';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';

@Component({
    templateUrl: 'order-details.component.html',
})
export class OrderDetailsComponent implements AfterViewInit {

    orderId: number;
    steps: StepStateBean[];
    JSON = JSON;

    constructor(private orderService: OrderService,
                @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
                private route: ActivatedRoute) {
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.orderId = params['id'] != null ? params['id'] : 0;

            setTimeout(() => {
                this.layout.navbarComponent.setTitle('#' + this.orderId);
            }, 100);
        });

        this.loadOrderDetails();
    }

    public loadOrderDetails() {
        this.orderService.getOrderStepsHistory(this.orderId).subscribe(steps => {

            this.steps = [];
            for (const step of steps.value) {
                this.steps.push(Helper.copyObject(step));
            }

        });
    }


}
