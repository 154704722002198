export const environment = {
    production: true,
    SERVER_URL: 'https://apihk.6dbytes.com/v1/',

    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyDVJnhQ6u3WZodoDh9VXnj-XBssMbYwzRg',
        authDomain: 'sixdcloudhk.firebaseapp.com',
        databaseURL: 'https://sixdcloudhk.firebaseio.com',
        projectId: 'sixdcloudhk',
        storageBucket: 'sixdcloudhk.appspot.com',
        messagingSenderId: '364785365325'
    },

    FILE_STORAGE_UPLOAD_URL: 'https://www.googleapis.com/upload/storage/v1/b/6dbytes_images_hk/o?uploadType=media&name=',
    FILE_STORAGE_URL: 'https://storage.googleapis.com/6dbytes_images_hk/',
    VISION_SERVER: 'https://visionhk.6dbytes.com/api/'
};
