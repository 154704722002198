import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../../common/field';
import {KioskService} from '../../../services/kiosk.service';
import {KioskDevicesComponent} from './kiosk-devices.component';
import {AppComponent} from '../../../app.component';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {FieldsListComponent} from '../../../components/fields-list.component';
import {Helper} from '../../../common/helper';
import {KioskDataService} from '../../../services/kiosk-data.service';
import {DeviceTemplateService} from '../../../services/device-template.service';
import {DeviceBean, DeviceTemplateBean, IngredientBean, KioskBean, VisionObjectAreaBean} from '../../../model/model';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'kiosk-devices-edit-component',
    templateUrl: 'kiosk-devices.edit.component.html'
})

export class KioskDevicesEditComponent implements AfterViewInit {

    device: DeviceBean = {} as DeviceBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    fields: Field[] = [
        new Field('id', 'Device ID').disable(),
        new Field('name', 'Name').require(),
        new Field('disabled', 'Disabled', FieldType.CHECKBOX),
        new Field('operationType', 'Operation Type', FieldType.SELECT).setOptions(['NORMAL', 'SIMULATION', 'PSEUDO_SIM']),
    ];
    deviceTemplate: DeviceTemplateBean;
    kiosk: KioskBean;
    ingredients: IngredientBean[];
    compareBeans = Helper.compareBeans;
    devices: DeviceBean[];

    constructor(private kioskService: KioskService,
                private kioskDataService: KioskDataService,
                private sanitizer: DomSanitizer,
                private deviceTemplateService: DeviceTemplateService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent,
                @Inject(forwardRef(() => KioskDevicesComponent)) private parent: KioskDevicesComponent) {
    }

    range = (value) => {
        const a = [];
        for (let i = 0; i < value; ++i) {
            a.push(i);
        }
        return a;
    };

    ngAfterViewInit(): void {
        this.kioskDataService.getData().subscribe(kioskData => {
            this.ingredients = [];
            for (const ingredient of kioskData.value.ingredients) {
                this.ingredients.push(Helper.copyObject(ingredient));
            }
        });

    }


    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }


    open(kiosk: KioskBean, device: DeviceBean, devices: DeviceBean[]) {

        this.kiosk = kiosk;
        this.devices = devices;
        this.device = (device ? Helper.copyObject(device) : {} as DeviceBean);
        if (!this.device.canDispenseIngredients) {
            this.device.canDispenseIngredients = {};
        }

        this.base.show(device == null ? 'Create new Device' : 'Edit ' + device.name);
        this.onDeviceChanged();

        this.deviceTemplateService.getDeviceTemplates().subscribe(deviceTemplates => {

            for (const deviceTemplate of deviceTemplates.list) {
                if (deviceTemplate.id == this.device.deviceTemplateId) {
                    this.deviceTemplate = deviceTemplate;
                }
            }

            setTimeout(() => {
                $('#select_hw').selectpicker('destroy').selectpicker();
                $('#select_dqt').selectpicker('destroy').selectpicker();
                $('#edit_type').selectpicker('destroy').selectpicker();
            }, 1);
        });

    }


    getIngredients(ingredientType: string) {

        const result = [];
        for (const ingredient of this.ingredients) {
            if (ingredient.type == ingredientType) {
                result.push(ingredient);
            }
        }
        return result;
    }

    onDeviceChanged() {
        setTimeout(() => {
            for (const i of this.range(this.device.numberOfIngredients)) {
                $('#select_ingredient' + i).selectpicker('destroy').selectpicker();
            }
            $('#select_boosts').selectpicker('destroy').selectpicker();
        }, 1);
        if (!this.isIngredient()) {
            this.device.canDispenseIngredients = {};
        }
    }

    isIngredient() {
        return this.device.dispenserType == 'INGREDIENT';
    }

    isVision() {
        return this.device.abstractDeviceName == 'Vision';
    }


    save() {

        if (Object.keys(this.device.canDispenseIngredients).length == 0) {
            this.device.canDispenseIngredients = null;
        }

        for (const key in this.device.canDispenseIngredients) {
            if (!this.device.canDispenseIngredients[key]) {
                delete this.device.canDispenseIngredients[key];
            }
        }

        this.kioskService.updateDevice(this.kiosk, this.device).subscribe(response => {

            if (response.success) {
                this.parent.loadDevices();
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update device');
            }
        }, error => {
            this.app.showError('Can\'t update device');
        });
    }

    addRect() {
        this.parent.openRectEditDialog(this.device, null);
    }

    editRect(rect: VisionObjectAreaBean) {
        this.parent.openRectEditDialog(this.device, rect);
    }

    removeRect(obj: VisionObjectAreaBean) {

        const index = this.device.visionObjectAreas.indexOf(obj);
        if (index != -1) {
            this.device.visionObjectAreas.splice(index, 1);
        }
    }
}
