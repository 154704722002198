import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'dictionaryValues'})
export class KeysValuePipe implements PipeTransform {
    transform(value, args: string[]): any {
        const keys = [];
        for (const key in value) {
            keys.push({key, value: value[key]});
        }
        return keys;
    }
}

@Pipe({name: 'time'})
export class TimePipe implements PipeTransform {

    transform(value: number): string {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60);

        return (hours < 10 ? '0' : '') + hours + (minutes < 10 ? ':0' : ':') + minutes + (seconds < 10 ? ':0' : ':') + seconds;
    }

}
