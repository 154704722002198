import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Helper} from '../../common/helper';
import {DeviceProvisioningComponent} from './device-provisioning.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {AppComponent} from '../../app.component';
import {KioskService} from '../../services/kiosk.service';
import {OperatorsService} from '../../services/operators.service';
import {DeviceService} from '../../services/device.service';
import {DeviceBean, DeviceProvisioningBean, HardwareWithProvisioningBean, KioskBean, OperatorBean} from '../../model/model';

@Component({
    selector: 'device-provisioning-edit-component',
    templateUrl: 'device-provisioning.edit.component.html'
})

export class DeviceProvisioningEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    compareBeans = Helper.compareBeans;
    deviceHardware: HardwareWithProvisioningBean;

    operators: OperatorBean[] = [];
    selectedOperator: OperatorBean = {} as OperatorBean;
    kiosks: KioskBean[] = [];
    selectedKiosk: KioskBean = {} as KioskBean;
    devices: DeviceBean[];

    // selectedDevice: Device;


    isValid() {
        return () => {
            return true;
        };
    }

    constructor(private deviceService: DeviceService,
                private kioskService: KioskService,
                private operatorsService: OperatorsService,
                @Inject(forwardRef(() => DeviceProvisioningComponent)) private parent: DeviceProvisioningComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(deviceHardware: HardwareWithProvisioningBean, title: string) {
        this.deviceHardware = (deviceHardware ? Helper.copyObject(deviceHardware) : {} as HardwareWithProvisioningBean);
        this.base.show(title);

        this.loadLocations();
    }


    loadLocations() {

        if (this.operators.length == 0) {
            this.operatorsService.getOperators().subscribe(operators => {
                this.operators = operators;
                this.selectedOperator = this.findLocation();

                setTimeout(() => {
                    $('#edit_select_location').selectpicker('destroy').selectpicker();
                }, 1);

                this.loadKiosks();
            });
        } else {

            this.selectedOperator = this.findLocation();
            setTimeout(() => {
                $('#edit_select_location').selectpicker('destroy').selectpicker();
            }, 1);

            this.loadKiosks();
        }
    }

    loadKiosks() {

        this.kioskService.getKiosks(this.selectedOperator.id).subscribe(kiosks => {
            this.kiosks = kiosks.list;
            this.selectedKiosk = this.findKiosk();

            setTimeout(() => {
                $('#edit_select_kiosk').selectpicker('destroy').selectpicker();
            }, 1);

            this.loadDevices();
        });
    }

    loadDevices() {

        this.kioskService.getDevices(this.selectedKiosk).subscribe(devices => {
            this.devices = devices.list;
            // this.selectedDevice = this.findDevice();

            setTimeout(() => {
                $('#edit_select_device').selectpicker('destroy').selectpicker();
            }, 1);
        });
    }

    findLocation(): OperatorBean {
        for (const location of this.operators) {
            if (this.deviceHardware.locationId == location.id) {
                return location;
            }
        }
        return this.operators[0];
    }

    findKiosk(): KioskBean {
        for (const kiosk of this.kiosks) {
            if (this.deviceHardware.kioskId == kiosk.id) {
                return kiosk;
            }
        }
        return this.kiosks[0];
    }

    isDeviceChecked(device: DeviceBean) {
        if (this.deviceHardware.devices) {
            for (const d of this.deviceHardware.devices) {
                if (device.id == d.id) {
                    return true;
                }
            }
        }
        return false;
    }

    setDeviceChecked(ev, device: DeviceBean) {
        if (!this.deviceHardware.devices) {
            return;
        }
        if (ev.target.checked) {

            const dp = {} as DeviceProvisioningBean;
            dp.id = device.id;
            this.deviceHardware.devices.push(dp);

        } else {
            for (let i = 0; i < this.deviceHardware.devices.length; i++) {
                if (this.deviceHardware.devices[i].id == device.id) {
                    this.deviceHardware.devices.splice(i, 1);
                    break;
                }
            }
        }
    }

    onOperatorChange() {
        this.devices = [];
        this.deviceHardware.devices = [];
        this.loadKiosks();
    }

    onKioskChange() {
        this.devices = [];
        this.deviceHardware.devices = [];
        this.loadDevices();
    }

    save() {

        if (!this.selectedOperator) {
            this.app.showError('Please select Operator');
            return;
        }
        if (!this.selectedKiosk) {
            this.app.showError('Please select Location');
            return;
        }

        this.deviceHardware.locationId = this.selectedOperator.id;
        this.deviceHardware.kioskId = this.selectedKiosk.id;

        this.deviceService.updateDeviceHardwareProvisioning(this.deviceHardware).subscribe(response => {

            if (response.success) {
                this.parent.reload();
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t create Device Provisioning');
            }
        }, error => {
            this.app.showError('Can\'t create Device Provisioning');
        });

    }

}


