import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskBean, LogMessageBean, StepStateBean} from '../../../model/model';
import {Helper} from '../../../common/helper';

@Component({
    selector: 'device-logs-component',
    templateUrl: 'device-logs.component.html'
})
export class DeviceLogsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    dateFrom: Date = new Date();
    dateTo: Date = new Date();
    messages: LogMessageBean[];
    diagnosticStepsHistory: StepStateBean[];
    query: string;

    MODE_ALL = 0;
    MODE_DIAGNOSTICS = 1;
    MODE_DISPENSE = 2;
    mode: number;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    fields: Column[] = [
        new Column('date', 'Date').date(),
        new Column('device', 'Device'),
        new Column('step', 'Step'),
        new Column('step', 'Quantity').data(this, DeviceLogsComponent.getDispenseQuantityString)
    ];
    realDispenseStepsOnly = true;

    static isEmptyObject(obj): boolean {
        for (const name in obj) {
            if (obj.hasOwnProperty(name)) {
                return false;
            }
        }
        return true;
    }

    static getDispenseQuantityString(step: StepStateBean) {

        if (!step || !step.dispenseQuantity) {
            return '';
        }
        let result = '{';
        for (const key in step.dispenseQuantity) {
            result += (key + ': ' + step.dispenseQuantity[key] + '; ');
        }
        return result + '}';
    }

    constructor(private kioskService: KioskService) {
    }

    ngAfterViewInit(): void {

        this.dateFrom.setDate(this.dateFrom.getDate() - 1);

        $('#presentation_from_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateFrom
        }).on('dp.change', (e) => {
            this.dateFrom = new Date(e.date);
        });

        $('#presentation_to_date').datetimepicker({
            icons: Helper.faIcons,
            date: this.dateTo
        }).on('dp.change', (e) => {
            this.dateTo = new Date(e.date);
        });
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
    }

    loadDevicesLogs() {

        this.mode = this.MODE_ALL;
        this.messages = [];
        this.kioskService.getHWLogs(this.kiosk, this.dateFrom.getTime() + 60 * 60000,
            this.dateTo.getTime() + 60 * 60000, this.query).subscribe(response => {
            if (response.success) {
                this.messages = response.value;
            }

            if (this.messages.length == 0) {
                this.messages.push({message: 'No Data'} as LogMessageBean);
            }
        });
    }

    loadDiagnosticsSteps() {

        this.mode = this.MODE_DIAGNOSTICS;
        this.diagnosticStepsHistory = [];

        this.kioskService.getDiagnosticsStepsHistory(this.kiosk, this.dateFrom.getTime() + 60 * 60000,
            this.dateTo.getTime() + 60 * 60000).subscribe(response => {
            for (const step of response.list) {
                if (step.state == 'FINISHED') {
                    this.diagnosticStepsHistory.push(step);
                }
            }
            if (this.diagnosticStepsHistory.length == 0) {
                this.diagnosticStepsHistory.push({device: 'No Data'} as StepStateBean);
            }
        });
    }

    loadDispenseSteps() {

        this.mode = this.MODE_DISPENSE;
        this.diagnosticStepsHistory = [];

        this.kioskService.getDispenseStepsHistory(this.kiosk, this.dateFrom.getTime() + 60 * 60000, this.dateTo.getTime() + 60 * 60000,
            this.realDispenseStepsOnly ? 'NORMAL' : null).subscribe(response => {
            this.diagnosticStepsHistory = [];
            for (const step of response.list) {
                if (step.dispenseQuantity != null && !DeviceLogsComponent.isEmptyObject(step.dispenseQuantity)) {
                    if (step.state == 'FINISHED') {
                        step.step = step.step.replace('Dispense', '');
                        this.diagnosticStepsHistory.push(step);
                    }
                }
            }
            if (this.diagnosticStepsHistory.length == 0) {
                this.diagnosticStepsHistory.push({device: 'No Data'} as StepStateBean);
            }

            this.table.setData(this.diagnosticStepsHistory, this.fields);
        });
    }


}
