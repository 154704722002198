import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {AppComponent} from '../../app.component';
import {ProfileService} from '../../services/profile.service';

@Component({
    templateUrl: 'notifications.component.html',
})

export class NotificationsComponent implements AfterViewInit {

    message: string;
    inactiveDays: number;

    constructor(private profileService: ProfileService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    send() {
        this.profileService.sendMessage(this.message, this.inactiveDays).subscribe(response => {

            if (response.success) {
                this.app.showNotification(response.value);

            } else {
                this.app.showError('Can\'t send notifications');
            }
        }, error => {
            this.app.showError('Can\'t send notifications');
        });

    }
}

