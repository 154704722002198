import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../../common/field';
import {KioskBean} from '../../../model/model';
import {AppComponent} from '../../../app.component';
import {FieldsListComponent} from '../../../components/fields-list.component';

@Component({
    selector: 'kiosk-rewards-component',
    templateUrl: 'kiosk-rewards.component.html'
})
export class KioskRewardsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    @ViewChild('kioskFieldsListComponent', {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        new Field('pointsPerOrder', 'Points per order', FieldType.NUMBER),
        new Field('pointsPerFreeDrink', 'Points per free drink', FieldType.NUMBER),
        new Field('pointsPerReferral', 'Points per referral', FieldType.NUMBER),
        new Field('pointsPerReferralConversion', 'Points per referral conversion', FieldType.NUMBER)
    ];

    constructor(@Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
    }
}
