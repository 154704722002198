/* tslint:disable */
import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import {ResponseBean, ResponseItemBean} from '../model/model';
import {HttpHeaders} from '@angular/common/http';


export class LearnTag {
    tag: string;
    x_max: number;
    x_min: number;
    y_max: number;
    y_min: number;
}

export class LearnResult {
    tags: LearnTag[];
    date: number;
    path: string;
    preview: string;
}

export class DetectionResult {
    tag: string;
    date: number;
    source_image: string;
    result_image: string;
    result: string;
    kiosk_id: number;
    device_name: string;
}

export class TrainingImage {
    date: number;
    url: string;
}

@Injectable()
export class VisionService extends BaseRequestService {

    getImages(): Observable<ResponseItemBean<LearnResult[]>> {
        return this.get('image');
    }

    getTags(): Observable<ResponseItemBean<string[]>> {
        return this.get('tags');
    }

    getDeviceNames(): Observable<ResponseItemBean<string[]>> {
        return this.get('device_names');
    }

    getDetectionResults(deviceName: string, kioskId: number): Observable<ResponseItemBean<DetectionResult[]>> {
        const p = {};
        if (deviceName != null) {
            p['device_name'] = deviceName;
        }
        if (kioskId != null) {
            p['kiosk_id'] = kioskId;
        }
        return this.get('detection_result', p);
    }

    addImageToTrainingData(tag: string, resultData: DetectionResult, x1: number, x2: number, y1: number, y2: number):
        Observable<ResponseBean> {
        const body = {
            kiosk_id: resultData.kiosk_id,
            device_name: resultData.device_name,
            tag,
            image_url: resultData.source_image,
            x1,
            x2,
            y1,
            y2
        };
        console.log(body);
        return this.post('image/add', body);
    }

    addTagToImage(tag: string, resultData: LearnResult, x1: number, x2: number, y1: number, y2: number): Observable<ResponseBean> {
        let body = {
            tag,
            image_url: resultData.path,
            x1,
            x2,
            y1,
            y2
        };
        console.log(body);
        return this.post('image/add/tag', body);
    }

    deleteDetectionResults(deviceName: string): Observable<ResponseBean> {
        return this.del('detection_result/' + deviceName + '/delete');
    }

    deleteDetectionResult(result: DetectionResult): Observable<ResponseBean> {
        return this.del('detection_result/' + result.device_name + '/' + result.date + '/delete');
    }

    /* deleteTrainingData(deviceName: string): Observable<Boolean> {
         return null;//this.call('cloud_delete_training_data', [tag.name]);
     }

     deleteTrainingImage(image_url: string): Observable<Boolean> {
         return null;//this.call('cloud_delete_data_image', [image_url]);
     }
     */

    deleteLearningResult(result: LearnResult): Observable<ResponseBean> {
        const p = {};
        p['url'] = result.path;
        return this.del('image/delete', p);
    }

    protected serverUrl(): string {
        return environment.VISION_SERVER;
    }

    protected headers(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Basic NmQ6cmVtb3RlNmRieXRlcw=='
        });
    }
}
