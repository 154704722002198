import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {DiagnosticsStepSequenceBean, PageBean, ResponseBean} from '../model/model';

@Injectable()
export class DiagnosticsStepService extends BaseRequestService {

    getStepsSequences(kioskModelId: number, limit?: number, offset?: number): Observable<PageBean<DiagnosticsStepSequenceBean>> {
        return this.get('admin/diagnostics/kiosk_model/' + kioskModelId + '/step/list',
            BaseRequestService.buildOffsetParams(limit, offset));
    }

    addStepSequence(kioskModelId: number, step: DiagnosticsStepSequenceBean): Observable<ResponseBean> {
        return this.post('admin/diagnostics/kiosk_model/' + kioskModelId + '/step/add', step);
    }

    deleteStepSequence(kioskModelId: number, step: DiagnosticsStepSequenceBean): Observable<ResponseBean> {
        return this.del('admin/diagnostics/kiosk_model/' + kioskModelId + '/step/' + step.id + '/delete');
    }

    updateStepSequence(kioskModelId: number, step: DiagnosticsStepSequenceBean): Observable<ResponseBean> {
        return this.put('admin/diagnostics/kiosk_model/' + kioskModelId + '/step/update', step);
    }

    startStep(kioskId: number, orderId: number, stepId: number): Observable<ResponseBean> {
        return this.get('admin/kiosk/' + kioskId + '/diagnostics/order/' + orderId + '/step/' + stepId + '/start');
    }

    skipStep(kioskId: number, orderId: number, stepId: number): Observable<ResponseBean> {
        return this.get('admin/kiosk/' + kioskId + '/diagnostics/order/' + orderId + '/step/' + stepId + '/skip');
    }
}
