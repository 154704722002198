import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    ChartMaintenanceCountBean,
    ChartOrderDistributionBean,
    CursorList,
    CustomizationCountByMonthChartBean,
    DeviceBean,
    DeviceMessageBean,
    DeviceOperationType,
    DeviceStateHistoryBean,
    DiagnosticsStepSequenceBean,
    EntityNameBean,
    IngredientWeightChartBean,
    KioskBean,
    KioskContentBean,
    KioskSnapshotBean,
    KioskState,
    KioskStateHistoryBean,
    KioskStatsBean,
    LogMessageBean,
    OrderThickness,
    PageBean,
    RecipeCountByMonthChartBean,
    ResponseBean,
    ResponseItemBean,
    RobotPoseBean,
    SimDeviceProcessBean,
    StepStateBean,
    TemperatureChartBean
} from '../model/model';

@Injectable()
export class KioskService extends BaseRequestService {

    private static buildPath(kiosk: KioskBean): string {
        if (!kiosk) {
            return '';
        }
        return 'admin/kiosk/' + kiosk.id + '/location/' + kiosk.operatorId + '/';
    }

    addKiosk(kiosk: KioskBean): Observable<ResponseItemBean<number>> {
        return this.post('admin/kiosk/add', kiosk);
    }

    updateKiosk(kiosk: KioskBean): Observable<ResponseBean> {
        return this.put('admin/kiosk/update', kiosk);
    }

    getKiosk(locationId: number, kioskId: number): Observable<ResponseItemBean<KioskBean>> {
        return this.get('admin/kiosk/' + kioskId + '/location/' + locationId);
    }

    getKiosks(locationId: number): Observable<PageBean<KioskBean>> {
        return this.get('admin/kiosk/location/' + locationId + '/all_kiosks');
    }

    getAllKiosks(steps: boolean = true): Observable<ResponseItemBean<KioskBean[]>> {
        const p = {};
        p['steps'] = steps;
        return this.get('admin/kiosk/list', p);
    }

    getActiveKiosksNames(production: boolean = false): Observable<ResponseItemBean<EntityNameBean[]>> {
        const p = {};
        p['production'] = production;
        return this.get('admin/kiosk/list_active', p);
    }

    deleteKiosk(kiosk: KioskBean): Observable<ResponseBean> {
        return this.del(KioskService.buildPath(kiosk) + 'delete');
    }

    setDiagnosticsState(kiosk: KioskBean, enable: boolean): Observable<ResponseItemBean<KioskState>> {
        const p = {};
        p['enable'] = enable;
        return this.get(KioskService.buildPath(kiosk) + 'diagnostics', p);
    }

    setSimulatorState(kiosk: KioskBean, enable: boolean): Observable<ResponseItemBean<KioskState>> {
        const p = {};
        p['enable'] = enable;
        return this.get(KioskService.buildPath(kiosk) + 'simulator', p);
    }

    updateDeviceProcessTime(kiosk: KioskBean, processes: SimDeviceProcessBean[]): Observable<ResponseBean> {
        return this.put(KioskService.buildPath(kiosk) + 'update_device_process_time', processes);
    }

    getDeviceProcessTime(kiosk: KioskBean): Observable<ResponseItemBean<SimDeviceProcessBean[]>> {
        return this.get(KioskService.buildPath(kiosk) + 'device_process_time');
    }

    sendDiagnosticsStepSequence(kiosk: KioskBean, step: DiagnosticsStepSequenceBean, repeatCount: number): Observable<ResponseBean> {
        const p = {};
        p['repeat_count'] = repeatCount;
        return this.get(KioskService.buildPath(kiosk) + 'diagnostics/execute_sequence/' + step.id, p);
    }

    sendDiagnosticsStep(kiosk: KioskBean, stepJson: string): Observable<ResponseBean> {
        return this.post(KioskService.buildPath(kiosk) + 'send_diagnostics_step', stepJson);
    }

    changeDiagnosticsStates(kiosk: KioskBean): Observable<ResponseBean> {
        const p = {};
        p['auto_pickup'] = kiosk.autoPickup;
        p['auto_refill'] = kiosk.autoRefill;
        p['auto_reset'] = kiosk.autoReset;
        p['pseudo_sim_errors'] = kiosk.pseudoSimErrors;
        p['robot_sim'] = kiosk.robotSim;
        p['robot_auto_recovery'] = kiosk.robotAutoRecovery;
        return this.get(KioskService.buildPath(kiosk) + 'change_diagnostics_states', p);
    }

    updateDevice(kiosk: KioskBean, device: DeviceBean): Observable<ResponseBean> {
        return this.put(KioskService.buildPath(kiosk) + 'device/update', device);
    }

    getDevice(kiosk: KioskBean, deviceId: number): Observable<ResponseItemBean<DeviceBean>> {
        return this.get(KioskService.buildPath(kiosk) + 'device/' + deviceId);
    }

    executeShellCommand(kiosk: KioskBean, deviceId: number, command: string): Observable<ResponseItemBean<string>> {
        return this.get('admin/kiosk/' + kiosk.id + '/device/' + deviceId + '/execute_shell_command?cmd=' + command);
    }

    getDevices(kiosk: KioskBean, limit?: number, offset?: number): Observable<PageBean<DeviceBean>> {
        return this.get(KioskService.buildPath(kiosk) + 'device/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    getDeviceStateHistory(kiosk: KioskBean, deviceId: number, limit?: number, offset?: number):
        Observable<PageBean<DeviceStateHistoryBean>> {
        return this.get(KioskService.buildPath(kiosk) + 'device/' + deviceId + '/state_history/list',
            BaseRequestService.buildOffsetParams(limit, offset));
    }

    deleteDeviceStateHistory(kiosk: KioskBean, deviceId: number, history: DeviceStateHistoryBean): Observable<ResponseBean> {
        return this.del(KioskService.buildPath(kiosk) + 'device/' + deviceId + '/state_history/' + history.id + '/delete');
    }

    clearSynchronizationErrors(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get(KioskService.buildPath(kiosk) + 'clear_sync_errors');
    }

    getSnapshots(locationId: number, kioskId: number, startTime: number, endTime: number): Observable<ResponseItemBean<KioskSnapshotBean>> {

        const p = {};
        p['location_id'] = locationId;
        p['kiosk_id'] = kioskId;
        p['start_time'] = startTime;
        p['end_time'] = endTime;

        return this.get('kiosk/presentation', p);
    }

    getKioskTemperatureCharts(kioskId: number, startTime: number, endTime: number): Observable<ResponseItemBean<TemperatureChartBean[]>> {
        const p = {};
        p['start_time'] = startTime;
        p['end_time'] = endTime;

        return this.get('admin/kiosk/' + kioskId + '/charts/temperature/', p);
    }

    getCustomizationCharts(kioskId: number, duration: string): Observable<ResponseItemBean<CustomizationCountByMonthChartBean[]>> {

        return this.get('admin/kiosk/' + kioskId + '/charts/customizations/recipes/' + duration);
    }

    getCustomizationIngredientsCharts(kioskId: number, recipeId: number, duration: string):
        Observable<ResponseItemBean<CustomizationCountByMonthChartBean[]>> {

        return this.get('admin/kiosk/' + kioskId + '/charts/customizations/recipe/' + recipeId + '/ingredients/' + duration);
    }

    getRecipesCharts(kioskId: number, duration: string): Observable<ResponseItemBean<RecipeCountByMonthChartBean[]>> {

        return this.get('admin/kiosk/' + kioskId + '/charts/recipes/' + duration);
    }

    getIngredientsCharts(kioskId: number, duration: string): Observable<ResponseItemBean<IngredientWeightChartBean[]>> {

        return this.get('admin/kiosk/' + kioskId + '/charts/ingredients/' + duration);
    }

    getMaintenanceCharts(kioskId: number, duration: string): Observable<ResponseItemBean<ChartMaintenanceCountBean[]>> {

        return this.get('admin/kiosk/' + kioskId + '/charts/maintenance/' + duration);
    }

    getTimeCharts(kioskId: number, startTime: number, endTime: number): Observable<ResponseItemBean<ChartOrderDistributionBean>> {

        const p = {};
        p['start_time'] = startTime;
        p['end_time'] = endTime;
        return this.get('admin/kiosk/' + kioskId + '/charts/order_distribution/', p);
    }

    getRecipesChartsCSV(kioskId: number, duration: string): Observable<Blob> {
        return this.blob('admin/kiosk/' + kioskId + '/charts/recipes/' + duration + '/download');
    }

    getMaintenanceChartsCSV(kioskId: number, duration: string): Observable<Blob> {
        return this.blob('admin/kiosk/' + kioskId + '/charts/maintenance/' + duration + '/download');
    }

    getIngredientsChartsCSV(kioskId: number, duration: string): Observable<Blob> {
        return this.blob('admin/kiosk/' + kioskId + '/charts/ingredients/' + duration + '/download');
    }

    getCustomizationChartsCSV(kioskId: number, duration: string): Observable<Blob> {

        return this.blob('admin/kiosk/' + kioskId + '/charts/customizations/recipes/' + duration + '/download');
    }

    getCustomizationIngredientsChartsCSV(kioskId: number, recipeId: number, duration: string): Observable<Blob> {
        return this.blob('admin/kiosk/' + kioskId + '/charts/customizations/recipe/' + recipeId + '/ingredients/' + duration + '/download');
    }

    getKioskTemperatureChartsCSV(kioskId: number, startTime: number, endTime: number, scale: string): Observable<Blob> {
        const p = {};
        p['start_time'] = startTime;
        p['end_time'] = endTime;
        p['scale'] = scale;

        return this.blob('admin/kiosk/' + kioskId + '/charts/temperature/download', p);
    }

    refillIngredient(kiosk: KioskBean, deviceId: number): Observable<ResponseBean> {
        return this.get(KioskService.buildPath(kiosk) + 'device/' + deviceId + '/refill_ingredient');
    }

    unlockDevice(kiosk: KioskBean, deviceId: number): Observable<ResponseBean> {
        return this.get('admin/kiosk/' + kiosk.id + '/device/' + deviceId + '/unlock');
    }

    getKioskStats(): Observable<KioskStatsBean[]> {
        return this.get('admin/kiosk/stats');
    }

    getDeviceMessages(): Observable<DeviceMessageBean[]> {
        return this.get('admin/kiosk/device_messages');
    }

    getHWLogs(kiosk: KioskBean, startDate: number, endDate: number, query: string): Observable<ResponseItemBean<LogMessageBean[]>> {
        const p = {};
        p['start_date'] = startDate;
        p['end_date'] = endDate;
        if (query) {
            p['query'] = query;
        }
        return this.get(KioskService.buildPath(kiosk) + 'device/hw_logs', p);
    }

    requestRobotPose(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get(KioskService.buildPath(kiosk) + 'request_robot_pose');
    }

    sendRobotPose(kiosk: KioskBean, pose: RobotPoseBean): Observable<ResponseBean> {
        return this.post(KioskService.buildPath(kiosk) + 'send_pose_change', pose);
    }

    restartKiosk(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get(KioskService.buildPath(kiosk) + 'restart');
    }


    toggleTakingOrders(kiosk: KioskBean, paused: boolean): Observable<ResponseBean> {
        const p = {};
        p['paused'] = paused;
        return this.get('admin/kiosk/' + kiosk.id + '/set_paused', p);
    }

    resetKiosk(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get('admin/kiosk/' + kiosk.id + '/reset_kiosk');
    }

    placeOrders(kiosk: KioskBean, cnt: number, thickness: OrderThickness): Observable<ResponseBean> {
        const p = {};
        p['number'] = cnt;
        p['thickness'] = thickness;
        return this.get('admin/kiosk/' + kiosk.id + '/place_random_orders', p);
    }

    updateDeviceSW(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get(KioskService.buildPath(kiosk) + 'update_device_sw');
    }

    updateConfig(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get(KioskService.buildPath(kiosk) + 'notify_config_update');
    }

    getDiagnosticsStepsHistory(kiosk: KioskBean, startTime: number, endTime: number, limit?: number, offset?: number):
        Observable<PageBean<StepStateBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        if (startTime != null) {
            p['startTime'] = startTime;
            p['endTime'] = endTime;
        }
        return this.get(KioskService.buildPath(kiosk) + 'diagnostics_steps_history', p);
    }

    getDispenseStepsHistory(kiosk: KioskBean, startTime: number, endTime: number, deviceOpType: DeviceOperationType,
                            limit?: number, offset?: number): Observable<PageBean<StepStateBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        if (startTime != null) {
            p['startTime'] = startTime;
            p['endTime'] = endTime;
            p['deviceOpType'] = deviceOpType;
        }

        return this.get(KioskService.buildPath(kiosk) + 'dispense_steps_history', p);
    }

    getKioskContent(operatorId: number, kioskId: number): Observable<ResponseItemBean<KioskContentBean>> {
        return this.get('kiosk/location/' + operatorId + '/kiosk/' + kioskId + '/content');
    }

    changeDevicesOperationType(kioskId: number, state: DeviceOperationType): Observable<ResponseBean> {
        const p = {};
        p['type'] = state;
        return this.get('admin/kiosk/' + kioskId + '/devices/change_operation_type', p);
    }


    getStateHistory(kioskId: number, query: string): Observable<CursorList<KioskStateHistoryBean>> {
        const p = {};
        if (query) {
            p['query'] = query;
        }
        return this.get('admin/kiosk/' + kioskId + '/state_history', p);
    }
}
