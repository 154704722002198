import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';

@Component({
    selector: 'kiosk-error-ids-component',
    templateUrl: 'kiosk-error-ids.component.html'
})

export class KioskErrorIdsComponent implements AfterViewInit {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    ids: number[];

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    open(title: string, ids: number[]) {
        console.log(ids);
        this.ids = ids;
        this.base.show(title);
    }

    save() {
    }

    showOrderDetails(id: number) {
    }
}
