import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {ProfileService} from '../../services/profile.service';
import {ProfilesComponent} from './profiles.component';
import {AppComponent} from '../../app.component';
import 'moment-timezone';
import {UserBean} from '../../model/model';

@Component({
    selector: 'customer-edit-component',
    templateUrl: 'customer.edit.component.html'
})

export class CustomerEditComponent {

    user: UserBean = {} as UserBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild('fieldsListComponent', {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('rewardPoints', 'Reward Points', FieldType.NUMBER),
    ];


    constructor(private profileService: ProfileService,
                @Inject(forwardRef(() => ProfilesComponent)) private parent: ProfilesComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(user: UserBean, title: string) {

        this.user = (user ? Helper.copyObject(user) : {} as UserBean);
        this.base.show(title + ' <' + this.user.displayName + '>');

    }


    save() {

        this.profileService.setRewardPoints(this.user.id, this.user.rewardPoints).subscribe(response => {

            if (response.success) {
                this.parent.load();

            } else {

                this.app.showError('Can\'t update user');
            }
        }, error => {
            this.app.showError('Can\'t update user');
        });


    }

}

