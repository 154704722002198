import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {KioskBean, PageBean, ResponseBean, StepSequenceTestBean, StepSequenceTestResultBean} from '../model/model';

@Injectable()
export class StepTestService extends BaseRequestService {

    getTests(kiosk: KioskBean, limit?: number, offset?: number): Observable<PageBean<StepSequenceTestBean>> {
        return this.get('admin/tests/kiosk/' + kiosk.id + '/location/' + kiosk.operatorId + '/list',
            BaseRequestService.buildOffsetParams(limit, offset));
    }

    addTest(kiosk: KioskBean, test: StepSequenceTestBean): Observable<ResponseBean> {
        return this.post('admin/tests/kiosk/' + kiosk.id + '/location/' + kiosk.operatorId + '/add_test', test);
    }

    deleteTest(kiosk: KioskBean, test: StepSequenceTestBean): Observable<ResponseBean> {
        return this.del('admin/tests/kiosk/' + kiosk.id + '/location/' + kiosk.operatorId + '/test/' + test.id + '/delete');
    }

    getTestResults(kiosk: KioskBean, test: StepSequenceTestBean, limit?: number, offset?: number):
        Observable<PageBean<StepSequenceTestResultBean>> {
        return this.get('admin/tests/kiosk/' + kiosk.id + '/location/' + kiosk.operatorId + '/test/' + test.id + '/results',
            BaseRequestService.buildOffsetParams(limit, offset));
    }
}
