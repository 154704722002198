import {Component} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';

@Component({
    selector: 'my-app',
    moduleId: module.id,
    templateUrl: 'app.component.html'
})

export class AppComponent {

    public options = {
        position: ['bottom', 'left'],
        timeOut: 2000,
        showProgressBar: false,
        pauseOnHover: true,
        clickToClose: true,
    };

    constructor(private notify: NotificationsService) {
        localStorage.removeItem('firebase:previous_websocket_failure');
    }

    public showError(text: string) {
        this.notify.error('Error', text);
    }

    public showNotification(text: string) {
        this.notify.info('Information', text);
    }
}
