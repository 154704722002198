import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {KioskBean, UserBean} from '../../../model/model';
import {Helper} from '../../../common/helper';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskTestersEditComponent} from './kiosk-testers.edit.component';

@Component({
    selector: 'kiosk-testers-component',
    templateUrl: 'kiosk-testers.component.html'
})
export class KioskTestersComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskTestersEditComponent, {static: false}) modal: KioskTestersEditComponent;

    fields: Column[] = [
        new Column('email', 'Email'),
    ];

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        this.updateTable();
    }

    onEdit(items: UserBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Email');
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.kiosk.testerEmails.indexOf(step);
            this.kiosk.testerEmails.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Email');
    }

    addUser(initUser: UserBean, user: UserBean) {

        console.log(user);

        if (initUser.email == null) {
            this.kiosk.testerEmails.push(user.email);

        } else {

            let ind = -1;
            let i = 0;
            for (const email of this.kiosk.testerEmails) {
                if (email == initUser.email) {
                    ind = i;
                }
                i++;
            }

            console.log(ind);

            if (ind >= 0) {
                this.kiosk.testerEmails.splice(ind, 1);
                this.kiosk.testerEmails.splice(ind, 0, user.email);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const users: UserBean[] = [];
            for (const email of this.kiosk.testerEmails) {
                const user: UserBean = {} as UserBean;
                user.email = email;
                users.push(user);
            }

            this.table.setData(users, this.fields);
        }
    }
}
