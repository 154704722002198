import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {AppComponent} from '../../../app.component';
import {VisionService} from '../../../services/vision.service';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {ImageCropperComponent} from 'ngx-image-cropper';
import {KioskDevicesComponent} from './kiosk-devices.component';
import {DeviceBean, VisionObjectAreaBean} from '../../../model/model';

@Component({
    selector: 'rect-select-component',
    templateUrl: 'rect.select.component.html'
})

export class RectSelectComponent {

    @ViewChild('imageCropper', {static: false}) imageCropper: ImageCropperComponent;
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    visionTags: string[];
    selectedTag: string;

    imageCropperReady = false;
    imageBase64Data: string;
    cWidth: number;
    cHeight: number;
    device: DeviceBean;
    rect: VisionObjectAreaBean = {} as VisionObjectAreaBean;
    origRect: VisionObjectAreaBean;
    devices: DeviceBean[];
    selectedDevice: DeviceBean;

    constructor(private visionService: VisionService,
                private httpClient: HttpClient,
                private sanitizer: DomSanitizer,
                @Inject(forwardRef(() => KioskDevicesComponent)) private parent: KioskDevicesComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(tags: string[], device: DeviceBean, devices: DeviceBean[], rect: VisionObjectAreaBean) {

        this.devices = devices;
        this.origRect = rect;
        this.rect = rect ? rect : {} as VisionObjectAreaBean;
        this.device = device;
        this.imageCropperReady = false;
        this.visionTags = tags;
        this.selectedTag = this.visionTags ? this.visionTags[0] : null;
        this.selectedDevice = this.devices ? this.devices[0] : null;

        this.base.show('Add Rect');
        this.getImage();

        setTimeout(() => {
            $('#selet_tag_modal').selectpicker('destroy').selectpicker();
            $('#selet_device_modal').selectpicker('destroy').selectpicker();
        }, 1);

    }


    getImage() {

        const dfd = $.Deferred();
        const image = new Image();
        image.onload = dfd.resolve;
        image.onerror = dfd.reject;
        image.src = this.device.visionImageUrl;

        const self = this;
        dfd.then(function() {

            const c = document.createElement('canvas');
            self.cWidth = c.width = this.width;
            self.cHeight = c.height = this.height;
            self.imageBase64Data = c.toDataURL();


            setTimeout(() => {
                self.imageCropperReady = true;
            }, 150);
        });
    }

    imageLoaded() {
    }

    getBackground() {
        return this.sanitizer.bypassSecurityTrustUrl(this.device.visionImageUrl);
    }

    save() {

        const w = this.cWidth / this.imageCropper['maxSize'].width;
        const h = this.cHeight / this.imageCropper['maxSize'].height;

        const x1 = Math.round(this.imageCropper.cropper.x1 * w);
        const x2 = Math.round(this.imageCropper.cropper.x2 * w);
        const y1 = Math.round(this.imageCropper.cropper.y1 * h);
        const y2 = Math.round(this.imageCropper.cropper.y2 * h);

        this.rect.x1 = x1;
        this.rect.y1 = y1;
        this.rect.x2 = x2;
        this.rect.y2 = y2;
        this.rect.visionTag = this.selectedTag;
        this.rect.deviceId = this.selectedDevice ? this.selectedDevice.id : 0;

        if (this.origRect) {
            this.parent.editRect(this.device, this.rect);
        } else {
            this.parent.addRect(this.device, this.rect);
        }

    }

}

