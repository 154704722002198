import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import Timer = NodeJS.Timer;

@Component({
    selector: 'value-editor-component',
    templateUrl: 'value-editor.component.html'
})
export class ValueEditorComponent implements AfterViewInit {

    @Input() title: string[];
    @Input() val: number;
    @Input() inc: number;

    @Output() onValueChanged = new EventEmitter();

    speed: number;
    interval: Timer;
    down: boolean;

    static round(value, precision) {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    constructor() {
    }

    ngAfterViewInit(): void {
        this.val = ValueEditorComponent.round(this.val, 6);
    }

    updateValue() {
        this.onValueChanged.emit({value: this.val, title: this.title});
    }

    minusDown() {
        this.down = true;
        this.speed = -this.inc;
        this.interval = setInterval(() => {
            this.speed -= this.inc;
            this.val = ValueEditorComponent.round(this.val + this.speed, 6);
        }, 200);
        this.val = ValueEditorComponent.round(this.val + this.speed, 6);
    }

    minusUp() {
        if (this.down) {
            this.updateValue();
        }
        this.down = false;
        clearInterval(this.interval);
    }

    plusDown() {
        this.down = true;
        this.speed = this.inc;
        this.interval = setInterval(() => {
            this.speed += this.inc;
            this.val = ValueEditorComponent.round(this.val + this.speed, 6);
        }, 200);
        this.val = ValueEditorComponent.round(this.val + this.speed, 6);

    }

    plusUp() {
        if (this.down) {
            this.updateValue();
        }
        this.down = false;
        clearInterval(this.interval);
    }

}
