import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../components/base.modal.component';
import {MessageToUsersBean} from '../../model/model';
import {ProfileService} from '../../services/profile.service';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'profiles-notification-component',
    templateUrl: 'profiles.notification.component.html'
})

export class ProfilesNotificationComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;

    message: string;
    private items: any;

    constructor(private profileService: ProfileService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

    }

    open(items) {
        this.items = items;

        this.show();
    }

    show() {
        this.base.show('Enter Message');
    }

    save() {
        const bean: MessageToUsersBean = {} as MessageToUsersBean;
        bean.message = this.message;
        bean.userIds = [];

        for (const profile of this.items) {
            bean.userIds.push(profile.id);
        }

        this.profileService.sendMessageToUsersSet(bean).subscribe(response => {

            if (response.success) {
                this.app.showNotification(response.value);

            } else {
                this.app.showError('Can\'t send notifications');
            }
        }, error => {
            this.app.showError('Can\'t send notifications');
        });
    }


}


