import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PageBean, RecipeListBean, ResponseBean} from '../model/model';

@Injectable()
export class RecipeListService extends BaseRequestService {

    getAllRecipeLists(limit?: number, offset?: number): Observable<PageBean<RecipeListBean>> {
        return this.get('admin/recipe_list/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    addRecipeList(list: RecipeListBean): Observable<ResponseBean> {
        return this.post('admin/recipe_list/add', list);
    }

    deleteRecipeList(list: RecipeListBean): Observable<ResponseBean> {
        return this.del('admin/recipe_list/' + list.id + '/delete');
    }

    updateRecipeList(list: RecipeListBean): Observable<ResponseBean> {
        return this.put('admin/recipe_list/update', list);
    }

}
