import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {CredentialsBean, ResponseBean, ResponseItemBean} from '../model/model';

@Injectable()
export class PaymentService extends BaseRequestService {

    getPaymentCredentials(): Observable<ResponseItemBean<CredentialsBean>> {
        return this.get('admin/payment/credentials');
    }

    updatePaymentCredentials(credentials: CredentialsBean): Observable<ResponseBean> {
        return this.put('admin/payment/credentials/update', credentials);
    }
}
