import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Helper} from '../common/helper';
import {BaseModalComponent} from './base.modal.component';
import {KioskDataService} from '../services/kiosk-data.service';
import {AbstractDeviceBean, RecipeStepBean} from '../model/model';

@Component({
    selector: 'depends-on-edit-component',
    templateUrl: 'depends-on.edit.component.html'
})
export class DependsOnEditComponent implements AfterViewInit {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    step: RecipeStepBean = {} as RecipeStepBean;
    recipeSteps: RecipeStepBean[];
    origStep: RecipeStepBean;
    devices: AbstractDeviceBean[];

    constructor(private kioskDataService: KioskDataService) {
    }

    ngAfterViewInit(): void {
        this.kioskDataService.getData().subscribe(kioskData => {
            this.devices = kioskData.value.devices;
        });
    }

    isChecked(step: RecipeStepBean, i: number) {
        const index = this.step.dependsOn.indexOf(i);
        return index >= 0;
    }

    setChecked(step: RecipeStepBean, i: number) {
        const index = this.step.dependsOn.indexOf(i);
        if (index >= 0) {
            this.step.dependsOn.splice(index, 1);
        } else {
            this.step.dependsOn.push(i);
        }
    }

    getStepDescription(step: RecipeStepBean) {
        return step.step ? Helper.getStepDescription(step.step, this.devices) : step.macroStep.description;
    }

    open(recipeSteps: RecipeStepBean[], step: RecipeStepBean) {

        const index = recipeSteps.indexOf(step);
        if (index > 0) {
            recipeSteps = recipeSteps.slice(0, index);
        }

        this.recipeSteps = recipeSteps;
        this.step = Helper.copyObject(step);
        this.origStep = step;

        this.base.show('Edit dependencies');
    }

    save() {
        this.origStep.dependsOn = this.step.dependsOn;
    }
}
