import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseRequestService} from './base-request.service';
import {CompanyBean, ResponseBean} from '../model/model';

@Injectable()
export class CompanyService extends BaseRequestService {

    getCompanies(): Observable<CompanyBean[]> {
        return this.get('company/list');
    }

    addCompany(company: CompanyBean): Observable<ResponseBean> {
        return this.post('admin/company/add', company);
    }

    updateCompany(company: CompanyBean): Observable<ResponseBean> {
        return this.put('admin/company/' + company.id + '/update', company);
    }

    deleteCompany(company: CompanyBean): Observable<ResponseBean> {
        return this.del('admin/company/' + company.id + '/delete');
    }

}
