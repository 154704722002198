import {publishReplay, refCount} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseRequestService} from './base-request.service';
import {OperatorBean, ResponseBean} from '../model/model';

@Injectable()
export class OperatorsService extends BaseRequestService {

    private operators: Observable<any>;

    getOperators(): Observable<OperatorBean[]> {
        if (!this.operators) {
            this.operators = this.get('operator/list').pipe(
                publishReplay(1),
                refCount());
        }
        return this.operators;
    }

    addOperator(location: OperatorBean): Observable<ResponseBean> {
        this.operators = null;
        return this.post('admin/operator/add', location);
    }

    updateOperator(location: OperatorBean): Observable<ResponseBean> {
        this.operators = null;
        return this.put('admin/operator/' + location.id + '/update', location);
    }

    deleteOperator(location: OperatorBean): Observable<ResponseBean> {
        this.operators = null;
        return this.del('admin/operator/' + location.id + '/delete');
    }

    clear() {
        this.operators = null;
    }

}
