import {AfterViewInit, Component} from '@angular/core';
import {KioskBean} from '../../../model/model';
import {Options} from 'ng5-slider';

@Component({
    selector: 'kiosk-hours-component',
    templateUrl: 'kiosk-hours.component.html',
    styleUrls: ['kiosk-hours.component.scss']
})
export class KioskHoursComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;

    days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];

    options: Options = {
        floor: 0,
        ceil: 24,
        step: 1,
        translate: (value: number): string => {

            if (value == 0 || value == 24) {
                return '12 a.m.';
            }
            if (value == 12) {
                return '12 p.m.';
            }

            const amOrPm = (value < 12) ? ' a.m.' : ' p.m.';
            const hour = (value < 12) ? value : value - 12;

            return hour + amOrPm;
        }
    };

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        if (!this.kiosk.workingHoursFrom) {
            this.kiosk.workingHoursFrom = [8, 8, 8, 8, 8, 8, 8];
        }
        if (!this.kiosk.workingHoursTo) {
            this.kiosk.workingHoursTo = [20, 20, 20, 20, 20, 20, 20];
        }
    }

}
