import {Component, ViewChild} from '@angular/core';
import {KioskBean} from '../../../model/model';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DashboardService} from '../../../services/dashboard.service';
import {KioskErrorIdsComponent} from './kiosk-error-ids.component';
import {WeeklyAnalyticsBean} from '../../../model/custom-model';

@Component({
    selector: 'kiosk-view-component',
    templateUrl: 'kiosk-view.component.html'
})
export class KioskViewComponent {

    kiosk: KioskBean = {} as KioskBean;
    url1: SafeResourceUrl;
    url2: SafeResourceUrl;

    @ViewChild(KioskErrorIdsComponent, {static: false}) kioskErrorIdsComponent: KioskErrorIdsComponent;

    weeklyAnalytics: WeeklyAnalyticsBean[];

    constructor(public sanitizer: DomSanitizer, private dashboardService: DashboardService) {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        if (this.kiosk) {
            this.url1 = this.sanitizer.bypassSecurityTrustResourceUrl('https://video.nest.com/embedded/live/' + this.kiosk.videoStreamId);
            this.url2 = this.sanitizer.bypassSecurityTrustResourceUrl('https://video.nest.com/embedded/live/' + this.kiosk.videoStreamId2);

            const from = new Date();
            from.setHours(from.getHours() + 7);
            from.setMinutes(from.getMinutes() - from.getTimezoneOffset());
            from.setDate(from.getDate() - from.getDay() - 21);

            const to = new Date();
            to.setHours(to.getHours() + 7);
            to.setMinutes(to.getMinutes() - to.getTimezoneOffset());

            this.dashboardService.getAnalytics(this.kiosk.id, from.getTime(), to.getTime(), 'daily').subscribe(response => {
                if (response.success) {

                    this.weeklyAnalytics = [];
                    let bean: WeeklyAnalyticsBean = new WeeklyAnalyticsBean();
                    let index = 0;

                    for (const item of response.value) {

                        const date = new Date(item.ts);
                        date.setHours(date.getHours() - 7);
                        date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

                        bean.ordersTotal += item.ordersTotal;
                        bean.ordersKiosk += item.ordersKiosk;
                        bean.ordersMealPlan += item.ordersMealPlan;
                        bean.ordersIOS += item.ordersIOS;
                        bean.ordersAndroid += item.ordersAndroid;
                        bean.ordersReordered += item.ordersReordered;
                        bean.ordersCancelled += item.ordersCancelled;
                        bean.maintenanceUserCritical += item.maintenanceUserCritical;
                        bean.maintenanceUserScheduled += item.maintenanceUserScheduled;


                        for (const key in item.errorsUser) {
                            bean.errorsUser += item.errorsUser[key];
                        }
                        for (const key in item.errorsComputed) {
                            bean.errorsComputed += item.errorsComputed[key];
                        }
                        for (const key in item.systemErrors) {
                            bean.systemErrors += item.systemErrors[key];
                        }

                        bean.ts = date.getTime();
                        if (++index % 7 == 0) {
                            this.weeklyAnalytics.push(bean);
                            bean = new WeeklyAnalyticsBean();
                        }
                    }
                    this.weeklyAnalytics.push(bean);

                }

            }, error => {
                console.log(error);
            });
        }
    }

    showDetails(ids: number[], title: string) {
        this.kioskErrorIdsComponent.open(title, ids);
    }
}
