import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {DiagnosticsStepService} from '../../../services/diagnosics-step.service';
import {StepTestService} from '../../../services/step-test.service';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {AppComponent} from '../../../app.component';
import {KioskTestDetailsComponent} from './kiosk-test-details.component';
import {KioskTestCycleDetailsComponent} from './kiosk-test-cycle-details.component';
import {DiagnosticsStepSequenceBean, KioskBean, StepSequenceTestBean, StepSequenceTestResultBean} from '../../../model/model';

@Component({
    selector: 'kiosk-tests-component',
    templateUrl: 'kiosk-tests.component.html'
})
export class KioskTestsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    kiosk: KioskBean = {} as KioskBean;
    sequences: DiagnosticsStepSequenceBean[];
    stepTest: StepSequenceTestBean;
    @ViewChild(KioskTestDetailsComponent, {static: false}) kioskTestDetailsComponent: KioskTestDetailsComponent;
    @ViewChild(KioskTestCycleDetailsComponent, {static: false}) kioskTestCycleDetailsComponent: KioskTestCycleDetailsComponent;

    fields: Column[] = [

        new Column('id', 'Id'),
        new Column('startDate', 'Start Time').date(),
        new Column('endDate', 'End Time').date(),
        new Column('stepSequence.description', 'Sequence'),
        new Column('repeatNumber', 'Repeat'),
        new Column('minQuantity', 'Min'),
        new Column('maxQuantity', 'Max'),
        new Column('stateCopy', 'State'),
    ];

    constructor(private diagnosticsStepService: DiagnosticsStepService,
                private stepTestService: StepTestService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

        this.stepTest = {} as StepSequenceTestBean;
        this.stepTest.repeatNumber = 1;
        this.stepTest.minQuantity = 1;
        this.stepTest.maxQuantity = 100;
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        this.loadSequences();
        $('#select_test_sequence').selectpicker('destroy').selectpicker();
    }

    loadSequences() {
        this.diagnosticsStepService.getStepsSequences(this.kiosk.kioskModelId).subscribe(sequences => {
            this.sequences = sequences.list;
            this.sequences.sort((a, b) => (a.description > b.description ? 1 : ((b.description > a.description) ? -1 : 0)));


            setTimeout(() => {
                $('#select_test_sequence').prop('disabled', false).selectpicker('destroy').selectpicker();
            }, 1);

            this.loadTests();
        });
    }

    runTest() {

        this.stepTestService.addTest(this.kiosk, this.stepTest).subscribe(response => {
            this.loadSequences();
        });
    }

    loadTests() {
        this.stepTestService.getTests(this.kiosk).subscribe(tests => {

            for (const test of tests.list) {
                test['stateCopy'] = test.state;
            }
            this.table.setData(tests.list, this.fields);
        });
    }

    onRemove(items) {
        for (const test of items) {

            this.stepTestService.deleteTest(this.kiosk, test).subscribe(response => {
                if (response.success) {
                    if (test == items.pop()) {
                        this.loadTests();
                    }

                } else {
                    this.app.showError('Can\'t delete device');
                }
            }, error => {
                this.app.showError('Can\'t delete device');
            });
        }
    }

    onView(items) {
        if (items && items.length > 0) {
            this.kioskTestDetailsComponent.open(this.kiosk, items[0]);
        }
    }

    openCycleDetails(result: StepSequenceTestResultBean, title: string) {
        this.kioskTestCycleDetailsComponent.open(result, title);
    }
}
