import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {MacroStepBean, PageBean, ResponseBean} from '../model/model';

@Injectable()
export class MacroStepService extends BaseRequestService {

    getMacroSteps(limit?: number, offset?: number): Observable<PageBean<MacroStepBean>> {
        return this.get('admin/step/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    addMacroStep(step: MacroStepBean): Observable<ResponseBean> {
        return this.post('admin/step/add', step);
    }

    deleteMacroStep(step: MacroStepBean): Observable<ResponseBean> {
        return this.del('admin/step/' + step.id + '/delete');
    }

    updateMacroStep(step: MacroStepBean): Observable<ResponseBean> {
        return this.put('admin/step/update', step);
    }
}
