import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {KioskService} from '../../services/kiosk.service';
import {KiosksComponent} from './kiosks.component';
import {AppComponent} from '../../app.component';
import {KioskModelService} from '../../services/kiosk-model.service';
import {KioskBean, KioskModelBean, OperatorBean} from '../../model/model';
import {Helper} from '../../common/helper';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {OperatorsService} from '../../services/operators.service';

@Component({
    selector: 'kiosks-edit-component',
    templateUrl: 'kiosks.edit.component.html'
})

export class KiosksEditComponent {

    kiosk: KioskBean = {} as KioskBean;
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    models: KioskModelBean[];

    fields = [
        new Field('name', 'Name').require(),
        new Field('addressLine1', 'Address Line #1').require(),
        new Field('addressLine2', 'Address Line #2'),
        new Field('country', 'Country').require(),
        new Field('countryCode', 'Country Code'),
        new Field('city', 'City').require(),
        // new Field('county', 'County'),
        // new Field('zipCode', 'Zip Code'),
        // new Field('phoneNumber', 'Phone Number', FieldType.TEL),
        new Field('contactName', 'Contact Name'),
        new Field('contactEmail', 'Contact Email'),
        new Field('contactPhone', 'Contact Phone', FieldType.TEL),
        new Field('latitude', 'Latitude', FieldType.NUMBER).require(),
        new Field('longitude', 'Longitude', FieldType.NUMBER).require(),
    ];
    operators: OperatorBean[];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private kioskService: KioskService,
                private kioskModelService: KioskModelService,
                private operatorsService: OperatorsService,
                private http: HttpClient,
                @Inject(forwardRef(() => KiosksComponent)) private parent: KiosksComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(kiosk: KioskBean, title: string) {

        if (kiosk) {
            this.kiosk = Helper.copyObject(kiosk);
        } else {
            this.kiosk = {} as KioskBean;
        }

        if (!this.models || this.models.length == 0) {
            this.loadKioskModels();
        }
        if (!this.operators || this.operators.length == 0) {
            this.loadOperators();
        }

        this.base.show(title);
    }

    save() {

        if (!this.kiosk.id) {

            this.kioskService.addKiosk(this.kiosk).subscribe(response => {
                if (response.success) {
                    this.parent.loadKiosks();
                } else {
                    this.app.showError('Can\'t create kiosk');
                }
            }, error => {
                this.app.showError('Can\'t create kiosk');
            });

        } else {

            this.kioskService.updateKiosk(this.kiosk).subscribe(response => {
                if (response.success) {
                    this.parent.loadKiosks();
                } else {
                    this.app.showError('Can\'t update kiosk');
                }
            }, error => {
                this.app.showError('Can\'t update kiosk');
            });
        }
    }


    loadKioskModels() {
        this.kioskModelService.getKioskModels().subscribe(models => {
            this.models = models.list;
        });
    }

    loadOperators() {
        this.operatorsService.getOperators().subscribe(operators => {
            this.operators = operators;
        });
    }


    getLocation() {

        this.findFromAddress()
            .subscribe(response => {
                if (response.results.length > 0) {

                    this.kiosk.latitude = response.results[0].geometry.location.lat;
                    this.kiosk.longitude = response.results[0].geometry.location.lng;

                } else {
                    this.app.showError('Can\'t get location');
                }
            });
    }

    findFromAddress(): Observable<any> {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + this.getAddress();
        return this.http.get(url);
    }


    getAddress() {
        return (this.kiosk.addressLine1 ? this.kiosk.addressLine1 : '') + '' +
            (this.kiosk.addressLine2 ? this.kiosk.addressLine2 : '') + '' +
            (this.kiosk.city ? this.kiosk.city : '') + '' +
            (this.kiosk.county ? this.kiosk.county : '') + '' +
            (this.kiosk.country ? this.kiosk.country : '');
    }

}

