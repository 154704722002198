import {AfterViewInit, Component} from '@angular/core';
import {KioskBean, OrderSize, RecipeStateBean} from '../../../model/model';

@Component({
    selector: 'kiosk-recipes-component',
    templateUrl: 'kiosk-recipes.component.html'
})
export class KioskRecipesComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    get filteredRecipes() {
        if (!this.kiosk || !this.kiosk.recipeStates) {
            return;
        }
        return this.kiosk.recipeStates.filter((value) => {
            return value.state == 'PROVISIONED';
        });
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        for (const recipe of this.kiosk.recipeStates) {
            if (!recipe.discount) {
                recipe.discount = {};
            }
            if (!recipe.price) {
                recipe.price = {};
            }// Helper.copyObject(recipe.defaultPrice);
        }
    }

    onSizeChanged(ev, size: OrderSize, recipeState: RecipeStateBean) {
        if (ev.target.checked) {
            delete recipeState.price[size];
        } else {
            recipeState.price[size] = recipeState.defaultPrice[size];
        }
    }
}
