import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {NavbarComponent} from '../shared/navbar/navbar.component';

@Component({
    templateUrl: 'dashboard-layout.component.html'
})
export class DashboardLayoutComponent implements AfterViewInit {

    @ViewChild(NavbarComponent, {static: false}) private navbarComponentVar: NavbarComponent;

    get navbarComponent(): NavbarComponent {
        return this.navbarComponentVar;
    }

    constructor() {
    }

    ngAfterViewInit(): void {
    }
}
