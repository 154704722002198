export enum FieldType {
    TEXT, NUMBER, TEXT_AREA, FILE, SELECT, DATE, TIMEZONE, TEL, CHECKBOX
}

export namespace FieldTypeHelper {
    export function toString(fieldType: FieldType): string {
        switch (fieldType) {
            case FieldType.TEXT:
                return 'text';
            case    FieldType.NUMBER:
                return 'number';
            case    FieldType.TEXT_AREA:
                return 'textarea';
            case   FieldType.FILE:
                return 'file';
            case    FieldType.SELECT:
                return 'select';
            case     FieldType.DATE:
                return 'date';
            case    FieldType.TIMEZONE:
                return 'timezone';
            case    FieldType.TEL:
                return 'tel';
        }
        return '';
    }
}

export class Field {
    public title: string;
    public required: boolean;
    public disabled: boolean;
    public field: string;
    public subfield: string;
    public fieldType: FieldType;
    public options: string[] = [];

    constructor(field: string, title: string, fieldType: FieldType = FieldType.TEXT) {
        this.field = (field ? field.split('.')[0] : null);
        this.subfield = (field ? field.split('.')[1] : null);
        this.title = title;
        this.fieldType = fieldType;
    }

    setOptions(options: string[]): Field {
        this.options = options;
        return this;
    }

    disable(): Field {
        this.disabled = true;
        return this;
    }

    require(): Field {
        this.required = true;
        return this;
    }
}
