import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {IngredientService} from '../../services/ingredient.service';
import {IngredientsComponent} from './ingredients.component';
import {AppComponent} from '../../app.component';
import {IngredientBean} from '../../model/model';
import {AppConstants} from '../../app.constants';

@Component({
    selector: 'ingredients-edit-component',
    templateUrl: 'ingredients.edit.component.html'
})

export class IngredientsEditComponent {

    ingredient: IngredientBean = {} as IngredientBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('name', 'Name').require(),
        new Field('type', 'Type', FieldType.SELECT).require().setOptions([AppConstants.TYPE_LIQUID, AppConstants.TYPE_SOLID,
            AppConstants.TYPE_POWDER, AppConstants.TYPE_BULK_POWDER, AppConstants.TYPE_FINE_POWDER]),
        new Field('imageUrl', 'Image', FieldType.FILE),
        new Field('density', 'Density', FieldType.NUMBER),
        new Field('calories', 'Cals (kcal)', FieldType.NUMBER),
        new Field('protein', 'Pro (g)', FieldType.NUMBER),
        new Field('totalCarb', 'Carb (g)', FieldType.NUMBER),
        new Field('dietaryFiber', 'Fiber (g)', FieldType.NUMBER),
        new Field('sugar', 'Total sugar (g)', FieldType.NUMBER),
        new Field('totalFat', 'Fat (g)', FieldType.NUMBER),
        new Field('saturatedFat', 'Saturated fat (g)', FieldType.NUMBER),
        new Field('cholestrol', 'Cholesterol (mg)', FieldType.NUMBER),
        new Field('vitaminA', 'Vit A (IU)', FieldType.NUMBER),
        new Field('vitaminC', 'Vit C (mg)', FieldType.NUMBER),
        new Field('calcium', 'Calcium (mg)', FieldType.NUMBER),
        new Field('iron', 'Iron (mg)', FieldType.NUMBER),
        new Field('pottasium', 'Potassium (mg)', FieldType.NUMBER),
        new Field('sodium', 'Sodium (mg)', FieldType.NUMBER),

        // new Field('polyunsaturatedFat', 'Polyunsaturated Fat', FieldType.NUMBER),
        // new Field('monounsaturatedFat', 'Monounsaturated Fat', FieldType.NUMBER),
        // new Field('vitaminD', 'Vitamin D', FieldType.NUMBER),
        // new Field('vitaminE', 'Vitamin E', FieldType.NUMBER),
        // new Field('magnesium', 'Magnesium', FieldType.NUMBER),

    ];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private ingredientService: IngredientService,
                @Inject(forwardRef(() => IngredientsComponent)) private parent: IngredientsComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(ingredient: IngredientBean, title: string) {

        this.ingredient = (ingredient ? Helper.copyObject(ingredient) : {} as IngredientBean);
        this.base.show(title);
    }

    save() {
        if (this.ingredient.id == null) {

            this.ingredientService.addIngredient(this.ingredient).subscribe(response => {

                if (response.success) {
                    this.parent.loadIngredients();
                } else {
                    this.app.showError('Can\'t create ingredient');
                }
            }, error => {
                this.app.showError('Can\'t create ingredient');
            });

        } else {

            this.ingredientService.updateIngredient(this.ingredient).subscribe(response => {

                if (response.success) {
                    this.parent.loadIngredients();
                } else {
                    this.app.showError('Can\'t update ingredient');
                }
            }, error => {
                this.app.showError('Can\'t update ingredient');
            });
        }
    }

}

