import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {ProfileService} from '../../services/profile.service';
import {DataService} from '../../services/data.service';
import {SidebarComponent} from '../../shared/sidebar/sidebar.component';
import * as moment from 'moment';
import 'moment-timezone';
import {TabDirective, TabsetComponent} from 'ngx-bootstrap/tabs';
import {Helper} from '../../common/helper';
import {Router} from '@angular/router';
import {UpdateCredentialsBean, UserInfoBean} from '../../model/model';

@Component({
    selector: 'user-edit-component',
    templateUrl: 'user.edit.component.html'
})

export class UserEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    @ViewChild(TabsetComponent, {static: false}) tabset: TabsetComponent;

    fields: Field[] = [
        new Field('profile.displayName', 'Display Name').require(),
        new Field('profile.firstName', 'First Name'),
        new Field('profile.lastName', 'Last Name'),
        // new Field('profile.birthdayDate', 'Birthday', FieldType.DATE),
        new Field('profile.country', 'Country'),
        new Field('profile.state', 'State'),
        new Field('profile.city', 'City'),
        new Field('profile.timezoneId', 'Time zone', FieldType.SELECT).setOptions(moment.tz.names()),
        new Field('profile.profilePicture', 'Image', FieldType.FILE),
    ];
    userInfo: UserInfoBean = {} as UserInfoBean;
    password1: string = '';
    password2: string = '';
    dataTabSelected = true;

    isValid() {
        return () => {
            if (this.dataTabSelected) {
                return this.fieldsListComponent && this.fieldsListComponent.valid();
            } else {
                return this.passwordsValid();
            }
        };
    }

    constructor(private profileService: ProfileService,
                private dataService: DataService,
                private router: Router,
                @Inject(forwardRef(() => SidebarComponent)) private sidebar: SidebarComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(userInfo: UserInfoBean) {
        this.userInfo = userInfo;
        this.base.show('Edit Profile');
        this.password1 = this.password2 = '';
    }

    save() {

        if (this.dataTabSelected) {
            if (this.userInfo.user.id != null) {
                const dateStr = this.fieldsListComponent.getValue('profile.birthdayDate');
                this.userInfo.profile.birthdayDate = new Date(dateStr);

                this.profileService.updateProfile(0, this.userInfo.user.id, this.userInfo.profile).subscribe(response => {
                    if (response.success) {
                        this.dataService.setUserInfo(this.userInfo);
                        this.sidebar.refreshUser();

                    } else {

                        this.app.showError('Can\'t update user');
                    }
                }, error => {
                    this.app.showError('Can\'t update user');
                });
            }
        } else {

            const credentials = {} as UpdateCredentialsBean;
            credentials.password = Helper.encodePassword(this.password1);
            credentials.companyId = 0; // this.dataService.getCompanyId();
            credentials.userId = this.userInfo.user.id;
            credentials.rights = this.userInfo.user.rights;


            this.profileService.updateCredentials(credentials).subscribe(response => {
                if (response.success) {

                    this.dataService.clear();
                    this.router.navigate(['/login']);

                } else {
                    this.app.showError('Can\'t update user');
                }
            }, error => {
                this.app.showError('Can\'t update user');
            });
        }
    }

    passwordsValid() {
        return (this.password1.length > 3 && this.password1 == this.password2);
    }

    onDataTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.dataTabSelected = true;
        }
    }

    onPasswordTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.dataTabSelected = false;
        }
    }


}

