import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field} from '../../common/field';
import {Helper} from '../../common/helper';
import {CompaniesComponent} from './companies.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {CompanyService} from '../../services/company.service';
import {AppComponent} from '../../app.component';
import {CompanyBean} from '../../model/model';

@Component({
    selector: 'companies-edit-component',
    templateUrl: 'companies.edit.component.html'
})

export class CompaniesEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    company: CompanyBean = {} as CompanyBean;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('name', 'Name').require(),
    ];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private companyService: CompanyService,
                @Inject(forwardRef(() => CompaniesComponent)) private parent: CompaniesComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(company: CompanyBean, title: string) {
        this.company = (company ? Helper.copyObject(company) : {} as CompanyBean);
        this.base.show(title);
    }

    save() {

        delete this.company['state'];
        if (this.company.id == null) {

            this.companyService.addCompany(this.company).subscribe(response => {

                if (response.success) {
                    this.parent.loadCompanies();
                } else {
                    this.app.showError('Can\'t create company');
                }
            }, error => {
                this.app.showError('Can\'t create company');
            });

        } else {

            this.companyService.updateCompany(this.company).subscribe(response => {

                if (response.success) {
                    this.parent.loadCompanies();
                } else {
                    this.app.showError('Can\'t update company');
                }
            }, error => {
                this.app.showError('Can\'t update company');
            });
        }
    }

}


