import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {BaseModalComponent} from '../components/base.modal.component';
import {ModalModule} from 'ngx-bootstrap';
import {FieldsListComponent} from '../components/fields-list.component';
import {TableComponent} from '../components/table.component';
import {FileUploaderComponent} from '../file-uploader/file-uploader.component';
import {ImageModalComponent} from '../components/image.modal.component';
import {MapModalComponent} from '../components/map.modal.component';
import {AgmCoreModule} from '@agm/core';
import {BrowserModule} from '@angular/platform-browser';
import {KeysValuePipe, TimePipe} from '../common/pipes';
import {ImageManagerSelectComponent} from '../dashboard/image-manager/image-manager.select.component';

const DECLARATIONS = [
    TableComponent,
    ImageModalComponent,
    MapModalComponent,
    FieldsListComponent,
    ImageManagerSelectComponent,
    FileUploaderComponent,
    BaseModalComponent,
    KeysValuePipe,
    TimePipe,
];

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBC01nYn_X0t-uT04ixACVNDao7kCInDQs'
        })
    ],
    declarations: [
        DECLARATIONS
    ],
    exports: [
        DECLARATIONS
    ],
    providers: []
})
export class SharedModule {
}
