import {AfterViewInit, Component} from '@angular/core';
import {KioskBean, UserBean} from '../../../model/model';
import {ProfileService} from '../../../services/profile.service';
import {Rights} from '../../../common/rights';
import {Helper} from '../../../common/helper';

@Component({
    selector: 'kiosk-managers-component',
    templateUrl: 'kiosk-managers.component.html'
})
export class KioskManagersComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    managers: UserBean[] = [];
    compareBeans = Helper.compareBeans;

    constructor(private profileService: ProfileService) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        if (this.managers.length != 0) {
            return;
        }

        this.profileService.getUsers().subscribe(response => {

            console.log(response);

            for (const user of response.list) {
                if ((user.rights | Rights.STORE_MANAGER) == user.rights) {
                    this.managers.push(user);
                }
            }

            setTimeout(() => {
                $('#select_manager').selectpicker('destroy').selectpicker();
                $('#select_state').selectpicker('destroy').selectpicker();
            }, 100);

        });
    }
}
