export class RouteInfo {
    path: string;
    title: string;
    children: RouteInfo[];

    constructor(path?: string, title?: string, children?: RouteInfo[]) {
        this.path = path;
        this.title = title;
        this.children = children;
    }

}
