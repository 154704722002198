import {KioskDefines} from './kiosk.defines';
import {Helper} from '../common/helper';
import {DeviceModelBean} from '../model/model';
import {DeviceView} from './device.view';

const resourcesPath = '../assets/img/presentation/';

export class JarView extends DeviceView {
    private group: fabric.Group;
    private canvas: fabric.Canvas;
    private contentGroup: fabric.Group;
    avColor: any;
    private quantity: number;
    private aborted: boolean;

    constructor(device: DeviceModelBean, place: [number, number], canvas: fabric.Canvas, callback?: (objects: fabric.Object[]) => any) {

        super();
        this.canvas = canvas;
        fabric.Image.fromURL(device.deviceTemplate.image1, img => {

            img.set({
                originY: 'center',
                originX: 'center',
                left: 0,
                top: -img.height,
            });

            img.setFlipX(device.rotation < 0);

            this.contentGroup = new fabric.Group([], {
                left: 0,
                top: 0,
                originY: 'center',
                originX: 'center',
                width: img.width,
                height: img.height
            });

            this.group = new fabric.Group([img], {
                left: place[0],
                top: place[1],
                originY: 'center',
                originX: 'center',
                hasControls: false,
                evented: false,
                width: img.width,
                height: img.height
            });

            this.quantity = 0;
            this.group.add(this.contentGroup);
            this.contentGroup.sendToBack();

            callback.call(this, [this.group]);
        });
    }

    public dispense(ingredientName: string, animationDuration: number) {

        fabric.Image.fromURL(resourcesPath + 'Jar_Content.png', jarContent => {

            const color = KioskDefines.getIngredientColor(ingredientName);
            const offset = 42;
            const rowHeight = 5;

            this.quantity += rowHeight;

            jarContent.set({
                originY: 'bottom',
                originX: 'left',
                left: -27,
                top: offset - this.quantity + rowHeight
            });

            jarContent.animate({top: offset - this.quantity}, {
                duration: animationDuration,
                // onChange: this.canvas.renderAll.bind(this.canvas)
            });

            jarContent.filters = [];
            jarContent.filters.push(new fabric.Image.filters.Tint({color, opacity: 0.6}));
            jarContent.applyFilters(this.canvas.renderAll.bind(this.canvas));

            const index = this.quantity / 6.0;

            if (this.avColor == null) {
                this.avColor = Helper.hexToRgb(color);
            } else {
                const nextColor = Helper.hexToRgb(color);
                this.avColor.r = this.avColor.r * ((index - 1) / index) + nextColor.r * (1 / index);
                this.avColor.g = this.avColor.g * ((index - 1) / index) + nextColor.g * (1 / index);
                this.avColor.b = this.avColor.b * ((index - 1) / index) + nextColor.b * (1 / index);
            }
            this.contentGroup.add(jarContent);
        });
    }


    blend(speed: number) {
        let i = 0;

        this.group.sendToBack();
        this.aborted = false;

        this.group.set('angle', 0);
        this.group.animate({angle: 0}, {
            duration: 200000 / speed,
            onChange: () => {
                if (i++ % 5 == 0) {
                    this.group.toggle('flipX');
                    this.canvas.renderAll();
                }
            },
            abort: () => {
                return this.aborted;
            }
        });

        const items = this.contentGroup.getObjects();
        for (const item of items) {
            ((item) as fabric.Image).filters = [];
            ((item) as fabric.Image).filters.push(new fabric.Image.filters.Tint({
                color: Helper.rgbToHex(Math.round(this.avColor.r), Math.round(this.avColor.g), Math.round(this.avColor.b)),
                opacity: 0.6
            }));
            ((item) as fabric.Image).applyFilters(this.canvas.renderAll.bind(this.canvas));
        }
    }

    stopBlending() {
        this.aborted = true;
        this.group.set('flipX', false);
    }

    pulse(animationDuration: number) {

        let i = 0;
        this.group.set('angle', 0);
        this.group.animate({angle: 0}, {
            duration: animationDuration,
            onChange: () => {
                if (i++ % 5 == 0) {
                    this.group.toggle('flipX');
                    this.canvas.renderAll();
                }
            }
        });
    }

    pour(animationDuration: number) {

        this.quantity = 0;
        this.group.set('angle', 0);
        this.group.set('progress', this.contentGroup.getObjects().length + 1);
        this.group.animate({angle: 360, progress: -this.contentGroup.getObjects().length}, {
            duration: animationDuration,
            onChange: () => {

                const progress = this.group.get('progress');
                if (this.contentGroup.getObjects().length > 0) {
                    while (this.contentGroup.getObjects().length > progress) {
                        this.contentGroup.remove(this.contentGroup.getObjects()[this.contentGroup.getObjects().length - 1]);
                    }
                }

                this.canvas.renderAll();
            }
        });
    }

    drain(animationDuration: number) {

        this.group.set('angle', 0);
        this.group.animate({angle: 720 * 3}, {
            duration: animationDuration,
            onChange: this.canvas.renderAll.bind(this.canvas)
        });
    }
}
