import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {ImageManagerEditComponent} from './image-manager.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {UploadService} from '../../services/upload.service';
import {environment} from '../../environments/environment';
import {ImageBean} from '../../model/model';
import {ImageManagerUploadComponent} from './image-manager.upload.component';

@Component({
    templateUrl: 'image-manager.component.html',
})

export class ImageManagerComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(ImageManagerEditComponent, {static: false}) imageManagerEditComponent: ImageManagerEditComponent;
    @ViewChild(ImageManagerUploadComponent, {static: false}) imageManagerUploadComponent: ImageManagerUploadComponent;

    fields: Column[] = [
        new Column('id', 'Id').hide(),
        new Column('creationDate', 'Creation Date').date(),
        new Column('tag', 'Tag'),
        new Column('references', 'References'),
        new Column('image', 'Image').thumbnail(128),
    ];
    images: ImageBean[];

    constructor(private uploadService: UploadService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadData();
    }

    loadData() {

        this.images = [];
        this.uploadService.list().subscribe(response => {

            if (response.success) {
                const date = new Date().getTime();
                for (const img of response.value) {
                    img['image'] = environment.FILE_STORAGE_URL + img.id + '?date=' + date;
                }
                this.table.setData(response.value, this.fields);

            } else {
                this.app.showError('Can\'t load images');
            }
        }, error => {
            this.app.showError('Can\'t load images');
        });
    }

    onEdit(items: ImageBean[]) {
        if (items && items.length > 0) {
            this.imageManagerEditComponent.open(items[0], 'Edit Image');
        }
    }

    onRemove(items: ImageBean[]) {
        let it = 0;
        for (const image of items) {

            this.uploadService.delete(image.id).subscribe(response => {
                if (response.success) {
                    if (++it == items.length) {
                        this.loadData();
                    }

                } else {
                    this.app.showError('Can\'t delete image: used in ' + response.errorDescription);
                }
            }, error => {
                this.app.showError('Can\'t delete image');
            });
        }
    }

    onAdd() {
        this.imageManagerEditComponent.open(null, 'Add Image');
    }

    onBatchUpload() {
        this.imageManagerUploadComponent.open();

    }
}

