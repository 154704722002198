import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {RecipeListService} from '../../services/recipe-list.service';
import {RecipesListsEditComponent} from './recipes-lists.edit.component';
import {TableComponent} from '../../components/table.component';
import {Column} from '../../common/column';
import {RecipeListBean} from '../../model/model';

@Component({
    templateUrl: 'recipes-lists.component.html',
})

export class RecipesListsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(RecipesListsEditComponent, {static: false}) modal: RecipesListsEditComponent;

    fields: Column[] = [
        // new Column('imageUrl', 'Image').thumbnail(),
        new Column('name', 'Name'),
        new Column('cnt', 'Recipes Count').data(this, this.getRecipesCount),
    ];

    constructor(private recipesListsService: RecipeListService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadRecipesLists();
    }

    loadRecipesLists() {
        this.recipesListsService.getAllRecipeLists().subscribe(recipesLists => {
            console.log(recipesLists);
            this.table.setData(recipesLists.list, this.fields);
        });
    }

    onEdit(items: RecipeListBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const recipesList of items) {

            this.recipesListsService.deleteRecipeList(recipesList).subscribe(response => {
                if (response.success) {
                    if (recipesList == items.pop()) {
                        this.loadRecipesLists();
                    }

                } else {
                    this.app.showError('Can\'t delete list');
                }
            }, error => {
                this.app.showError('Can\'t delete list');
            });
        }
    }

    onAdd() {
        this.modal.open(null, 'New List');
    }

    getRecipesCount(recipesList: RecipeListBean): number {
        if (!recipesList || !recipesList.recipeIds) {
            return 0;
        }
        return recipesList.recipeIds.length;
    }
}

