import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {KioskBean, ResponseBean, ResponseItemBean} from '../model/model';
import {DeviceSnapshot} from '../model/custom-model';

@Injectable()
export class StoreManagerService extends BaseRequestService {

    setKioskMaintenance(kiosk: KioskBean, on: boolean): Observable<ResponseBean> {
        const p = {};
        p['on'] = on;
        return this.get('store_manager/kiosk/' + kiosk.id + '/maintenance', p);
    }

    setDeviceDisabled(kiosk: KioskBean, device: DeviceSnapshot, disabled: boolean): Observable<ResponseBean> {
        const p = {};
        p['value'] = disabled;
        return this.get('store_manager/kiosk/' + kiosk.id + '/device/' + device.id + '/disabled', p);
    }

    refillIngredient(kiosk: KioskBean, device: DeviceSnapshot): Observable<ResponseBean> {
        return this.get('store_manager/kiosk/' + kiosk.id + '/device/' + device.id + '/refill_ingredient');
    }

    cancelOrder(orderId: number): Observable<ResponseBean> {
        return this.get('store_manager/order/' + orderId + '/cancel');
    }

    startNow(orderId: number): Observable<ResponseBean> {
        return this.get('store_manager/order/' + orderId + '/start_now');
    }

    getKiosks(): Observable<ResponseItemBean<KioskBean[]>> {
        return this.get('store_manager/kiosk/list');
    }

    unlockJars(kiosk: KioskBean): Observable<ResponseBean> {
        return this.get('store_manager/kiosk/' + kiosk.id + '/unlock_jars');
    }

}
