import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {VisionService} from '../../services/vision.service';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {VisionDetectComponent} from './detect/vision-detect.component';
import {VisionLearnComponent} from './learn/vision-learn.component';
import {VisionLogComponent} from './log/vision-log.component';

@Component({
    templateUrl: 'vision.component.html',
})
export class VisionComponent implements AfterViewInit {

    @ViewChild(VisionDetectComponent, {static: false}) visionDetectComponent: VisionDetectComponent;
    @ViewChild(VisionLearnComponent, {static: false}) visionLearnComponent: VisionLearnComponent;
    @ViewChild(VisionLogComponent, {static: false}) visionLogComponent: VisionLogComponent;

    deviceNames: string[];

    constructor(private visionService: VisionService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadVision(true);
    }

    public loadVision(openDetectTab: boolean) {
        this.visionService.getTags().subscribe(response => {
                if (response.success) {
                    this.visionDetectComponent.setTags(response.value);
                    this.visionLearnComponent.setTags(response.value);
                } else {
                    this.app.showError('Can\'t get tags');
                }
        }, error => {
                console.log(error);
                this.app.showError('Can\'t get tags');
        });

        this.visionService.getDeviceNames().subscribe(response => {

            if (response.success) {
                this.deviceNames = [];
                for (const name of response.value) {
                    this.deviceNames.push(name);
                }

                this.deviceNames.sort((a: string, b: string) => {
                    return a.localeCompare(b);
                });

                this.visionDetectComponent.setDeviceNames(this.deviceNames);

                setTimeout(() => {
                    $('#select_log_tag').selectpicker('destroy').selectpicker();
                }, 1);

                if (openDetectTab != null) {
                    if (openDetectTab) {
                        this.onDetectTabSelected(null);
                    } else {
                        this.onLearnTabSelected(null);
                    }
                }
            } else {
                this.app.showError('Can\'t get device names');
            }
        }, error => {
            console.log(error);
            this.app.showError('Can\'t get device names');
        });

    }

    onLogsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.visionLogComponent.onShow();
        }
    }

    onDetectTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.visionDetectComponent.onShow();
        }
    }

    onLearnTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.visionLearnComponent.onShow();
        }
    }

}
