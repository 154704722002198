import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {ImageManagerComponent} from './image-manager.component';
import {AppComponent} from '../../app.component';
import {ImageBean} from '../../model/model';
import {UploadService} from '../../services/upload.service';
import {FileUploaderComponent} from '../../file-uploader/file-uploader.component';
import {environment} from '../../environments/environment';

@Component({
    selector: 'image-manager-edit-component',
    templateUrl: 'image-manager.edit.component.html'
})

export class ImageManagerEditComponent {

    image: ImageBean = {} as ImageBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    @ViewChild(FileUploaderComponent, {static: false}) fileUploaderComponent: FileUploaderComponent;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('tag', 'Tag', FieldType.TEXT),
    ];
    selectedImage: ImageBean;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(
        private uploadService: UploadService,
        @Inject(forwardRef(() => ImageManagerComponent)) private parent: ImageManagerComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(image: ImageBean, title: string) {
        this.image = (image ? Helper.copyObject(image) : {} as ImageBean);
        this.base.show(title);
        this.fileUploaderComponent.fileData = null;
    }

    save() {

        if (!this.image.id) {

            this.uploadService.uploadImage(this.fileUploaderComponent.fileData, this.image.tag).subscribe(response => {
                this.parent.loadData();
                this.base.onCancelClicked();
            });

        } else {
            this.uploadService.replaceImage(this.fileUploaderComponent.fileData, this.image.id, this.image.tag).subscribe(response => {
                this.parent.loadData();
                this.base.onCancelClicked();
            });
        }
    }

    getThumbnail(orig: ImageBean) {
        return environment.SERVER_URL + 'admin/image/' + orig.id + '/thumbnail?size=' + 128;
    }


    select(image: ImageBean) {
        console.log('select ' + this.selectedImage);

        if (this.selectedImage == image) {
            this.selectedImage = null;
        } else {
            this.selectedImage = image;
        }
    }

    onRestoreClicked() {
        this.uploadService.replaceWith(this.image.id, this.selectedImage.id).subscribe(response => {
            this.parent.loadData();
            this.base.onCancelClicked();
        });
    }
}

