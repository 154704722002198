export class LineChartData {
    data: Array<{ data: Array<any>; label: string; fill: boolean }>;
    labels: Array<any>;

    constructor(labels: Array<string>) {

        this.data = [];
        this.labels = [];

        for (const label of labels) {
            this.data.push({data: [], label, fill: false});
        }
    }

    colors: Array<any> = [
        {borderColor: '#DC3912'},
        {borderColor: '#65BE91'},
        {borderColor: '#3366CC'},
        {borderColor: '#FF9900'},
        {borderColor: '#990099'},
        {borderColor: '#3B3EAC'},
        {borderColor: '#0099C6'},
        {borderColor: '#DD4477'},
        {borderColor: '#66AA00'},
        {borderColor: '#B82E2E'},
        {borderColor: '#316395'},
        {borderColor: '#994499'},
        {borderColor: '#22AA99'},
        {borderColor: '#AAAA11'},
        {borderColor: '#6633CC'},
        {borderColor: '#E67300'},
        {borderColor: '#8B0707'},
        {borderColor: '#329262'},
        {borderColor: '#5574A6'},
        {borderColor: '#3B3EAC'},
    ];
}

