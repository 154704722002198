import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {KioskService} from '../../services/kiosk.service';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {OperatorsService} from '../../services/operators.service';
import {KiosksEditComponent} from './kiosks.edit.component';
import {Helper} from '../../common/helper';
import {KioskBean, KioskModelBean, KioskState, KioskTemplateBean} from '../../model/model';
import {MapModalComponent} from '../../components/map.modal.component';
import {KiosksQuickviewComponent} from './kiosks.quickview.component';

@Component({
    templateUrl: 'kiosks.component.html',
})

export class KiosksComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild('kiosksEditComponent', {static: false}) kiosksEditComponent: KiosksEditComponent;
    @ViewChild('kiosksQuickviewComponent', {static: false}) kiosksQuickviewComponent: KiosksQuickviewComponent;
    @ViewChild(MapModalComponent, {static: false}) mapModalComponent: MapModalComponent;

    columns: Column[] = [
        // new Column('operator', 'Operator').data(this, this.getOperator),
        new Column('name', 'Name').button(this, this.editKiosk),
        // new Column('shortName', 'Short Name'),
        // new Column('kioskModelName', 'Model').button(this, this.showModel),
        new Column('production', 'Production').checkbox(),
        new Column('locationInfo', 'Location Info').data(this, this.isLocationInfoFilled),
        new Column('stateCopy', 'State'),
        new Column('operationalSince', 'Operational Since').date(),
        // new Column('contactName', 'Contact').concat('contactEmail').concat('contactPhone'),
        // new Column('city', 'Address').concat('addressLine1').concat('addressLine2').button(this, this.openMap)
    ];

    // operators: OperatorBean[] = [];
    // allOperators: OperatorBean = {} as OperatorBean;
    // selectedOperator: OperatorBean = {} as OperatorBean;
    // selectedOperatorId: number;
    templates: KioskTemplateBean[];
    models: KioskModelBean[];
    compareBeans = Helper.compareBeans;

    averageLatitude = 0;
    averageLongitude = 0;
    kiosks: KioskBean[];
    selectedStates: KioskState[] = [];
    defaultStates: KioskState[] = [
        // 'OUT_OF_SERVICE',
        'WAITING',
        'OPERATIONAL',
        'MAINTENANCE',
        'PROVISIONING',
    ];
    filteredKiosk: KioskBean[] = [];
    selectedMarkerKiosk: KioskBean;

    constructor(private kioskService: KioskService,
                private operatorsService: OperatorsService,
                private route: ActivatedRoute,
                private router: Router,
                private location: Location,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

        // this.allOperators.id = 0;
    }


    isLocationInfoFilled(kiosk: KioskBean) {
        const checked: boolean = !!kiosk.addressLine1 && !!kiosk.contactName && !!kiosk.contactEmail && !!kiosk.contactPhone;
        return '<input type="checkbox" disabled ' + (checked ? 'checked' : '') + '/>';
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            // this.selectedOperatorId = params['location'] ? params['location'] : 0;

            if (params['states']) {
                for (const param of params['states'].split(',')) {
                    this.selectedStates.push(param);
                }
            } else {
                this.selectedStates = this.defaultStates;
            }
            // this.loadLocations();

            setTimeout(() => {
                $('#select_state').selectpicker('destroy').selectpicker();
                this.loadKiosks();
            }, 1);
        });
    }

    // public loadLocations() {
    //     this.operatorsService.getOperators().subscribe(operators => {
    //
    //         this.operators = operators;
    //         this.selectedOperator = this.allOperators;
    //         for (const location of this.operators) {
    //             if (location.id == this.selectedOperatorId) {
    //                 this.selectedOperator = location;
    //                 break;
    //             }
    //         }
    //
    //         setTimeout(() => {
    //             $('#select_operator').selectpicker('destroy').selectpicker();
    //             $('#select_state').selectpicker('destroy').selectpicker();
    //             this.loadKiosks();
    //         }, 1);
    //
    //
    //     });
    // }
    //
    // getOperator(kiosk: KioskBean): string {
    //     for (const operator of this.operators) {
    //         if (operator.id == kiosk.operatorId) return operator.name;
    //     }
    //     return '';
    // }

    clearKiosks() {
        this.table.setData([], this.columns);
    }

    loadKiosks() {

        // if (this.selectedOperator.id == 0) {
        //     this.kioskService.getAllKiosks(false).subscribe(kiosks => {
        //         this.setKiosks(kiosks.value);
        //     })
        // } else {
        //     this.kioskService.getKiosks(this.selectedOperator.id).subscribe(kiosks => {
        //         this.setKiosks(kiosks.list);
        //     })
        // }

        this.kioskService.getAllKiosks(false).subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks) {

        this.kiosks = kiosks;

        let maxLat = -Number.MAX_SAFE_INTEGER;
        let maxLng = -Number.MAX_SAFE_INTEGER;
        let minLat = Number.MAX_SAFE_INTEGER;
        let minLng = Number.MAX_SAFE_INTEGER;

        this.filteredKiosk = [];
        for (const kiosk of kiosks) {

            if (kiosk.latitude != 0 && kiosk.longitude != 0) {
                if (kiosk.latitude > maxLat) {
                    maxLat = kiosk.latitude;
                }
                if (kiosk.longitude > maxLng) {
                    maxLng = kiosk.longitude;
                }
                if (kiosk.latitude < minLat) {
                    minLat = kiosk.latitude;
                }
                if (kiosk.longitude < minLng) {
                    minLng = kiosk.longitude;
                }
            }

            const kioskCopy = Helper.copyObject(kiosk);
            // kioskCopy['location'] = this.getLocationName(kiosk.operatorId);
            kioskCopy['stateCopy'] = kiosk.state;

            for (const state of this.selectedStates) {
                if (state == kioskCopy['stateCopy']) {
                    this.filteredKiosk.push(kioskCopy);
                }
            }
        }

        this.averageLongitude = minLng + ((maxLng - minLng) / 2);
        this.averageLatitude = minLat + ((maxLat - minLat) / 2);
        this.table.setData(this.filteredKiosk, this.columns);
    }

    // getLocationName(locationId: number) {
    //     for (const location of this.operators) {
    //         if (location.id == locationId) return location.name;
    //     }
    //     return 'Unknown';
    // }

    // onOperatorChange() {
    //
    //     this.loadKiosks();
    //     this.location.replaceState('/kiosks?location=' + this.selectedOperator.id + '&states=' + this.selectedStates)
    // }

    onStateChange() {
        this.setKiosks(this.kiosks);
        // this.location.replaceState('/kiosks?location=' + this.selectedOperator.id + '&states=' + this.selectedStates)
        this.location.replaceState('/kiosks?states=' + this.selectedStates);
    }

    showModel(kiosk: KioskBean) {
        this.router.navigate(['/kiosk-model-details/'], {
            queryParams: {
                modelId: kiosk.kioskModelId,
            }
        });
    }


    onView(items: KioskBean[]) {

        if (items && items.length > 0) {
            this.router.navigate(['/kiosk-view/'], {
                queryParams: {
                    kioskId: items[0].id,
                    operatorId: items[0].operatorId
                }
            });
        }
    }

    onStats(items: KioskBean[]) {

        if (items && items.length > 0) {
            this.router.navigate(['/kiosk-info/'], {
                queryParams: {
                    kioskId: items[0].id,
                    operatorId: items[0].operatorId
                }
            });
        }
    }


    onEdit(items: KioskBean[]) {
        if (items && items.length > 0) {
            this.editKiosk(items[0]);
        }
    }

    editKiosk(item: KioskBean) {
        this.router.navigate(['/kiosk-details/'], {
            queryParams: {
                kioskId: item.id,
                operatorId: item.operatorId
            }
        }).then(success => {
            if (!success) {
                this.app.showError('Permission denied');
            }
        });
    }

    onAdd() {
        // if (this.selectedOperator == this.allOperators) {
        //     this.app.showNotification('Please select Operator');
        // } else {
        //     this.kiosksEditComponent.open(null, this.selectedOperator.id, 'New Kiosk');
        // }
        this.kiosksEditComponent.open(null, 'New Kiosk');
    }

    onRemove(items) {
        for (const kiosk of items) {

            this.kioskService.deleteKiosk(kiosk).subscribe(response => {
                if (response.success) {
                    this.loadKiosks();

                } else {
                    this.app.showError('Can\'t delete kiosk');
                }
            }, error => {
                this.app.showError('Can\'t delete kiosk');
            });
        }
    }


    openMap(item: KioskBean) {
        this.mapModalComponent.open(item.latitude, item.longitude);
    }

    clickedMarker(item: KioskBean) {
        this.selectedMarkerKiosk = item;
    }

    onClickRow(item: KioskBean) {
        this.kiosksQuickviewComponent.open(item, item.operatorId, item.name);
    }


    onQuickView(items: KioskBean[]) {
        if (items && items.length > 0) {
            this.router.navigate(['/store-manager-kiosk-view/'], {
                queryParams: {
                    kioskId: items[0].id,
                    operatorId: items[0].operatorId
                }
            });
        }
    }

}

