import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {
    AdminRegistrationBean,
    LoginResponseBean,
    MessageToUsersBean,
    PageBean,
    ResponseBean,
    ResponseItemBean,
    UpdateCredentialsBean,
    UserBean,
    UserInfoBean,
    UserProfileBean
} from '../model/model';
import {Observable} from 'rxjs';

@Injectable()
export class ProfileService extends BaseRequestService {

    login(email: string, password: string): Observable<LoginResponseBean> {
        const body = {email, password};
        return this.post('admin/user/login', body);
    }

    loginConfirmation(userId: number, confirmationCode: string): Observable<ResponseItemBean<UserInfoBean>> {
        return this.post('admin/user/' + userId.toString() + '/login/confirmation', confirmationCode);
    }

    resendConfirmationCode(userId: number): Observable<ResponseBean> {
        return this.get('users/' + userId.toString() + '/login/resend_confirmation');
    }

    getCustomers(limit?: number, offset?: number, sortByOrders?: boolean): Observable<PageBean<UserBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        p['sort_by_orders'] = sortByOrders;
        return this.get('admin/user/customers/list', p);
    }

    getUsers(limit?: number, offset?: number): Observable<PageBean<UserBean>> {
        return this.get('admin/user/users/list', BaseRequestService.buildOffsetParams(limit, offset));
    }


    sendMessage(message?: string, inactiveDays?: number): Observable<ResponseItemBean<string>> {
        const p = {};
        p['message'] = message;
        if (inactiveDays) {
            p['inactive_days'] = inactiveDays;
        }
        return this.get('admin/user/send_message', p);
    }

    sendMessageToUsersSet(message?: MessageToUsersBean): Observable<ResponseItemBean<string>> {
        return this.post('admin/user/send_message', message);
    }

    getAdmins(limit?: number, offset?: number): Observable<PageBean<UserBean>> {
        return this.get('admin/user/admins/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    isEmailFree(email: string): Observable<ResponseBean> {
        const p = {};
        p['email'] = email;
        return this.get('admin/user/is_email_free', p);
    }

    deleteUser(companyId: number, userId: number): Observable<ResponseBean> {
        return this.del('admin/user/' + userId.toString() + '/company/' + companyId.toString() + '/delete');
    }

    register(reg: AdminRegistrationBean): Observable<LoginResponseBean> {
        return this.post('admin/user/register', reg);
    }


    // https://api.6dbytes.com/v1/admin/user/6535381816180736/client_key/generate

    generateClientKey(userId: number): Observable<ResponseItemBean<UserBean>> {
        return this.get('admin/user/' + userId.toString() + '/client_key/generate');
    }

    updateCredentials(credentials: UpdateCredentialsBean): Observable<ResponseItemBean<UserInfoBean>> {
        return this.put('admin/user/update', credentials);
    }

    updateProfile(companyId: number, userId: number, profile: UserProfileBean): Observable<ResponseBean> {
        return this.put('admin/user/' + userId.toString() + '/company/' + companyId.toString() + '/profile/update', profile);
    }

    getProfile(companyId: number, userId: number): Observable<ResponseItemBean<UserProfileBean>> {
        return this.get('admin/user/' + userId.toString() + '/company/' + companyId.toString() + '/profile');
    }

    requestPasswordRecovery(email: string): Observable<ResponseBean> {
        const p = {};
        p['email'] = email;
        return this.get('admin/user/request_password_recovery', p);
    }

    setRewardPoints(userId: number, points: number): Observable<ResponseBean> {
        const p = {};
        p['points'] = points;
        return this.get('admin/user/' + userId.toString() + '/set_reward_points', p);
    }

    resetPassword(key: string, password: string): Observable<ResponseBean> {
        return this.post('admin/user/reset_password/' + key, password);
    }

}
