import {AfterViewInit, Component} from '@angular/core';
import {KioskBean} from '../../../model/model';

@Component({
    selector: 'kiosk-ingredients-component',
    templateUrl: 'kiosk-ingredients.component.html'
})
export class KioskIngredientsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
    }
}
