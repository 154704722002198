import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../components/base.modal.component';
import {ImageManagerComponent} from './image-manager.component';
import {AppComponent} from '../../app.component';
import {UploadService} from '../../services/upload.service';
import {FileUploadControl, FileUploadValidators} from '@iplab/ngx-file-upload';

@Component({
    selector: 'image-manager-upload-component',
    templateUrl: 'image-manager.upload.component.html'
})

export class ImageManagerUploadComponent {

    fileUploadControl = new FileUploadControl(FileUploadValidators.accept(['image/*']));
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    uploadsCount: number;

    constructor(
        private uploadService: UploadService,
        @Inject(forwardRef(() => ImageManagerComponent)) private parent: ImageManagerComponent,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open() {
        this.base.show('Upload Images');
        this.fileUploadControl.clear();
    }

    save() {

        this.uploadsCount = this.fileUploadControl.value.length;

        for (const file of this.fileUploadControl.value) {

            const fileReader = new FileReader();
            fileReader.onload = this.handleFileReaderLoaded.bind(this);
            fileReader.readAsArrayBuffer(file);
        }
    }

    handleFileReaderLoaded(e) {
        this.uploadService.uploadImage(e.target.result, '').subscribe(response => {
            this.uploadsCount--;
            if (this.uploadsCount == 0) {
                this.parent.loadData();
                this.base.onCancelClicked();
            }
        });
    }

}

