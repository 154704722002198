import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {StepTestService} from '../../../services/step-test.service';
import {StepSequenceTestResultBean} from '../../../model/model';

@Component({
    selector: 'kiosk-test-cycle-details-component',
    templateUrl: 'kiosk-test-cycle-details.component.html'
})

export class KioskTestCycleDetailsComponent implements AfterViewInit {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    result: StepSequenceTestResultBean = {} as StepSequenceTestResultBean;

    constructor(private stepTestService: StepTestService) {
    }

    ngAfterViewInit(): void {
    }

    open(result: StepSequenceTestResultBean, title: string) {
        this.result = result;
        this.base.setFooterVisibility(false);
        this.base.show(title);

    }

    save() {
    }

    openCycleDetails(result: StepSequenceTestResultBean) {

    }

}
