import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {KioskBean} from '../../../model/model';
import {DiagnosticsStepService} from '../../../services/diagnosics-step.service';
import {AppComponent} from '../../../app.component';
import {KioskDiagnosticsRunStepComponent} from './kiosk-diagnostics-run-step.component';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {KioskDiagnosticsOrdersComponent} from './kiosk-diagnostics-orders.component';

@Component({
    selector: 'kiosk-diagnostics-component',
    templateUrl: 'kiosk-diagnostics.component.html'
})
export class KioskDiagnosticsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    @ViewChild(KioskDiagnosticsRunStepComponent, {static: false}) kioskDiagnosticsRunStepComponent: KioskDiagnosticsRunStepComponent;
    @ViewChild(KioskDiagnosticsOrdersComponent, {static: false}) kioskDiagnosticsOrdersComponent: KioskDiagnosticsOrdersComponent;

    constructor(private diagnosticsStepService: DiagnosticsStepService,
                private kioskService: KioskService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        this.onOrdersTabSelected(null);
    }

    public setDiagnosticsMode(enabled) {

        this.kioskService.setDiagnosticsState(this.kiosk, enabled).subscribe(response => {
            if (response.success) {
                this.kiosk.state = response.value;
                if (this.isDiagnostics()) {
                    this.onOrdersTabSelected(null);
                }

            } else {
                this.app.showError('Can\'t change kiosk state');
            }
        });
    }

    isDiagnostics() {
        if (!this.kiosk) {
            return false;
        }
        return this.kiosk.state == 'MAINTENANCE';
    }

    saveDiagnosticStates() {
        this.kioskService.changeDiagnosticsStates(this.kiosk).subscribe(response => {
            console.log(response);
            if (response.success) {
                this.app.showNotification('Updated');
            } else {
                this.app.showError('Can\'t update states');
            }
        }, error => {
            this.app.showError('Can\'t update states');
        });
    }

    onOrdersTabSelected(ev) {
        console.log('onOrdersTabSelected');

        if (!ev || ev instanceof TabDirective) {
            this.kioskDiagnosticsOrdersComponent.onShow(this.kiosk);
        }
    }

    onRunStepTabSelected(ev) {
        console.log('onRunStepTabSelected');

        if (!ev || ev instanceof TabDirective) {
            this.kioskDiagnosticsRunStepComponent.onShow(this.kiosk);
        }
    }
}
