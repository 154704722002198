import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';
import {Router} from '@angular/router';
import {BusinessOpsBean, DashboardDynamicsBean, DeviceMessageBean, StatusBean} from '../../model/model';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {Helper} from '../../common/helper';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';

@Component({
    templateUrl: 'business-ops.component.html'
})
export class BusinessOpsComponent implements AfterViewInit {

    public lineChartOptions: any = {
        responsive: true
    };

    messages: DeviceMessageBean[];
    operationsDynamicOrders: DashboardDynamicsBean[];

    compareBeans = Helper.compareBeans;
    status: StatusBean;
    businessOps: BusinessOpsBean;

    constructor(private dashboardService: DashboardService,
                private operatorsService: OperatorsService,
                private kioskService: KioskService,
                @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
                private router: Router) {
    }

    ngAfterViewInit() {
        this.getBusinessOps();
        this.getOperations();
        this.getStatus();
    }

    private getStatus() {
        this.dashboardService.getStatus(true).subscribe(response => {
            if (response) {
                this.status = response.value;
            }
        });
    }

    private getBusinessOps() {
        this.dashboardService.getBusinessOps().subscribe(response => {
            if (response) {
                this.businessOps = response.value;
            }
        });
    }

    private getOperations() {
        this.dashboardService.getOperationsDynamic().subscribe(response => {
            if (response) {
                this.operationsDynamicOrders = response.value;
            }
        });
    }
}
