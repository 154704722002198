import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {AppComponent} from '../../../app.component';
import {VisionComponent} from '../vision.component';
import {DetectionResult, VisionService} from '../../../services/vision.service';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {ImageCropperComponent} from 'ngx-image-cropper';

@Component({
    selector: 'tag-select-component',
    templateUrl: 'tag.select.component.html'
})

export class TagSelectComponent {

    @ViewChild('imageCropper', {static: false}) imageCropper: ImageCropperComponent;
    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    visionTags: string[];
    selectedTag: string;
    detectionResult: DetectionResult;

    imageCropperReady = false;
    imageBase64Data: string;
    cWidth: number;
    cHeight: number;

    constructor(private visionService: VisionService,
                private httpClient: HttpClient,
                private sanitizer: DomSanitizer,
                @Inject(forwardRef(() => VisionComponent)) private parent: VisionComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(tags: string[], detectionResult: DetectionResult) {

        this.imageCropperReady = false;
        this.visionTags = tags;
        this.selectedTag = this.visionTags[0];

        for (const tag of this.visionTags) {
            if (tag == detectionResult.tag) {
                this.selectedTag = tag;
                break;
            }
        }

        this.detectionResult = detectionResult;
        this.base.show('Add to Learning Data');

        this.getImage();

        setTimeout(() => {
            $('#selet_tag_modal').selectpicker('destroy').selectpicker();
        }, 1);
    }


    getImage() {

        const dfd = $.Deferred();
        const image = new Image();
        image.onload = dfd.resolve;
        image.onerror = dfd.reject;
        image.src = this.detectionResult.source_image;

        const self = this;
        dfd.then(function() {

            const c = document.createElement('canvas');
            self.cWidth = c.width = this.width;
            self.cHeight = c.height = this.height;
            self.imageBase64Data = c.toDataURL();

            setTimeout(() => {
                self.imageCropperReady = true;
            }, 150);
        });
    }

    getBackground() {
        return this.sanitizer.bypassSecurityTrustUrl(this.detectionResult.source_image);
    }

    save() {

        const w = this.cWidth / this.imageCropper['maxSize'].width;
        const h = this.cHeight / this.imageCropper['maxSize'].height;

        const x1 = Math.round(this.imageCropper.cropper.x1 * w);
        const x2 = Math.round(this.imageCropper.cropper.x2 * w);
        const y1 = Math.round(this.imageCropper.cropper.y1 * h);
        const y2 = Math.round(this.imageCropper.cropper.y2 * h);

        this.visionService.addImageToTrainingData(this.selectedTag, this.detectionResult, x1, x2, y1, y2).subscribe(response => {
            console.log(response);
            if (response) {
                // this.parent.loadVision(true);
                this.app.showNotification('Added');

            } else {
                this.app.showError('Can\'t add image');
            }
        }, error => {
            console.log(error);
            this.app.showError('Can\'t add image');
        });


    }

}

