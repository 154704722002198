import {publishReplay, refCount} from 'rxjs/operators';
import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KioskTemplateBean, PageBean, ResponseBean, ResponseItemBean} from '../model/model';

@Injectable()
export class KioskTemplateService extends BaseRequestService {

    private kioskTemplates: Observable<any>;

    getKioskTemplate(id: number): Observable<ResponseItemBean<KioskTemplateBean>> {
        return this.get('admin/kiosk/template/' + id);
    }

    addKioskTemplate(template: KioskTemplateBean): Observable<ResponseBean> {
        this.kioskTemplates = null;
        return this.post('admin/kiosk/template/add', template);
    }

    updateKioskTemplate(template: KioskTemplateBean): Observable<ResponseBean> {
        this.kioskTemplates = null;
        return this.put('admin/kiosk/template/update', template);
    }

    deleteKioskTemplate(template: KioskTemplateBean): Observable<ResponseBean> {
        this.kioskTemplates = null;
        return this.del('admin/kiosk/template/' + template.id + '/delete');
    }

    getKioskTemplates(limit?: number, offset?: number): Observable<PageBean<KioskTemplateBean>> {
        if (!this.kioskTemplates) {
            this.kioskTemplates = this.get('admin/kiosk/template/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.kioskTemplates;
    }

    clear() {
        this.kioskTemplates = null;
    }

}
