import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import {AnalyticsBean, EntityNameBean} from '../../model/model';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {Helper} from '../../common/helper';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import {Location} from '@angular/common';
import {NgxDateRangePickerComponent, NgxDateRangePickerOptions} from 'ngx-daterangepicker';
import {LineChartData} from '../../common/line.chart';
import * as FileSaver from 'file-saver';

@Component({
    templateUrl: 'analytics.component.html'
})
export class AnalyticsComponent implements AfterViewInit {

    allKiosks: EntityNameBean = {id: 0} as EntityNameBean;

    lineChartOptions: any = {
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    min: 0,
                    beginAtZero: true
                }
            }]
        }
    };

    ordersChart: LineChartData;
    maintenanceChart: LineChartData;
    revenueChart: LineChartData;
    userErrorsChart: LineChartData;
    computedErrorsChart: LineChartData;
    systemErrorsChart: LineChartData;
    recipesChart: LineChartData;
    actualIngredientsChart: LineChartData;
    desiredIngredientsChart: LineChartData;
    customizedChart: LineChartData;
    reviewsChart: LineChartData;

    kiosks: EntityNameBean[] = [];
    selectedKiosk: EntityNameBean = {id: 0} as EntityNameBean;
    range = 'daily';
    selectedKioskId: number;

    compareBeans = Helper.compareBeans;

    options: NgxDateRangePickerOptions;
    rangePickerValue: any;

    @ViewChild('daterangepicker', {static: false}) rangePicker: NgxDateRangePickerComponent;
    analytics: AnalyticsBean[];

    constructor(private dashboardService: DashboardService,
                private operatorsService: OperatorsService,
                private kioskService: KioskService,
                @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
                private route: ActivatedRoute,
                private location: Location) {
    }

    static daysIntoYear(date) {
        return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
    }

    private static fillChart(chart: LineChartData, keys: { [index: string]: number }, label: string, total: boolean) {
        let sum = 0;
        let totalIndex = 0;
        for (let i = 0; i < chart.data.length; i++) {
            if (chart.data[i].label == 'Total') {
                totalIndex = i;
            }
        }
        for (let i = 0; i < chart.data.length; i++) {
            let found = false;
            for (const key in keys) {
                if (chart.data[i].label == key) {
                    chart.data[i].data.push(keys[key]);
                    sum += keys[key];
                    found = true;
                }
            }
            if (!found) {
                if (!total || (total && i != totalIndex)) {
                    chart.data[i].data.push(0);
                }
            }
        }
        if (total) {
            chart.data[totalIndex].data.push(sum);
        }
        chart.labels.push(label);
    }

    ngAfterViewInit() {
        $('#select_kiosk').selectpicker('destroy').selectpicker();

        const date = new Date();
        const dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 27);
        // dateFrom.setMonth(2);
        // dateFrom.setDate(24);

        const quarter = new Date(date.getFullYear(), Math.floor((date.getMonth() / 3)) * 3, 1);
        const day = AnalyticsComponent.daysIntoYear(date);
        const quarterDay = AnalyticsComponent.daysIntoYear(quarter);

        this.options = {
            theme: 'default',
            labels: ['Start', 'End'],
            menu: [
                {alias: 'wyt', text: 'One week', operation: '-1wt'},
                {alias: 'myt', text: 'Four weeks', operation: '-4wt'},
                {alias: 'lyt', text: 'One year', operation: '-1yt'},
                {alias: 'mtd', text: 'Month to Date', operation: '-' + (date.getDate() - 1) + 'd'},
                {alias: 'qtd', text: 'Quarter to Date', operation: '-' + (day - quarterDay) + 'd'},
                {alias: 'ytd', text: 'Year to Date', operation: '-' + (day - 1) + 'd'},
            ],
            dateFormat: 'YYYY-MM-DD',
            outputFormat: 'DD-MM-YYYY',
            startOfWeek: 0,
            outputType: 'object',
            locale: 'en',
            date: {
                from: {
                    year: dateFrom.getFullYear(),
                    month: dateFrom.getMonth() + 1,
                    day: dateFrom.getDate()
                },
                to: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate()
                }
            }
        };

        this.route.queryParams.subscribe(params => {
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;
            this.loadKiosks();
        });
    }

    onKioskChange() {
        this.getAnalytics();
        this.updateLocation();
    }

    loadKiosks() {
        this.kioskService.getActiveKiosksNames(true).subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        setTimeout(() => {
            $('#select_kiosk').selectpicker('destroy').selectpicker();
        }, 1);

        this.getAnalytics();
    }

    updateLocation() {
        let url = '/analytics';
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id);
        }

        this.location.replaceState(url);
    }

    downloadMaintenance() {

        const from = new Date(this.rangePicker.dateFrom.getTime());
        const to = new Date(this.rangePicker.dateTo.getTime());

        from.setHours(from.getHours() + 7);
        from.setMinutes(from.getMinutes() - from.getTimezoneOffset());

        to.setHours(to.getHours() + 7);
        to.setMinutes(to.getMinutes() - to.getTimezoneOffset());

        this.dashboardService.getMaintenanceChartsCSV(this.selectedKiosk.id, from.getTime(), to.getTime()).subscribe(response => {
            FileSaver.saveAs(response, 'Maintenance.csv');
        });
    }

    private getAnalytics() {
        const from = new Date(this.rangePicker.dateFrom.getTime());
        const to = new Date(this.rangePicker.dateTo.getTime());

        from.setHours(from.getHours() + 7);
        from.setMinutes(from.getMinutes() - from.getTimezoneOffset());

        to.setHours(to.getHours() + 7);
        to.setMinutes(to.getMinutes() - to.getTimezoneOffset());

        if (this.range == 'hourly') {
            to.setHours(to.getHours() + 24);
        }

        console.log(this.range);

        this.dashboardService.getAnalytics(this.selectedKiosk.id, from.getTime(), to.getTime(), this.range).subscribe(response => {
            if (response && response.success) {
                this.analytics = response.value;

                console.log(this.analytics);
                this.updateCharts();
            }
        });
    }

    onDateRangeChanged() {
        this.getAnalytics();
    }

    private updateCharts() {

        if (!this.analytics) {
            return;
        }

        this.revenueChart = new LineChartData(['Total', 'IOS', 'Android', 'Kiosk', 'Meal Plan']);
        this.customizedChart = new LineChartData(['IOS, %', 'Android, %', 'Kiosk, %', 'Meal Plan, %']);
        this.ordersChart = new LineChartData(['Total', 'IOS', 'Android', 'Kiosk', 'Meal Plan', 'Reordered', 'Canceled', 'Free']);
        this.maintenanceChart = new LineChartData(['Total', /*'Critical', 'Regular',*/ 'User Critical', 'User Scheduled']);
        this.reviewsChart = new LineChartData(['Freshness', 'Overall', 'Wait Time']);

        const userErrorNames: Set<string> = new Set();
        const computedErrorNames: Set<string> = new Set();
        const systemErrorNames: Set<string> = new Set();

        const recipeNames: Set<string> = new Set();
        const actualIngredientNames: Set<string> = new Set();
        const desiredIngredientNames: Set<string> = new Set();

        for (const item of this.analytics) {
            for (const key in item.errorsUser) {
                userErrorNames.add(key);
            }
            for (const key in item.errorsComputed) {
                computedErrorNames.add(key);
            }
            for (const key in item.systemErrors) {
                systemErrorNames.add(key);
            }
            for (const key in item.recipes) {
                recipeNames.add(key);
            }
            for (const key in item.actualIngredients) {
                actualIngredientNames.add(key);
            }
            for (const key in item.desiredIngredients) {
                desiredIngredientNames.add(key);
            }
        }

        const userErrorNamesArray = userErrorNames.size > 0 ? Array.from(userErrorNames.values()).sort() : ['No Data'];
        userErrorNamesArray.unshift('Total');

        const computedErrorNamesArray = computedErrorNames.size > 0 ? Array.from(computedErrorNames.values()).sort() : ['No Data'];
        computedErrorNamesArray.unshift('Total');

        const systemErrorNamesArray = systemErrorNames.size > 0 ? Array.from(systemErrorNames.values()).sort() : ['No Data'];
        systemErrorNamesArray.unshift('Total');

        this.userErrorsChart = new LineChartData(userErrorNamesArray);
        this.computedErrorsChart = new LineChartData(computedErrorNamesArray);
        this.systemErrorsChart = new LineChartData(systemErrorNamesArray);

        this.recipesChart = new LineChartData(recipeNames.size > 0 ?
            Array.from(recipeNames.values()).sort() : ['No Data']);
        this.actualIngredientsChart = new LineChartData(recipeNames.size > 0 ?
            Array.from(actualIngredientNames.values()).sort() : ['No Data']);
        this.desiredIngredientsChart = new LineChartData(recipeNames.size > 0 ?
            Array.from(desiredIngredientNames.values()).sort() : ['No Data']);

        for (const item of this.analytics) {

            const date = new Date(item.ts);
            date.setHours(date.getHours() - 7);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

            const label = (date.getMonth() + 1) + '/' + date.getDate() + ' ' + (this.range == 'hourly' ? date.toLocaleString('en-US', {
                hour: 'numeric',
                hour12: true
            }) : '');

            this.revenueChart.data[0].data.push(item.revenueTotal);
            this.revenueChart.data[1].data.push(item.revenueIOS);
            this.revenueChart.data[2].data.push(item.revenueAndroid);
            this.revenueChart.data[3].data.push(item.revenueKiosk);
            this.revenueChart.data[4].data.push(item.revenueMealPlan);
            this.revenueChart.labels.push(label);

            this.ordersChart.data[0].data.push(item.ordersTotal);
            this.ordersChart.data[1].data.push(item.ordersIOS);
            this.ordersChart.data[2].data.push(item.ordersAndroid);
            this.ordersChart.data[3].data.push(item.ordersKiosk);
            this.ordersChart.data[4].data.push(item.ordersMealPlan);
            this.ordersChart.data[5].data.push(item.ordersReordered);
            this.ordersChart.data[6].data.push(item.ordersCancelled);
            this.ordersChart.data[7].data.push(item.ordersFree);
            this.ordersChart.labels.push(label);

            this.customizedChart.data[0].data.push(item.ordersIOS > 0 ? (item.customizedIOS / item.ordersIOS * 100) : 0);
            this.customizedChart.data[1].data.push(item.ordersAndroid > 0 ? (item.customizedAndroid / item.ordersAndroid * 100) : 0);
            this.customizedChart.data[2].data.push(item.ordersKiosk > 0 ? (item.customizedKiosk / item.ordersKiosk * 100) : 0);
            this.customizedChart.data[3].data.push(item.ordersMealPlan > 0 ? (item.customizedMealPlan / item.ordersMealPlan * 100) : 0);
            this.customizedChart.labels.push(label);

            this.maintenanceChart.data[0].data.push(item.maintenanceTotal);
            // this.maintenanceChart.data[1].data.push(item.maintenanceCritical);
            // this.maintenanceChart.data[2].data.push(item.maintenanceRegular);
            this.maintenanceChart.data[1].data.push(item.maintenanceUserCritical);
            this.maintenanceChart.data[2].data.push(item.maintenanceUserScheduled);
            this.maintenanceChart.labels.push(label);

            this.reviewsChart.data[0].data.push(item.reviewFreshness);
            this.reviewsChart.data[1].data.push(item.reviewOverall);
            this.reviewsChart.data[2].data.push(item.reviewWaitTime);
            this.reviewsChart.labels.push(label);

            AnalyticsComponent.fillChart(this.userErrorsChart, item.errorsUser, label, true);
            AnalyticsComponent.fillChart(this.computedErrorsChart, item.errorsComputed, label, true);
            AnalyticsComponent.fillChart(this.systemErrorsChart, item.systemErrors, label, true);
            AnalyticsComponent.fillChart(this.recipesChart, item.recipes, label, false);
            AnalyticsComponent.fillChart(this.actualIngredientsChart, item.actualIngredients, label, false);
            AnalyticsComponent.fillChart(this.desiredIngredientsChart, item.desiredIngredients, label, false);

            date.setDate(date.getDate() + 1);
        }

    }

    rangeChange($event: Event) {
        this.getAnalytics();
    }
}
