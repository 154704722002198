import {AfterViewInit, Component, forwardRef, Inject, OnDestroy} from '@angular/core';
import {KioskBean, KioskState} from '../../model/model';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AppSettings} from '../../app.settings';
import {DataService} from '../../services/data.service';
import {DeviceSnapshot, IngredientSnapshot, KioskNOC, OrderSnapshot, OrderStepsNocSnapshot} from '../../model/custom-model';
import {KioskService} from '../../services/kiosk.service';
import {AppComponent} from '../../app.component';
import {Helper} from '../../common/helper';
import {ActivatedRoute, Router} from '@angular/router';

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    selector: 'kiosk-noc-component',
    templateUrl: 'kiosk-noc.component.html'
})
export class KioskNocComponent implements AfterViewInit, OnDestroy {

    kiosk: KioskBean = {} as KioskBean;
    getIngredientPercent = KioskNocComponent.getIngredientPercent;
    url: SafeResourceUrl;
    channel: any;
    kioskNOC: KioskNOC;
    kioskState: KioskState;
    now: number;
    kioskId: number;
    operatorId: number;
    steps: OrderStepsNocSnapshot;
    lastStepsChangeTime: number;

    constructor(private dataService: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private kioskService: KioskService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    static getIngredientPercent(ingredient: IngredientSnapshot) {
        return ingredient.current_amount <= 0 ? 0 : ingredient.current_amount / ingredient.max_amount * 100;
    }


    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.kioskId = params['kioskId'] != null ? params['kioskId'] : 0;
            this.operatorId = params['operatorId'] != null ? params['operatorId'] : 0;
            this.getKiosk();
        });
    }


    getKiosk() {
        this.kioskService.getKiosk(this.operatorId, this.kioskId).subscribe(response => {
            this.kiosk = Helper.copyObject(response.value);
            this.initFirebase();
        });
    }

    initFirebase() {
        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            this.channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('kiosk')
                .child('' + this.kiosk.id);

            this.channel.on('value', data => {

                this.kioskNOC = data.val();
                this.now = (new Date()).getTime();
                this.kioskState = this.kioskNOC.kiosk_state.value;

                if (this.kioskNOC.order_steps_noc != this.steps) {
                    this.lastStepsChangeTime = (new Date()).getTime();
                    this.steps = this.kioskNOC.order_steps_noc;
                }

                if (this.kioskNOC.devices_snapshot) {

                    this.kioskNOC.devices_snapshot.value.devices.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                }

                if (this.kioskNOC.orders_snapshot) {

                    this.kioskNOC.orders_snapshot.value.sort((a, b) => {
                        if (!a.start_date && b.start_date) {
                            return 1;
                        }
                        if (!a.start_date && !b.start_date) {
                            return a.state_change_date - b.state_change_date;
                        }
                        return a.start_date - b.start_date;
                    });
                }
            });
        }, error => {
            console.log(error);
        });
    }

    getOrderForJar(device: DeviceSnapshot) {
        if (this.kioskNOC.orders_snapshot) {
            for (const order of this.kioskNOC.orders_snapshot.value) {
                if (order.jar_name == device.name) {
                    return '#' + order.id + ' ' + (order.jar_place ? order.jar_place : '');
                }
            }
        }
    }

    ngOnDestroy() {
        try {
            firebase.app().delete();
        } catch (error) {
        }
    }

    getLastIngredientStep(device: DeviceSnapshot, ingredient: IngredientSnapshot) {
        if (this.kioskNOC && this.kioskNOC.order_steps_noc) {

            const orders = this.kioskNOC.order_steps_noc.value;
            for (const id in orders) {
                if (orders[id].steps) {
                    for (const step of orders[id].steps) {
                        if (step && step.device_name == device.name && step.state != 'WAITING') {
                            if (step.process.indexOf(ingredient.name) > 0) {
                                let process = step.process.replace(device.name, '').replace(' -> ', '');
                                if (step.dispensed_weight) {
                                    process += ' Response {' + step.dispensed_weight + '}';
                                }
                                return process;
                            }
                        }
                    }
                }
            }
        }
        return device.invalid ? 'INVALID' : device.state;
    }

    getLastOrderStep(order: OrderSnapshot) {
        let result;
        if (this.kioskNOC && this.kioskNOC.order_steps_noc) {

            const orders = this.kioskNOC.order_steps_noc.value;
            if (orders[order.id] && orders[order.id].steps) {
                if (orders[order.id].steps) {
                    for (const step of orders[order.id].steps) {
                        if (step && step.state != 'WAITING') {
                            result = step.process;
                        }
                    }
                }
            }
        }
        return result;
    }

    getRunningStep(device: DeviceSnapshot) {
        if (this.kioskNOC && this.kioskNOC.order_steps_noc) {

            const orders = this.kioskNOC.order_steps_noc.value;
            for (const id in orders) {
                if (orders[id].steps) {
                    for (const step of orders[id].steps) {
                        if (step && step.device_name == device.name && step.state == 'RUNNING') {
                            return step.process.replace(device.name, '').replace(' -> ', '');
                        }
                    }
                }
            }
        }
        return '';
    }

    refill(deviceId: number) {
        this.kioskService.refillIngredient(this.kiosk, deviceId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Refilled');

            } else {
                this.app.showError('Can\'t refill');
            }
        }, error => {
            this.app.showError('Can\'t refill');
        });
    }

    unlock(deviceId: number) {
        this.kioskService.unlockDevice(this.kiosk, deviceId).subscribe(response => {
            if (response.success) {
                this.app.showNotification('Unlocked');

            } else {
                this.app.showError('Can\'t unlock');
            }
        }, error => {
            this.app.showError('Can\'t unlock');
        });
    }

    isRunning(): boolean {
        if (this.kioskNOC && this.kioskNOC.orders_snapshot) {

            for (const id in this.kioskNOC.orders_snapshot.value) {
                const now = (new Date()).getTime();
                return (now - this.lastStepsChangeTime) < 60000;
            }
        }

        return false;
    }
}
