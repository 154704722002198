import {publishReplay, refCount} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {KioskModelBean, PageBean, ResponseBean, ResponseItemBean} from '../model/model';

@Injectable()
export class KioskModelService extends BaseRequestService {

    private kioskModels: Observable<any>;

    getKioskModel(id: number): Observable<ResponseItemBean<KioskModelBean>> {
        return this.get('admin/kiosk/model/' + id);
    }

    getKioskModels(limit?: number, offset?: number): Observable<PageBean<KioskModelBean>> {
        if (!this.kioskModels) {
            this.kioskModels = this.get('admin/kiosk/model/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.kioskModels;
    }

    addKioskModel(model: KioskModelBean): Observable<ResponseItemBean<number>> {
        this.kioskModels = null;
        return this.post('admin/kiosk/model/add', model);
    }

    updateKioskModel(model: KioskModelBean): Observable<ResponseBean> {
        this.kioskModels = null;
        return this.put('admin/kiosk/model/update', model);
    }

    deleteKioskModel(model: KioskModelBean): Observable<ResponseBean> {
        this.kioskModels = null;
        return this.del('admin/kiosk/model/' + model.id + '/delete');
    }

    clear() {
        this.kioskModels = null;
    }

    copyServingSteps(model: KioskModelBean, name: string): Observable<ResponseItemBean<KioskModelBean>> {
        return this.get('admin/kiosk/model/' + model.id + '/copy_serving_steps?name=' + name);
    }
}
