import {environment} from './environments/environment';

export class AppSettings {

    public static FB_USER = 'firebase@6dbytes.com';
    public static FB_PASSWORD = 'blendid!';

    public static getFirebaseConfig() {
        return environment.FIREBASE_CONFIG;
    }
}
