import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Field, FieldType, FieldTypeHelper} from '../common/field';
import {ImageManagerSelectComponent} from '../dashboard/image-manager/image-manager.select.component';

@Component({
    selector: 'fields-list-component',
    templateUrl: 'fields-list.component.html'
})
export class FieldsListComponent implements AfterViewInit {

    @Input() name: string = 'control';

    @Input() fields: Field[];

    constructor(private elRef: ElementRef) {
    }
    @Input() value: any;
    @ViewChild(ImageManagerSelectComponent, {static: false}) imageManagerSelectComponent: ImageManagerSelectComponent;
    selectedField: Field;

    static parseISOLocal(s) {
        const b = s.split(/\D/);
        return new Date(Date.UTC(b[0], b[1] - 1, b[2], b[3], b[4], b[5]));
    }

    public valid() {
        if (!this.fields) {
            return true;
        }
        for (const f of this.fields) {
            if (f.required && !this.value[f.field]) {
                return false;
            }
        }
        return true;
    }

    isObject(obj) {
        return obj instanceof Object;
    }

    getType(fieldType: FieldType) {
        return FieldTypeHelper.toString(fieldType);
    }

    isInput(fieldType: FieldType) {
        switch (fieldType) {
            case FieldType.TEXT:
            case FieldType.NUMBER:
            case FieldType.TIMEZONE:
            case FieldType.TEL:
                return true;
            default:
                return false;
        }
    }

    isDate(fieldType: FieldType) {
        return fieldType == FieldType.DATE;
    }

    isCheckbox(fieldType: FieldType) {
        return fieldType == FieldType.CHECKBOX;
    }

    isSelect(fieldType: FieldType) {
        return fieldType == FieldType.SELECT;
    }

    isTextArea(fieldType: FieldType) {
        return fieldType == FieldType.TEXT_AREA;
    }

    isFile(fieldType: FieldType) {
        return fieldType == FieldType.FILE;
    }

    public getValue(fieldName: string) {
        const selector = '#control_' + fieldName.replace('.', '');
        const div = this.elRef.nativeElement.querySelector(selector);
        if (div) {
            return div.value;
        }
        return null;
    }

    ngAfterViewInit(): void {
    }

    saveDate(event, field) {
        const date: Date = FieldsListComponent.parseISOLocal(event.value + 'T00:00:00');
        date.setTime(date.getTime() + 8 * 60 * 60 * 1000);
        this.value[field] = date.getTime();
    }

    saveDateField(event, field, subfield) {
        const date: Date = FieldsListComponent.parseISOLocal(event.value + 'T00:00:00');
        date.setTime(date.getTime() + 8 * 60 * 60 * 1000);
        this.value[field][subfield] = date.getTime();
    }

    selectImage(field: Field) {
        this.selectedField = field;
        this.imageManagerSelectComponent.open(field.subfield ? this.value[field.field][field.subfield] : this.value[field.field]);
    }

    onImageSelected(selectedImage: string) {
        if (this.selectedField.subfield) {
            this.value[this.selectedField.field][this.selectedField.subfield] = selectedImage;
        } else {
            this.value[this.selectedField.field] = selectedImage;
        }
    }
}
