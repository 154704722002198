import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {AppSettings} from '../../../app.settings';
import {DataService} from '../../../services/data.service';
import {KioskService} from '../../../services/kiosk.service';
import {AppComponent} from '../../../app.component';
import {ValueEditorComponent} from '../../../components/value-editor.component';
import {KioskBean, RobotPoseBean} from '../../../model/model';

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    selector: 'kiosk-robot-component',
    templateUrl: 'kiosk-robot.component.html'
})
export class KioskRobotComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    pose: RobotPoseBean;
    channel: any;

    @ViewChild('editorX', {static: false}) editorX: ValueEditorComponent;
    @ViewChild('editorY', {static: false}) editorY: ValueEditorComponent;
    @ViewChild('editorZ', {static: false}) editorZ: ValueEditorComponent;
    @ViewChild('editorRX', {static: false}) editorRX: ValueEditorComponent;
    @ViewChild('editorRY', {static: false}) editorRY: ValueEditorComponent;
    @ViewChild('editorRZ', {static: false}) editorRZ: ValueEditorComponent;

    @ViewChild('editorBase', {static: false}) editorBase: ValueEditorComponent;
    @ViewChild('editorShoulder', {static: false}) editorShoulder: ValueEditorComponent;
    @ViewChild('editorElbow', {static: false}) editorElbow: ValueEditorComponent;
    @ViewChild('editorWrist1', {static: false}) editorWrist1: ValueEditorComponent;
    @ViewChild('editorWrist2', {static: false}) editorWrist2: ValueEditorComponent;
    @ViewChild('editorWrist3', {static: false}) editorWrist3: ValueEditorComponent;

    constructor(private dataService: DataService,
                private kioskService: KioskService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    initFirebase() {
        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            this.channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('location')
                .child('' + this.kiosk.operatorId)
                .child('kiosk')
                .child('' + this.kiosk.id)
                .child('robot_control')
                .child('pose');

            this.channel.on('value', data => {
                this.pose = data.val();

                if (this.pose) {
                    this.editorX.val = this.pose.x;
                    this.editorY.val = this.pose.y;
                    this.editorZ.val = this.pose.z;
                    this.editorRX.val = this.pose.rx;
                    this.editorRY.val = this.pose.ry;
                    this.editorRZ.val = this.pose.rz;

                    this.editorBase.val = this.pose.base;
                    this.editorShoulder.val = this.pose.shoulder;
                    this.editorElbow.val = this.pose.elbow;
                    this.editorWrist1.val = this.pose.wrist1;
                    this.editorWrist2.val = this.pose.wrist2;
                    this.editorWrist3.val = this.pose.wrist3;
                }
            });
        });
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;


        this.kioskService.requestRobotPose(this.kiosk).subscribe(response => {
            if (response.success) {
                this.initFirebase();
            } else {
                this.app.showError('Can\'t connect to the Robot');
            }
        }, error => {
            this.app.showError('Can\'t connect to the Robot');
        });

    }

    startPrograms() {
    }

    stopPrograms() {
    }

    onValueChanged(ev: any) {

        if (!this.channel) {
            return;
        }
        switch (ev.title) {
            case 'X':
                this.pose.x = ev.value;
                break;
            case 'Y':
                this.pose.y = ev.value;
                break;
            case 'Z':
                this.pose.z = ev.value;
                break;
            case 'RX':
                this.pose.rx = ev.value;
                break;
            case 'RY':
                this.pose.ry = ev.value;
                break;
            case 'RZ':
                this.pose.rz = ev.value;
                break;

            case 'Base':
                this.pose.base = ev.value;
                break;
            case 'Shoulder':
                this.pose.shoulder = ev.value;
                break;
            case 'Elbow':
                this.pose.elbow = ev.value;
                break;
            case 'Wrist 1':
                this.pose.wrist1 = ev.value;
                break;
            case 'Wrist 2':
                this.pose.wrist2 = ev.value;
                break;
            case 'Wrist 3':
                this.pose.wrist3 = ev.value;
                break;
        }

        this.kioskService.sendRobotPose(this.kiosk, this.pose).subscribe(response => {
            if (!response.success) {
                this.app.showError('Can\'t connect to the Robot');
            }
        }, error => {
            this.app.showError('Can\'t connect to the Robot');
        });

        this.channel.set(this.pose, complete => {
            console.log('complete');
        });
    }
}
