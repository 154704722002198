import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {KioskModelBean} from '../../../model/model';
import {Helper} from '../../../common/helper';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskModelQuestionsEditComponent} from './kiosk-model-questions.edit.component';
import {StringBean} from '../../../model/custom-model';

@Component({
    selector: 'kiosk-model-questions-component',
    templateUrl: 'kiosk-model-questions.component.html'
})
export class KioskModelQuestionsComponent implements AfterViewInit {

    kioskModel: KioskModelBean = {} as KioskModelBean;
    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskModelQuestionsEditComponent, {static: false}) modal: KioskModelQuestionsEditComponent;

    fields: Column[] = [
        new Column('value', 'Value'),
    ];

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    onShow(kioskModel: KioskModelBean) {
        this.kioskModel = kioskModel;
        this.updateTable();
    }

    onEdit(items: StringBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Question');
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.kioskModel.recipeMakerQuestions.indexOf(step);
            this.kioskModel.recipeMakerQuestions.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Email');
    }

    addValue(initQuestion: StringBean, question: StringBean) {

        if (initQuestion.value == null) {
            this.kioskModel.recipeMakerQuestions.push(question.value);

        } else {

            let ind = -1;
            let i = 0;
            for (const email of this.kioskModel.recipeMakerQuestions) {
                if (email == initQuestion.value) {
                    ind = i;
                }
                i++;
            }

            console.log(ind);

            if (ind >= 0) {
                this.kioskModel.recipeMakerQuestions.splice(ind, 1);
                this.kioskModel.recipeMakerQuestions.splice(ind, 0, question.value);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const questions: StringBean[] = [];
            for (const item of this.kioskModel.recipeMakerQuestions) {
                const question: StringBean = {} as StringBean;
                question.value = item;
                questions.push(question);
            }

            this.table.setData(questions, this.fields);
        }
    }
}
