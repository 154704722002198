import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, Output, ViewChild} from '@angular/core';
import {Column} from '../common/column';
import {DatePipe} from '@angular/common';
import {Helper} from '../common/helper';
import {isNumber} from 'util';
import {Lightbox} from 'ngx-lightbox';
import {environment} from '../environments/environment';
import {NgxCoolDialogsService} from 'ngx-cool-dialogs';
import {AppComponent} from '../app.component';

export class ActionButton {
    title: string;
    icon: string;
    clazz: string;
    dataObject: object;
    dataFunction: (bean: any) => any;

    constructor(title: string, clazz: string, icon: string) {
        this.title = title;
        this.clazz = clazz;
        this.icon = icon;
    }

    click(clickObject: object, clickFunction: (bean: any) => any) {
        this.dataObject = clickObject;
        this.dataFunction = clickFunction;
        return this;
    }
}

@Component({
    selector: 'table-component',
    templateUrl: 'table.component.html',
})

export class TableComponent implements AfterViewInit {

    table: JQuery;
    removeButton: JQuery;
    extraButton: JQuery;
    addButton: JQuery;
    toolbar: JQuery;

    @Output() onView: EventEmitter<any> = new EventEmitter();
    @Output() onEdit: EventEmitter<any> = new EventEmitter();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    @Output() onExtra: EventEmitter<any> = new EventEmitter();
    @Output() onAdd: EventEmitter<any> = new EventEmitter();
    @Output() onStats: EventEmitter<any> = new EventEmitter();
    @Output() onQuickView: EventEmitter<any> = new EventEmitter();
    @Output() onClickRow: EventEmitter<any> = new EventEmitter();
    @Output() onSearch: EventEmitter<any> = new EventEmitter();
    @Output() dataFunction: EventEmitter<any> = new EventEmitter();

    @ViewChild('table', {static: false}) tableElement: ElementRef;
    @ViewChild('remove', {static: false}) removeElement: ElementRef;
    @ViewChild('extra', {static: false}) extraElement: ElementRef;
    @ViewChild('add', {static: false}) addElement: ElementRef;
    @ViewChild('copy', {static: false}) copyElement: ElementRef;
    @ViewChild('toolbar', {static: false}) toolbarElement: ElementRef;

    @Input() buttons: ActionButton[];
    @Input() searchText: string;
    @Input() removeText: string = 'Delete';
    @Input() extraText: string = 'Extra';
    @Input() statsTitle: string = 'Stats';
    @Input() sortName: string;
    @Input() sortOrder: string = 'asc';
    @Input() extraAutoDisabled = true;

    guid: string;
    initialized: boolean;
    touchLocked: boolean;

    private album = [];

    constructor(private lightbox: Lightbox,
                private coolDialogs: NgxCoolDialogsService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
        this.initialized = false;
    }

    ngAfterViewInit(): void {
        this.init();
    }

    init() {

        if (this.initialized) {
            return;
        }

        this.table = $(this.tableElement.nativeElement);
        this.removeButton = $(this.removeElement.nativeElement);
        this.extraButton = $(this.extraElement.nativeElement);
        this.addButton = $(this.addElement.nativeElement);

        this.removeButton.hide();
        this.addButton.hide();
        this.extraButton.hide();

        this.guid = Helper.generateGUID();
        this.toolbar = $(this.toolbarElement.nativeElement);
        this.toolbar.attr('id', this.guid);

        this.initialized = true;
    }

    setData(data: object[], fields: Column[]) {

        this.init();

        const datePipe = new DatePipe('en-US');
        const serverSide = this.dataFunction.observers.length != 0;

        if (this.removeButton) {
            this.removeButton.show();
        }
        if (this.addButton) {
            this.addButton.show();
        }
        if (this.extraButton) {
            this.extraButton.show();
        }

        if (this.extraButton && this.onExtra.observers.length == 0) {
            this.extraButton.hide();
        }
        if (this.removeButton && this.onRemove.observers.length == 0) {
            this.removeButton.hide();
        }
        if (this.addButton && this.onAdd.observers.length == 0) {
            this.addButton.hide();
        }

        const columns = [];
        if (this.onRemove.observers.length != 0) {
            columns.push({field: 'state', checkbox: true});
        }

        if (this.buttons && this.buttons.length > 0) {
            const events = {};

            for (const bt of this.buttons) {
                events['click .' + bt.clazz] = (e, value, row, _) => {
                    bt.dataFunction.call(bt.dataObject, row);
                };
            }

            columns.push({
                title: 'Actions',
                align: 'center',
                events,
                formatter: () => {

                    const clazz = 'btn btn-simple btn-icon table-action';
                    let result = '<div class="table-icons">';
                    if (this.buttons) {
                        for (const bt of this.buttons) {
                            result += '<a rel="tooltip" title="' + bt.title + '" class="' + clazz + ' btn-danger '
                                + bt.clazz + '" href="javascript:void(0)"><i class="' + bt.icon + '"></i></a>';
                        }
                    }
                    result += '</div>';
                    return result;
                }
            });
        }

        const self = this;
        this.album = [];


        for (const field of fields) {
            columns.push({
                    sortable: !serverSide,
                field: field.field, title: field.title, formatter(orig, formattedData) {
                    if (field.subfield && formattedData[field.field]) {
                        orig = formattedData[field.field][field.subfield];
                    }

                        let result = orig;

                        if (field.isThumbnail) {
                            if (orig) {
                                const arr = orig.replace(environment.FILE_STORAGE_URL, '').split('?');
                                const id = arr[0];

                                const thumb = environment.SERVER_URL + 'admin/image/' + id + '/thumbnail?size=' + field.thumbnailSize
                                    + '&' + (arr.length > 1 ? arr[1] : '');
                                result = '<img class="img-thumbnail ' +
                                    (field.thumbnailRotated ? 'img-rotated' : '') +
                                    '" width="' + field.thumbnailSize + 'px" height="' +
                                    field.thumbnailSize + 'px" src="' + (field.serverThumbnail ? thumb : orig) + '" alt=""/>';
                            } else {
                                result = '<img class="img-thumbnail ' +
                                    '" width="' + field.thumbnailSize + 'px" height="' +
                                    field.thumbnailSize + 'px" src="assets/img/no-image.png" alt=""/>';
                            }


                            if (orig) {
                                self.album.push({
                                    thumb: orig,
                                    src: orig
                                });
                            }

                            if (field.dataFunction) {
                                result += field.dataFunction.call(field.dataObject, formattedData);
                            }
                        } else if (field.dataFunction) {
                            result = field.dataFunction.call(field.dataObject, formattedData);
                        } else if (field.isCheckbox) {
                            result = '<input type="checkbox" disabled ' + (orig ? 'checked' : '') + '/>';

                        } else if (field.isDecimal) {
                            result = isNumber(orig) ? orig.toFixed(2) : orig;

                        } else if (field.isDate) {
                            result = (orig == 0 ? '-' : datePipe.transform(orig, 'MM/dd/yyyy hh:mm a', 'GMT-7'));

                        } else if (field.isShortDate) {
                            result = (orig == 0 ? '-' : datePipe.transform(orig, 'MM/dd/yyyy', 'GMT-7'));

                        } else if (field.isTime) {
                            result = datePipe.transform(orig, 'hh:mm:ss a', 'GMT-7');

                        } else if (field.isShortTime) {
                            result = datePipe.transform(orig, 'mm:ss');

                        } else if (field.enumType) {
                            result = field.enumType[orig];

                        } else if (field.concatFields.length > 0) {

                            if (!result) {
                                result = '';
                            }
                            for (const concat of field.concatFields) {
                                if (formattedData[concat]) {
                                    result += (', ' + formattedData[concat]);
                                }
                            }
                        }

                        if (field.isButton) {
                            result = '<button type="button" class="btn btn-simple">' + result + '</button>';
                        }
                        return result;
                    },

                    events: {
                        'click .img-thumbnail':
                            (e, value, row, index) => {

                                for (let i = 0; i < this.album.length; i++) {
                                    if (this.album[i].thumb == value) {
                                        this.lightbox.open(this.album, i);
                                        break;
                                    }
                                }
                                // this.modal.open(value, field.thumbnailRotated);
                            },
                        'click .btn':
                            (e, value, row, index) => {
                                field.clickFunction.call(field.clickObject, row);
                            }
                    }
                }
            );
        }

        if (
            this.onRemove.observers.length != 0 ||
            this.onEdit.observers.length != 0 ||
            this.onView.observers.length != 0) {

            const events = {
                'click .view': (e, value, row, index) => {
                    this.onView.emit([row]);
                    this.lockTouch();
                },
                'click .edit': (e, value, row, index) => {
                    this.onEdit.emit([row]);
                    this.lockTouch();
                },
                'click .stats': (e, value, row, index) => {
                    this.onStats.emit([row]);
                    this.lockTouch();
                },
                'click .quick_view': (e, value, row, index) => {
                    this.onQuickView.emit([row]);
                    this.lockTouch();
                },
                'click .remove': (e, value, row, index) => {
                    this.showDeleteConfirmation([row]);
                    this.lockTouch();
                },
                'click .bbbs': (e, value, row, index) => {
                    this.showDeleteConfirmation([row]);
                    this.lockTouch();
                }
            };

            columns.push({
                title: 'Actions',
                align: 'center',
                events,
                formatter: () => {
                    return this.getActionButtons();
                }
            });
        }

        const config = {
            data,
            sortName: this.sortName,
            sortOrder: this.sortOrder,
            toolbar: '#' + this.guid,
            striped: true,
            showColumns: true,
            pageSize: 50,
            search: !serverSide || this.onSearch.observers.length != 0,
            pagination: true,
            pageList: [8, 10, 25, 50],
            searchText: this.searchText,
            formatShowingRows(pageFrom, pageTo, totalRows) {
            },
            formatRecordsPerPage(pageNumber) {
                return pageNumber + ' rows visible';
            },
            icons: {
                refresh: 'fa fa-refresh',
                toggle: 'fa fa-th-list',
                columns: 'fa fa-columns',
                detailOpen: 'fa fa-plus-circle',
                detailClose: 'fa fa-minus-circle'
            },
            formatLoadingMessage() {
                return '<img src="../../assets/img/loading_spinner.gif" style="padding-top: 60px"/>';
            },
            columns,
            onClickRow: (row, element) => {
                if (this.onClickRow.observers.length != 0 && !this.touchLocked) {
                    this.onClickRow.emit(row);
                }
            },
            onSearch: (text) => {
                if (this.onSearch.observers.length != 0 && !this.touchLocked) {
                    this.onSearch.emit(text);
                }
                return true;
            }
        };

        if (serverSide) {
            config['sidePagination'] = 'server';

            config['ajax'] = (params) => {
                this.dataFunction.emit(params);
            };
        }

        this.table.bootstrapTable('destroy');
        this.table.bootstrapTable(config);

        $('[rel="tooltip"]').tooltip();

        this.table.on('check.bs.table uncheck.bs.table ' +
            'check-all.bs.table uncheck-all.bs.table', () => {
            this.removeButton.prop('disabled', !this.table.bootstrapTable('getSelections').length);
            if (this.extraAutoDisabled) {
                this.extraButton.prop('disabled', !this.table.bootstrapTable('getSelections').length);
            }
        });

        for (const field of fields) {
            if (field.isHidden) {
                this.table.bootstrapTable('hideColumn', field.field);
            }
        }

    }

    addItem() {
        this.onAdd.emit();
    }

    deleteSelectedItems() {
        this.showDeleteConfirmation(this.table.bootstrapTable('getSelections'));
    }

    extraMultipleEvent() {
        this.onExtra.emit(this.table.bootstrapTable('getSelections'));
    }

    public showDeleteConfirmation(items: object[]) {

        this.coolDialogs.confirm('Are you sure?', {
            okButtonText: `Yes, ${this.removeText}!`,
            cancelButtonText: 'No, Discard changes',
        })
            .subscribe(res => {
                if (res) {
                    this.onRemove.emit(items);
                    this.app.showNotification('Done! The table will be reloaded.');

                } else {
                    this.app.showNotification('Cancelled! Your data is safe.');
                }
            });
    }

    getActionButtons() {
        const clazz = 'btn btn-simple btn-icon table-action';

        let result = '<div class="table-icons">';
        if (this.onView.observers.length) {
            result += '<a rel="tooltip" title="View" class="' + clazz +
                ' btn-info view" href="javascript:void(0)"><i class="ti-image"></i></a>';
        }
        if (this.onEdit.observers.length) {
            result += '<a rel="tooltip" title="Edit" class="' + clazz +
                ' btn-warning edit" href="javascript:void(0)"><i class="ti-pencil-alt"></i></a>';
        }
        if (this.onStats.observers.length) {
            result += '<a rel="tooltip" title="' + this.statsTitle + '" class="' + clazz +
                ' btn-danger stats" href="javascript:void(0)"><i class="ti-stats-up"></i></a>';
        }
        if (this.onQuickView.observers.length) {
            result += '<a rel="tooltip" title="Quick View" class="' + clazz +
                ' btn-danger quick_view" href="javascript:void(0)"><i class="ti-camera"></i></a>';
        }
        if (this.onRemove.observers.length) {
            result += '<a rel="tooltip" title="' + this.removeText + '" class="' + clazz +
                ' btn-danger remove" href="javascript:void(0)"><i class="ti-close"></i></a>';
        }
        result += '</div>';

        return result;
    }

    disableEditing() {
        this.onEdit.observers = [];
    }

    disableAdding() {
        this.onAdd.observers = [];
        this.addButton.hide();
    }

    public refresh() {
        this.table.bootstrapTable('refresh');
    }

    private lockTouch() {
        this.touchLocked = true;
        setTimeout(() => {
            this.touchLocked = false;
        }, 1000);
    }
}
