import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ActionButton, TableComponent} from '../../../components/table.component';
import {LearnResult, VisionService} from '../../../services/vision.service';
import {Column} from '../../../common/column';
import {AppComponent} from '../../../app.component';
import {ImageSelectComponent} from './image.select.component';

@Component({
    selector: 'vision-learn-component',
    templateUrl: 'vision-learn.component.html'
})
export class VisionLearnComponent implements AfterViewInit {

    trainingFields: Column[] = [
        new Column('date', 'Date').date(),
        new Column('path', 'Image').thumbnail(50, false, false),
        new Column('preview', 'Preview').thumbnail(50, false, false),
    ];
    @ViewChild('trainingTable', {static: false}) trainingTable: TableComponent;
    @ViewChild(ImageSelectComponent, {static: false}) imageSelectComponent: ImageSelectComponent;

    resultsButtons: ActionButton[] = [
        new ActionButton('Add to Data', 'addToData', 'ti-plus').click(this, this.addToData),
    ];
    tags: string[];

    constructor(private visionService: VisionService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow() {
        this.loadVision();
    }

    setTags(tags: string[]) {
        this.tags = tags;
    }

    loadVision() {
        this.visionService.getImages().subscribe(response => {
            if (response.success) {
                this.trainingTable.setData(response.value, this.trainingFields);

            } else {
                this.app.showError('Can\'t get images');
            }

        }, error => {
            console.log(error);
            this.app.showError('Can\'t get images');
        });
    }

    addToData(learnResult: LearnResult) {
        this.imageSelectComponent.open(this.tags, learnResult);
    }


    onRemoveLearning(results: LearnResult[]) {

        for (const result of results) {
            this.visionService.deleteLearningResult(result).subscribe(response => {
                if (response.success) {
                    this.app.showNotification('Removed');
                    this.loadVision();

                } else {
                    this.app.showError('Can\'t remove image');
                }
            }, error => {
                this.app.showError('Can\'t remove image');
            });
        }
    }
}
