import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {OperatorsService} from '../../services/operators.service';
import {OperatorsComponent} from './operators.component';
import {AppComponent} from '../../app.component';
import {HttpClient} from '@angular/common/http';
import {OperatorBean} from '../../model/model';

@Component({
    selector: 'operators-edit-component',
    templateUrl: 'operators.edit.component.html'
})

export class OperatorsEditComponent {

    location: OperatorBean = {} as OperatorBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('name', 'Name').require(),
        new Field('corporateAddress', 'Corporate Address'),
        new Field('corporateContactName', 'Corporate Contact Name'),
        new Field('corporateContactEmail', 'Corporate Contact Email'),
        new Field('corporateContactPhone', 'Corporate Contact Phone'),
        new Field('operationsContactName', 'Operations Contact Name'),
        new Field('operationContactEmail', 'Operation Contact Email'),
        new Field('operationContactPhone', 'Operation Contact Phone'),
        new Field('status', 'Status', FieldType.SELECT).setOptions(['ACTIVE', 'PROVISIONING', 'WAITING', 'INACTIVE'])
    ];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private operatorsService: OperatorsService,
                private http: HttpClient,
                @Inject(forwardRef(() => OperatorsComponent)) private parent: OperatorsComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(location: OperatorBean, title: string) {

        this.location = (location ? Helper.copyObject(location) : {} as OperatorBean);
        this.base.show(title);
    }

    save() {
        if (!this.location.id) {
            this.operatorsService.addOperator(this.location).subscribe(response => {

                if (response.success) {
                    this.parent.loadOperators();
                } else {
                    this.app.showError('Can\'t create operator');
                }
            }, error => {
                this.app.showError('Can\'t create operator');
            });

        } else {
            this.operatorsService.updateOperator(this.location).subscribe(response => {

                if (response.success) {
                    this.parent.loadOperators();
                } else {
                    this.app.showError('Can\'t update operator');
                }
            }, error => {
                this.app.showError('Can\'t update operator');
            });
        }
    }

}

