import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {PageBean, ResponseBean, ReviewBean} from '../model/model';

@Injectable()
export class ReviewService extends BaseRequestService {

    getReviews(recipeId: number, limit?: number, offset?: number): Observable<PageBean<ReviewBean>> {
        return this.get('admin/review/recipe/' + recipeId + '/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    getReviewsByKiosk(kioskId: number, recipeId: number, limit?: number, offset?: number): Observable<PageBean<ReviewBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        if (recipeId != null) {
            p['recipeId'] = recipeId;
        }
        return this.get('admin/review/kiosk/' + kioskId + '/list', p);
    }


    downloadReviewsByKiosk(kioskId: number, recipeId: number, limit?: number, offset?: number): Observable<Blob> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        if (recipeId != null) {
            p['recipeId'] = recipeId;
        }
        return this.blob('admin/review/kiosk/' + kioskId + '/export_csv', p);
    }

    deleteReview(review: ReviewBean): Observable<ResponseBean> {
        return this.del('admin/review/' + review.orderId + '/delete');
    }

    updateReview(review: ReviewBean): Observable<ResponseBean> {
        return this.put('admin/review/update', review);
    }

}
