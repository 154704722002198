import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {DeviceService} from '../../services/device.service';
import {TableComponent} from '../../components/table.component';
import {DevicesEditComponent} from './devices.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {DeviceProcessEditComponent} from './device-process.edit.component';
import {AbstractDeviceBean, ProcessBean} from '../../model/model';

@Component({
    templateUrl: 'devices.component.html',
})

export class DevicesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(DevicesEditComponent, {static: false}) devicesEditComponent: DevicesEditComponent;
    @ViewChild(DeviceProcessEditComponent, {static: false}) processEditComponent: DeviceProcessEditComponent;

    fields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('model', 'Model'),
        new Column('size', 'Size'),
        new Column('type', 'Type'),
        new Column('robot', 'Robot').checkbox(),
    ];
    devices: AbstractDeviceBean[];

    constructor(private deviceService: DeviceService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadDevices();
    }

    loadDevices() {
        this.deviceService.getDevices().subscribe(devices => {
            this.devices = devices.list;
            this.table.setData(devices.list, this.fields);
        });
    }

    onEdit(items: AbstractDeviceBean[]) {
        if (items && items.length > 0) {
            this.devicesEditComponent.open(items[0], this.devices, 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const device of items) {

            this.deviceService.deleteDevice(device).subscribe(response => {
                if (response.success) {
                    if (device == items.pop()) {
                        this.loadDevices();
                    }

                } else {
                    this.app.showError('Can\'t delete device');
                }
            }, error => {
                this.app.showError('Can\'t delete device');
            });
        }
    }

    onAdd() {
        this.devicesEditComponent.open(null, this.devices, 'New Device');
    }

    openProcessEditComponent(item: ProcessBean) {
        this.processEditComponent.open(item);
    }

    saveProcess(process: ProcessBean, origProcess: ProcessBean) {

        this.devicesEditComponent.saveProcess(process, origProcess);
    }
}

