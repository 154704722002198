import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {KioskBean} from '../../../model/model';
import {Helper} from '../../../common/helper';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskMaintenanceOptionsEditComponent} from './kiosk-maintenance-options.edit.component';
import {StringBean} from '../../../model/custom-model';

@Component({
    selector: 'kiosk-maintenance-options-component',
    templateUrl: 'kiosk-maintenance-options.component.html'
})
export class KioskMaintenanceOptionsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskMaintenanceOptionsEditComponent, {static: false}) modal: KioskMaintenanceOptionsEditComponent;

    fields: Column[] = [
        new Column('value', 'Text'),
    ];

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        this.updateTable();
    }

    onEdit(items: StringBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Maintenance Option');
        }
    }

    onRemove(items: StringBean[]) {
        for (const step of items) {
            const index = this.kiosk.maintenanceOptions.indexOf(step.value);
            this.kiosk.maintenanceOptions.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Maintenance Option');
    }

    addUser(initValue: StringBean, value: StringBean) {


        if (initValue.value == null) {
            this.kiosk.maintenanceOptions.push(value.value);

        } else {

            let ind = -1;
            let i = 0;
            for (const opt of this.kiosk.maintenanceOptions) {
                if (opt == initValue.value) {
                    ind = i;
                }
                i++;
            }

            if (ind >= 0) {
                this.kiosk.maintenanceOptions.splice(ind, 1);
                this.kiosk.maintenanceOptions.splice(ind, 0, value.value);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {

            const values = [];
            for (const value of this.kiosk.maintenanceOptions) {
                values.push({value} as StringBean);
            }

            this.table.setData(values, this.fields);
        }
    }
}
