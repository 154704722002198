import {publishReplay, refCount} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {CategoryBean, CategoryPriorityBean, ResponseBean} from '../model/model';

@Injectable()
export class CategoryService extends BaseRequestService {

    private categories: Observable<any>;

    getCategories(): Observable<CategoryBean[]> {
        if (!this.categories) {
            this.categories = this.get('admin/category/list').pipe(
                publishReplay(1),
                refCount());
        }
        return this.categories;
    }

    addCategory(category: CategoryBean): Observable<ResponseBean> {
        this.categories = null;
        return this.post('admin/category/add', category);
    }

    updateCategory(category: CategoryBean): Observable<ResponseBean> {
        this.categories = null;
        return this.put('admin/category/' + category.id + '/update', category);
    }

    deleteCategory(category: CategoryBean): Observable<ResponseBean> {
        this.categories = null;
        return this.del('admin/category/' + category.id + '/delete');
    }

    hasRecipes(category: CategoryBean): Observable<ResponseBean> {
        return this.get('admin/category/' + category.id + '/has_recipes');
    }

    updatePriorities(priorities: CategoryPriorityBean[]): Observable<ResponseBean> {
        return this.put('admin/category/update_priorities', priorities);
    }

    clear() {
        this.categories = null;
    }
}
