import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {AppComponent} from '../../app.component';
import {ProfileService} from '../../services/profile.service';
import {Helper} from '../../common/helper';
import {ForgotPasswordComponent} from './forgot-password.component';

@Component({
    templateUrl: 'login.component.html'
})

export class LoginComponent {

    @ViewChild(ForgotPasswordComponent, {static: false}) forgotPasswordComponent: ForgotPasswordComponent;

    constructor(public router: Router,
                private userService: ProfileService,
                private dataService: DataService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    login(event, username, password) {
        event.preventDefault();

        this.userService.login(username, Helper.encodePassword(password))
            .subscribe(response => {

                console.log(response);
                if (response.success) {

                    this.dataService.setUserId(response.userId);
                    this.router.navigate(['/confirmation']);


                } else {

                    this.app.showError('Can\'t login: ' + response.errorDescription);
                }
            }, error => {
                console.log(error);
                this.app.showError('Can\'t login');
            });
    }


    forgotPassword() {
        this.forgotPasswordComponent.open();
    }
}

