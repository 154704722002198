import {AfterViewInit, Component} from '@angular/core';

@Component({
    templateUrl: 'fullscreen-layout.component.html',
})
export class FullscreenLayoutComponent implements AfterViewInit {

    constructor() {
    }

    ngAfterViewInit(): void {
    }
}
