import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {KioskService} from '../../services/kiosk.service';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {KioskDataService} from '../../services/kiosk-data.service';
import {KioskBean} from '../../model/model';
import {Helper} from '../../common/helper';
import {KioskViewComponent} from './view/kiosk-view.component';
import {KioskPresentationComponent} from './presentation/kiosk-presentation.component';
import {KioskHistoryComponent} from './history/kiosk-history.component';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {KioskStatsComponent} from './stats/kiosk-stats.component';
import {KioskSensorsComponent} from './sensors/kiosk-sensors.component';

@Component({
    selector: 'kiosk-info-component',
    templateUrl: 'kiosk-info.component.html'
})

export class KioskInfoComponent implements AfterViewInit {

    kioskId: number;
    operatorId: number;
    kiosk: KioskBean = {} as KioskBean;

    @ViewChild(KioskViewComponent, {static: false}) kioskViewComponent: KioskViewComponent;
    @ViewChild(KioskSensorsComponent, {static: false}) kioskSensorsComponent: KioskSensorsComponent;
    @ViewChild(KioskStatsComponent, {static: false}) kioskStatsComponent: KioskStatsComponent;
    @ViewChild(KioskHistoryComponent, {static: false}) kioskHistoryComponent: KioskHistoryComponent;
    @ViewChild(KioskPresentationComponent, {static: false}) kioskPresentationComponent: KioskPresentationComponent;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private kioskService: KioskService,
                @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
                private kioskDataService: KioskDataService) {
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.kioskId = params['kioskId'] != null ? params['kioskId'] : 0;
            this.operatorId = params['operatorId'] != null ? params['operatorId'] : 0;
            this.getData();
        });
    }

    public getData() {
        this.kioskDataService.getData().subscribe(data => {
            this.getKiosk();
        });
    }

    getKiosk() {
        this.kioskService.getKiosk(this.operatorId, this.kioskId).subscribe(response => {
            this.kiosk = Helper.copyObject(response.value);
            // this.onLiveTabSelected(null);

            this.onLiveTabSelected(null);
            if (this.kiosk) {
                this.layout.navbarComponent.setTitle(this.kiosk.name);
            }
        });

    }

    onLiveTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskViewComponent.onShow(this.kiosk);
        }
    }

    onSensorsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskSensorsComponent.onShow(this.kiosk);
        }
    }

    onStatsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskStatsComponent.onShow(this.kiosk);
        }
    }

    onPresentationTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskPresentationComponent.onShow(this.kiosk, this.operatorId);
        }
    }

    onStepsHistoryTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskHistoryComponent.onShow(this.kiosk);
        }
    }

    editKiosk() {
        this.router.navigate(['/kiosk-details/'], {
            queryParams: {
                kioskId: this.kioskId,
                operatorId: this.operatorId
            }
        });
    }
}
