import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {DataService} from '../services/data.service';
import {isUndefined} from 'util';
import {Rights} from './rights';
import {DASHBOARD_MODULE_ROUTES} from '../dashboard/dashboard.routes';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private dataService: DataService) {
    }

    canActivate() {
        if (this.dataService.getUserInfo() != null) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}

@Injectable()
export class RightsGuard implements CanActivate {

    public static isCompanyAdmin() {

        if (!DataService.userInfo) {
            return false;
        }
        return (DataService.userInfo.user.rights == Rights.COMPANY_ADMIN);
    }

    public static isRootAdmin() {

        if (!DataService.userInfo) {
            return false;
        }
        return (DataService.userInfo.user.rights == Rights.ROOT_ADMIN);
    }

    public static isStoreManager() {

        if (!DataService.userInfo) {
            return false;
        }
        return (DataService.userInfo.user.rights == Rights.STORE_MANAGER);
    }

    public static isAdmin() {
        return this.isCompanyAdmin() || this.isRootAdmin();
    }

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {

        if (this.userAccess(route.data['rights'])) {
            return true;
        } else {

            if (route.url.length > 0 && route.url[0].path == 'home') {
                for (const child of DASHBOARD_MODULE_ROUTES[0].children) {

                    if (child.data) {
                        if (this.userAccess(child.data['rights'])) {
                            this.router.navigate([child.path]);
                            break;
                        }

                    } else if (child.children) {
                        for (const childRoute of child.children) {
                            if (childRoute.data) {
                                if (this.userAccess(childRoute.data['rights'])) {
                                    this.router.navigate([child.path]);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    public userAccess(rights: number[]) {

        if (isUndefined(rights) || !DataService.userInfo) {
            return false;
        }
        if (RightsGuard.isCompanyAdmin() || RightsGuard.isRootAdmin()) {
            return true;
        }

        for (const right of rights) {
            if ((DataService.userInfo.user.rights & right) == right || DataService.userInfo.user.rights == right) {
                return true;
            }
        }
        return false;
    }


}
