import {AfterViewInit, Component} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {DeviceBean, KioskBean, SimDeviceProcessBean} from '../../../model/model';

@Component({
    selector: 'kiosk-simulator-component',
    templateUrl: 'kiosk-simulator.component.html'
})
export class KioskSimulatorComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    simDeviceProcesses: SimDeviceProcessBean[] = [];
    devices: DeviceBean[] = [];

    constructor(private kioskService: KioskService) {
    }

    ngAfterViewInit(): void {
    }

    onShow(kiosk: KioskBean) {

        this.kiosk = kiosk;
        this.getDeviceProcessTime();
    }

    getDeviceProcessTime() {
        this.kioskService.getDeviceProcessTime(this.kiosk).subscribe(simDeviceProcesses => {
            this.simDeviceProcesses = simDeviceProcesses.value;
            this.kioskService.getDevices(this.kiosk).subscribe(devices => {
                this.devices = devices.list;
            });
        });
    }

    public setSimulatorMode(enabled) {

        this.kioskService.setSimulatorState(this.kiosk, enabled).subscribe(response => {
            this.kiosk.state = response.value;
        });
    }

    saveTimes() {
        this.kioskService.updateDeviceProcessTime(this.kiosk, this.simDeviceProcesses).subscribe(response => {
            this.getDeviceProcessTime();
        });
    }

    isSimulator() {
        return this.kiosk.simulation;
    }

    getDeviceName(deviceId: number) {
        for (const device of this.devices) {
            if (device.id == deviceId) {
                return device.name;
            }
        }
        return '';
    }

}
