import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {OperatorsService} from '../../services/operators.service';
import {Helper} from '../../common/helper';
import {KioskBean} from '../../model/model';
import {StoreManagerService} from '../../services/store-manager.service';

@Component({
    templateUrl: 'store-manager-kiosks.component.html',
})

export class StoreManagerKiosksComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    columns: Column[] = [
        new Column('name', 'Name').button(this, this.onView),
        new Column('stateCopy', 'State'),
        new Column('operationalSince', 'Operational Since').date(),
    ];

    compareBeans = Helper.compareBeans;
    kiosks: KioskBean[];

    constructor(private storeManagerService: StoreManagerService,
                private operatorsService: OperatorsService,
                private route: ActivatedRoute,
                private router: Router,
                private location: Location,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {

    }

    ngAfterViewInit(): void {

        this.loadKiosks();
    }

    loadKiosks() {

        this.storeManagerService.getKiosks().subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
    }

    setKiosks(kiosks) {

        this.kiosks = kiosks;

        const filteredKiosks = [];
        for (const kiosk of kiosks) {

            const kioskCopy = Helper.copyObject(kiosk);
            kioskCopy['stateCopy'] = kiosk.state;

            filteredKiosks.push(kioskCopy);
        }

        this.table.setData(filteredKiosks, this.columns);
    }


    onView(items: KioskBean[]) {

        if (items && items.length > 0) {
            this.router.navigate(['/kiosk-view/'], {
                queryParams: {
                    kioskId: items[0].id,
                    operatorId: items[0].operatorId
                }
            });
        }
    }

    onEdit(items: KioskBean[]) {

        if (items && items.length > 0) {
            this.router.navigate(['/store-manager-kiosk-view/'], {
                queryParams: {
                    kioskId: items[0].id,
                    operatorId: items[0].operatorId
                }
            });
        }
    }


}

