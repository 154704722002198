import {publishReplay, refCount} from 'rxjs/operators';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {
    AbstractDeviceBean,
    DeviceTestDataBean,
    HardwareProvisioningHistoryBean,
    HardwareWithProvisioningBean,
    PageBean,
    ResponseBean,
    ResponseItemBean
} from '../model/model';

@Injectable()
export class DeviceService extends BaseRequestService {

    private devices: Observable<any>;

    getDevices(limit?: number, offset?: number): Observable<PageBean<AbstractDeviceBean>> {
        if (!this.devices) {
            this.devices = this.get('admin/device/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.devices;
    }

    addDevice(device: AbstractDeviceBean): Observable<ResponseBean> {
        this.devices = null;
        return this.post('admin/device/add', device);
    }

    updateDevice(device: AbstractDeviceBean): Observable<ResponseBean> {
        this.devices = null;
        return this.put('admin/device/' + device.id + '/update', device);
    }

    deleteDevice(device: AbstractDeviceBean): Observable<ResponseBean> {
        this.devices = null;
        return this.del('admin/device/' + device.id + '/delete');
    }

    updateDeviceHardwareProvisioning(hw: HardwareWithProvisioningBean): Observable<ResponseBean> {

        return this.put('admin/device/hardware/' + hw.id + '/location/' + hw.locationId +
            '/kiosk/' + hw.kioskId + '/provisioning/update', hw.devices);
    }

    deleteDeviceHardware(hw: HardwareWithProvisioningBean): Observable<ResponseBean> {
        return this.del('admin/device/hardware/' + hw.id + '/delete');
    }

    getDevicesHardware(kioskId?: number, limit?: number, offset?: number): Observable<PageBean<HardwareWithProvisioningBean>> {

        const p = BaseRequestService.buildOffsetParams(limit, offset);
        // if (locationId != null && locationId != 0) p['locationId'] = locationId;
        if (kioskId != null && kioskId != 0) {
            p['kioskId'] = kioskId;
        }
        return this.get('admin/device/hardware/list', p);
    }

    getNotConnectedDevicesHardware(limit?: number, offset?: number): Observable<PageBean<HardwareWithProvisioningBean>> {

        const p = BaseRequestService.buildOffsetParams(limit, offset);
        p['not_connected'] = true;

        return this.get('admin/device/hardware/list', p);
    }

    startFunctionalTest(hardwareId: string, test: DeviceTestDataBean): Observable<ResponseBean> {
        return this.post('admin/device/hardware/' + hardwareId + '/test/functional/start', test);
    }

    startDeviceTest(hardwareId: string, abstractDeviceId: number, test: DeviceTestDataBean): Observable<ResponseBean> {
        return this.post('admin/device/hardware/' + hardwareId + '/test/device/' + abstractDeviceId + '/start', test);
    }

    updateSoftware(hardwareId: string): Observable<ResponseBean> {
        return this.get('admin/device/hardware/' + hardwareId + '/update_software');
    }

    getDeviceHistory(hardwareId: string): Observable<ResponseItemBean<HardwareProvisioningHistoryBean[]>> {
        return this.get('admin/device/hardware/' + hardwareId + '/history');
    }

    changeHostname(hardwareId: string, name: string): Observable<ResponseBean> {
        const p = {};
        p['name'] = name;
        return this.get('admin/device/hardware/' + hardwareId + '/change_hostname', p);
    }

    clear() {
        this.devices = null;
    }
}
