import {DeviceModelBean} from '../model/model';

export class KioskDefines {

    static getAnimationTime(step, speed) {
        switch (step) {
            case 'Pick':
            case 'Place':
                return 2000 / speed;
            case 'Dispense':
                return 5000 / speed;
            case 'Pour':
                return 15000 / speed;
            case 'Mix':
                return 7000 / speed;
            case 'On':
                return 60000 / speed;
            case 'DispenseCup':
                return 1000 / speed;
        }
        return 1000 / speed;
    }

    static getIngredientColor(ingredientName) {

        switch (ingredientName) {
            case 'Strawberry juice':
                return '#ff4c7f';
            case 'Soy milk':
            case 'Milk':
                return '#ffffff';
            case 'Peach juice':
                return '#ffc12d';
            case 'Water':
                return '#a4fffc';
            case 'Apple juice':
                return '#cd9f00';
            case 'Mixed berry juice':
                return '#99009b';
            case 'Banana, sliced':
                return '#fae535';
            case 'Frozen blueberries':
                return '#4643a1';
            case 'Frozen mangos':
                return '#ed5802';
            case 'Frozen peaches':
                return '#e68505';
            case 'Frozen strawberries':
                return '#ed4b07';
            case 'Ice':
                return '#a2c9d7';

            case 'Soy Sauce':
            case 'Black Tea':
                return '#521908';
            case 'Schezhuan Sauce':
                return '#853922';
            case 'Garlic Sauce':
                return '#ddd8b2';
            case 'Marinara Sauce':
                return '#d9190e';
            case 'Alfredo Sauce':
                return '#edd9b7';

            case 'Green Tea':
                return '#7f9b5a';
            case 'Coffee':
                return '#794609';
            default:
                return '#ff0000';
        }
    }

    static getCoordinateFixes(device: DeviceModelBean, pick, moveWithoutRobot) {
        // return [0, 0];

        if (!device || !device.deviceTemplate) {
            return [0, 0];
        }
        //
        // let fixX = 0, fixY = 0;
        if (device.deviceTemplate.abstractDevice.name == 'Ingredient Dispenser') {
            return [30, 30];
        }

        //
        // } else if (device.deviceTemplate.abstractDevice.name == 'Blender') {
        //     fixX = -5;
        //     fixY = 0;
        //
        // } else if (device.deviceTemplate.abstractDevice.name == 'Mix Station') {
        //     if (moveWithoutRobot) {
        //         fixX = 0;
        //         fixY = -90;
        //     } else {
        //         fixX = 0;
        //         fixY = -10;
        //     }
        //
        // } else if (device.deviceTemplate.abstractDevice.name == 'Pour Station') {
        //     fixX = -20;
        //     fixY = -30;
        //
        // } else if (device.deviceTemplate.abstractDevice.name == 'Jar') {
        //     fixX = 30;
        //     fixY = -10;
        //
        // } else if (device.deviceTemplate.abstractDevice.name == 'Cup Dispenser') {
        //     fixX = 0;
        //     fixY = -30;
        //
        // } else if (device.deviceTemplate.abstractDevice.name == 'Cup') {
        //     if (pick) {
        //         fixX = 0;
        //         fixY = 0;
        //     }
        // }
        return [0, 0];
    }

}
