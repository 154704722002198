import {AfterViewInit, Component, forwardRef, Inject, OnDestroy} from '@angular/core';
import {VisionService} from '../../../services/vision.service';
import {VisionComponent} from '../vision.component';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'vision-log-component',
    templateUrl: 'vision-log.component.html'
})
export class VisionLogComponent implements AfterViewInit, OnDestroy {

    log: string;
    tensorFlowLog: string = '';
    tensorFlowProgress = 0;
    tensorFlowIntervalId: NodeJS.Timer;

    constructor(private visionService: VisionService,
                @Inject(forwardRef(() => VisionComponent)) private parent: VisionComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
    }

    onShow() {
        this.getTensorFlowData();
    }

    onTensorFlowTabSelected() {

        clearInterval(this.tensorFlowIntervalId);
        this.tensorFlowIntervalId = setInterval(() => {
            this.getTensorFlowData();
        }, 5000);
        this.getTensorFlowData();
    }

    getTensorFlowData() {
        /*this.visionService.getTensorFlowTrainingLog().subscribe(log => {
            this.tensorFlowLog = log.replace(/\n/g, '<br />');
        });
        this.visionService.getTensorFlowTrainingProgress().subscribe(progress => {
            this.tensorFlowProgress = progress;
            if (this.tensorFlowProgress == 100) clearInterval(this.tensorFlowIntervalId);
        });*/
    }

    ngOnDestroy() {
        clearInterval(this.tensorFlowIntervalId);
    }

    onVisionTabSelected() {
        clearInterval(this.tensorFlowIntervalId);
    }

    startTensorFlowTraining() {
        /*this.visionService.startTensorFlowTraining().subscribe(success => {
            if (success) {
                this.tensorFlowProgress = 0;
                this.onTensorFlowTabSelected();
            }
        });*/
    }

    stopTensorFlowTraining() {
        /*this.visionService.stopTensorFlowTraining().subscribe(success => {
            if (success) {
                this.tensorFlowProgress = 0;
                this.tensorFlowLog = '';
            }
        });*/
    }


}
