import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {Router} from '@angular/router';
import {MacroStepService} from '../../services/macro-step.service';
import {MacroStepBean} from '../../model/model';
import {Helper} from '../../common/helper';

@Component({
    templateUrl: 'macro-steps.component.html',
})

export class MacroStepsComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;

    columns: Column[] = [
        new Column('id', 'Id'),
        new Column('description', 'Description'),
        new Column('template', 'Type'),
    ];

    constructor(private macroStepService: MacroStepService,
                private router: Router,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadMacroSteps();
    }

    loadMacroSteps() {

        this.macroStepService.getMacroSteps().subscribe(macroSteps => {
            const list = [];
            for (const step of macroSteps.list) {
                list.push(Helper.copyObject(step));
            }
            this.table.setData(list, this.columns);
        });
    }

    onView(items: MacroStepBean[]) {
        if (items && items.length > 0) {
            this.router.navigate(['/macro-step-edit/'], {queryParams: {id: items[0].id}});
        }
    }

    onAdd() {
        this.router.navigate(['/macro-step-edit/']);
    }

    onRemove(items) {
        for (const macroStep of items) {

            this.macroStepService.deleteMacroStep(macroStep).subscribe(response => {
                if (response.success) {
                    this.loadMacroSteps();

                } else {
                    this.app.showError('Can\'t delete Macro Step');
                }
            }, error => {
                this.app.showError('Can\'t delete Macro Step');
            });
        }
    }

}

