import {Injectable} from '@angular/core';
import {UserInfoBean} from '../model/model';
import {HttpHeaders} from '@angular/common/http';
import {StorageAccessToken} from '../model/custom-model';

@Injectable()
export class DataService {

    static userInfo: UserInfoBean;

    private KEY_USER_INFO = 'KEY_USER_INFO';
    private KEY_USER_INFO_SAVE_TIME = 'KEY_USER_INFO_SAVE_TIME';
    private KEY_STORAGE_ACCESS_TOKEN = 'KEY_STORAGE_ACCESS_TOKEN';

    private storageAccessToken: StorageAccessToken;

    requestHeaders: HttpHeaders;
    private userId: number;

    constructor() {
        this.updateRequestHeaders();
    }

    setStorageAccessToken(storageAccessToken: StorageAccessToken) {
        this.storageAccessToken = storageAccessToken;
        window.localStorage.setItem(this.KEY_STORAGE_ACCESS_TOKEN, JSON.stringify(storageAccessToken));
    }

    getStorageAccessToken(): StorageAccessToken {
        if (this.storageAccessToken == null) {
            this.storageAccessToken = JSON.parse(window.localStorage.getItem(this.KEY_STORAGE_ACCESS_TOKEN));
        }
        return this.storageAccessToken;
    }

    setUserInfo(userInfo: UserInfoBean) {
        DataService.userInfo = userInfo;
        window.localStorage.setItem(this.KEY_USER_INFO, JSON.stringify(userInfo));
        window.localStorage.setItem(this.KEY_USER_INFO_SAVE_TIME, '' + (new Date().getTime()));
        this.updateRequestHeaders();
    }

    getSaveUserInfoTime(): number {
        const time = window.localStorage.getItem(this.KEY_USER_INFO_SAVE_TIME);
        if (time) {
            return +time;
        }
        return 0;
    }

    getUserInfo(): UserInfoBean {
        if (DataService.userInfo == null) {
            const userInfo = window.localStorage.getItem(this.KEY_USER_INFO);
            if (userInfo) {
                try {
                    DataService.userInfo = JSON.parse(userInfo);
                } catch (e) {
                    console.log(e);
                }
            }
        }
        return DataService.userInfo;
    }

    clear() {
        DataService.userInfo = null;
        this.storageAccessToken = null;
        window.localStorage.removeItem(this.KEY_USER_INFO);
        window.localStorage.removeItem(this.KEY_STORAGE_ACCESS_TOKEN);
        this.updateRequestHeaders();
    }

    getCompanyId(): number {
        let companyId = 5649391675244544;
        if (this.getUserInfo() != null) {
            if (this.getUserInfo().company != null) {
                companyId = this.getUserInfo().company.id;
            }
        }
        return companyId;
    }

    getToken(): string {
        let token: string = '';
        if (this.getUserInfo() != null) {
            token = this.getUserInfo().token;
        }
        return token;
    }

    updateRequestHeaders() {
        this.requestHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            CompanyID: this.getCompanyId().toString(),
            Authorization: this.getToken()
        });
    }

    octet() {
        return new HttpHeaders({
            'Content-Type': 'application/octet-stream',
            CompanyID: this.getCompanyId().toString(),
            Authorization: this.getToken()
        });
    }

    setUserId(userId: number) {
        this.userId = userId;
    }

    getUserId() {
        return this.userId;
    }

}
