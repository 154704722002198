import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ActionButton, TableComponent} from '../../../components/table.component';
import {DetectionResult, VisionService} from '../../../services/vision.service';
import {Column} from '../../../common/column';
import {AppComponent} from '../../../app.component';
import {TagSelectComponent} from './tag.select.component';
import {FileUploaderComponent} from '../../../file-uploader/file-uploader.component';
import {VisionComponent} from '../vision.component';
import {Helper} from '../../../common/helper';
import {ActivatedRoute} from '@angular/router';
import {KioskBean} from '../../../model/model';
import {Location} from '@angular/common';
import {KioskService} from '../../../services/kiosk.service';
import {OperatorsService} from '../../../services/operators.service';

@Component({
    selector: 'vision-detect-component',
    templateUrl: 'vision-detect.component.html'
})
export class VisionDetectComponent implements AfterViewInit {

    @ViewChild('resultsTable', {static: false}) resultsTable: TableComponent;
    @ViewChild('detectImageAdd', {static: false}) detectImageAdd: FileUploaderComponent;
    @ViewChild(TagSelectComponent, {static: false}) tagSelectComponent: TagSelectComponent;

    selectedDeviceName: string;
    allDeviceName: string = 'All';

    // operators: OperatorBean[] = [];
    // allOperators: OperatorBean = {} as OperatorBean;
    // selectedOperator: OperatorBean = {} as OperatorBean;
    // selectedOperatorId: number;

    kiosks: KioskBean[] = [];
    allKiosks: KioskBean = {} as KioskBean;
    selectedKiosk: KioskBean = {} as KioskBean;
    selectedKioskId: number;
    compareBeans = Helper.compareBeans;

    fields: Column[] = [
        new Column('device_name', 'Device'),
        new Column('date', 'Date').date(),
        new Column('source_image', 'Source Image').thumbnail(50, false, false),
        new Column('result_image', 'Result Image').thumbnail(50, false, false),
        new Column('result', 'Result'),
    ];
    resultsButtons: ActionButton[] = [
        new ActionButton('Add to Data', 'addToData', 'ti-plus').click(this, this.addToData),
    ];

    tags: string[];
    deviceNames: string[];
    detectionResults: DetectionResult[];
    filter: string;

    constructor(private visionService: VisionService,
                private operatorsService: OperatorsService,
                private kioskService: KioskService,
                private route: ActivatedRoute,
                private location: Location,
                @Inject(forwardRef(() => VisionComponent)) private parent: VisionComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.filter = params['filter'] ? params['filter'] : 0;
        });


    }

    onShow() {

        // this.allOperators.id = 0;
        this.route.queryParams.subscribe(params => {
            // this.selectedOperatorId = params['location'] != null ? params['location'] : 0;
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;
            // this.loadLocations();
            this.loadKiosks();
        });

    }

    onDetectTagChange(value) {
        this.selectedDeviceName = value;
        this.resultsTable.setData([], this.fields);
        this.getDetectionResults();
    }

    setTags(tags: string[]) {
        this.tags = tags;
    }

    setDeviceNames(names: string[]) {
        this.deviceNames = names;
        if (this.selectedDeviceName) {
            for (const name of this.deviceNames) {
                if (name == this.selectedDeviceName) {
                    this.selectedDeviceName = name;
                    break;
                }
            }
        }
        if (!this.selectedDeviceName) {
            this.selectedDeviceName = this.allDeviceName;
        }
        this.onDetectTagChange(this.selectedDeviceName);

        setTimeout(() => {
            $('#select_detect_tag').selectpicker('destroy').selectpicker();
        }, 1);
    }

    deleteDetectionResults() {

        this.visionService.deleteDetectionResults(this.selectedDeviceName).subscribe(response => {
            if (response.success) {
                this.getDetectionResults();
                this.app.showNotification('Deleted');

            } else {
                this.app.showError('Can\'t delete results');
            }
        }, error => {
            this.app.showError('Can\'t delete results');
        });
    }

    getDetectionResults() {
        console.log('getdtet');
        let name = this.selectedDeviceName;
        if (name == this.allDeviceName) {
            name = null;
        }
        this.visionService.getDetectionResults(name, this.selectedKiosk.id).subscribe(results => {

            this.detectionResults = [];
            for (const v in results.value) {
                this.detectionResults.push(Helper.copyObject(results.value[v]));
            }

            this.detectionResults.sort((a: DetectionResult, b: DetectionResult) => {
                return b.date - a.date;
            });

            this.resultsTable.setData(this.detectionResults, this.fields);

        }, error => {
            this.app.showError('Can\'t get results');
        });
    }

    onRemoveResults(results: DetectionResult[]) {

        for (const result of results) {
            this.visionService.deleteDetectionResult(result).subscribe(response => {
                if (response.success) {
                    this.parent.loadVision(false);
                    this.app.showNotification('Removed');

                } else {
                    this.app.showError('Can\'t remove image');
                }
            }, error => {
                this.app.showError('Can\'t remove image');
            });
        }
    }

    addToData(detectionResult: DetectionResult) {
        this.tagSelectComponent.open(this.tags, detectionResult);
    }

    onOperatorChange() {
        this.loadKiosks();
        this.updateLocation();
    }

    onKioskChange() {
        this.getDetectionResults();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/vision';
        // if (this.selectedOperator != this.allOperators) {
        //     url += ('?location=' + this.selectedOperator.id);
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id);
        }
        // }

        this.location.replaceState(url);
    }

    // public loadLocations() {
    //     this.operatorsService.getOperators().subscribe(operators => {
    //
    //
    //         this.operators = [];
    //         for (const operator of operators) {
    //             if (operator.status == 'ACTIVE') this.operators.push(operator);
    //         }
    //
    //         if (!this.selectedOperatorId) this.selectedOperator = this.allOperators;
    //         for (const location of operators) {
    //             if (location.id == this.selectedOperatorId)
    //                 this.selectedOperator = location;
    //         }
    //
    //         setTimeout(() => {
    //             $('#select_location').selectpicker('destroy').selectpicker();
    //         }, 1);
    //
    //         this.onOperatorChange();
    //     });
    // }

    loadKiosks() {
        // if (!this.selectedOperator.id) {
        this.kioskService.getAllKiosks().subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
        // } else {
        //     this.kioskService.getKiosks(this.selectedOperator.id).subscribe(kiosks => {
        //         this.setKiosks(kiosks.list);
        //     })
        // }
    }


    setKiosks(kiosks: KioskBean[]) {

        this.kiosks = [];
        for (const kiosk of kiosks) {
            if (kiosk.state != 'DECOMMISSIONED') {
                this.kiosks.push(kiosk);
            }
        }

        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        setTimeout(() => {
            $('#select_kiosk').selectpicker('destroy').selectpicker();
        }, 1);

        this.getDetectionResults();
    }

}
