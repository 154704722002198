import {Route} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LoginLayoutComponent} from '../layouts/login-layout.component';
import {ForgotPasswordComponent} from './login/forgot-password.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {ConfirmationComponent} from './login/confirmation.component';

export const UNAUTHORIZED_MODULE_ROUTES: Route[] = [
    {
        path: '', component: LoginLayoutComponent,
        children: [
            {path: 'login', component: LoginComponent},
            {path: 'confirmation', component: ConfirmationComponent},
            {path: 'password-recovery/:key', component: PasswordResetComponent},
            {path: '', redirectTo: 'login', pathMatch: 'full'}
        ]
    },
];

export const UNAUTHORIZED_MODULE_COMPONENTS = [
    LoginLayoutComponent,
    LoginComponent,
    ConfirmationComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
];
