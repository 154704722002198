import {publishReplay, refCount} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {DeviceTemplateBean, PageBean, ResponseBean} from '../model/model';

@Injectable()
export class DeviceTemplateService extends BaseRequestService {

    private deviceTemplates: Observable<any>;

    getDeviceTemplates(limit?: number, offset?: number): Observable<PageBean<DeviceTemplateBean>> {

        if (!this.deviceTemplates) {
            this.deviceTemplates = this.get('admin/device/template/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.deviceTemplates;
    }

    addDeviceTemplate(device: DeviceTemplateBean): Observable<ResponseBean> {
        this.deviceTemplates = null;
        return this.post('admin/device/template/add', device);
    }

    updateDeviceTemplate(device: DeviceTemplateBean): Observable<ResponseBean> {
        this.deviceTemplates = null;
        return this.put('admin/device/template/update', device);
    }

    deleteDeviceTemplate(device: DeviceTemplateBean): Observable<ResponseBean> {
        this.deviceTemplates = null;
        return this.del('admin/device/template/' + device.id + '/delete');
    }

    clear() {
        this.deviceTemplates = null;
    }
}
