import {DeviceModelBean} from '../model/model';
import {DeviceView} from './device.view';

export class BlenderView extends DeviceView {

    constructor(device: DeviceModelBean, place: [number, number], canvas: fabric.Canvas, callback?: (objects: fabric.Object[]) => any) {

        super();
        fabric.Image.fromURL(device.deviceTemplate.image1, img => {

            img.set({
                hasControls: false,
                evented: false,
                originY: 'center',
                originX: 'center',
                left: place[0],
                top: place[1],
            });

            img.setFlipX(device.rotation < 0);

            callback.call(this, [img]);
        });
    }
}
