import {publishReplay, refCount} from 'rxjs/operators';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {IngredientBean, PageBean, ResponseBean} from '../model/model';

@Injectable()
export class IngredientService extends BaseRequestService {

    private ingredients: Observable<any>;

    getIngredients(limit?: number, offset?: number): Observable<PageBean<IngredientBean>> {
        if (!this.ingredients) {
            this.ingredients = this.get('admin/ingredient/list', BaseRequestService.buildOffsetParams(limit, offset)).pipe(
                publishReplay(1),
                refCount());
        }
        return this.ingredients;
    }

    addIngredient(ingredient: IngredientBean): Observable<ResponseBean> {
        this.ingredients = null;
        return this.post('admin/ingredient/add', ingredient);
    }

    updateIngredient(ingredient: IngredientBean): Observable<ResponseBean> {
        this.ingredients = null;
        return this.put('admin/ingredient/' + ingredient.id + '/update', ingredient);
    }

    deleteIngredient(ingredient: IngredientBean): Observable<ResponseBean> {
        this.ingredients = null;
        return this.del('admin/ingredient/' + ingredient.id + '/delete');
    }

    clear() {
        this.ingredients = null;
    }
}
