import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {RecipesListsComponent} from './recipes-lists.component';
import {RecipeListService} from '../../services/recipe-list.service';
import {RecipeService} from '../../services/recipe.service';
import {RecipeBean, RecipeListBean} from '../../model/model';


@Component({
    selector: 'recipes-lists-edit-component',
    templateUrl: 'recipes-lists.edit.component.html'
})

export class RecipesListsEditComponent {

    recipesList: RecipeListBean = {} as RecipeListBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('name', 'Name').require(),
        new Field('description', 'Description', FieldType.TEXT_AREA),
    ];
    recipes: RecipeBean[];
    selectedRecipes: Array<RecipeBean> = [];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private recipesListsService: RecipeListService,
                private recipeService: RecipeService,
                @Inject(forwardRef(() => RecipesListsComponent)) private parent: RecipesListsComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(recipesList: RecipeListBean, title: string) {

        this.recipesList = (recipesList ? Helper.copyObject(recipesList) : {} as RecipeListBean);
        if (this.recipesList.recipeIds == null) {
            this.recipesList.recipeIds = [];
        }
        this.base.show(title);
        this.load();
    }


    load() {
        this.recipeService.getAllRecipes().subscribe(recipes => {
            this.recipes = Helper.copyObject(recipes.list);
            this.recipes.sort((r1, r2) => {
                return r2.categoryName.localeCompare(r1.categoryName);
            });

            this.selectedRecipes = [];
            for (const id of this.recipesList.recipeIds) {
                const recipe = this.getRecipe(id);
                if (recipe) {
                    this.recipes.splice(this.recipes.indexOf(recipe), 1);
                    this.selectedRecipes.push(recipe);
                }
            }
        });
    }

    getRecipe(id: number) {
        if (!this.recipes) {
            return null;
        }

        for (const recipe of this.recipes) {
            if (recipe.id == id) {
                return recipe;
            }
        }
        return null;
    }

    save() {

        this.recipesList.recipeIds = [];
        for (const recipe of this.selectedRecipes) {
            this.recipesList.recipeIds.push(recipe.id);
        }

        if (this.recipesList.id == null) {

            this.recipesListsService.addRecipeList(this.recipesList).subscribe(response => {

                if (response.success) {
                    this.parent.loadRecipesLists();
                } else {
                    this.app.showError('Can\'t create list');
                }
            }, error => {
                this.app.showError('Can\'t create list');
            });

        } else {

            this.recipesListsService.updateRecipeList(this.recipesList).subscribe(response => {

                if (response.success) {
                    this.parent.loadRecipesLists();
                } else {
                    this.app.showError('Can\'t update list');
                }
            }, error => {
                this.app.showError('Can\'t update list');
            });
        }
    }


}

