import {AfterViewInit, Component} from '@angular/core';

@Component({
    templateUrl: 'login-layout.component.html',
})
export class LoginLayoutComponent implements AfterViewInit {

    currentDate: Date;

    constructor() {

        this.currentDate = new Date();
    }

    ngAfterViewInit(): void {
    }
}
