import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {KioskModelDetailsComponent} from './kiosk-model-details.component';
import {DeviceModelBean, DeviceTemplateBean} from '../../model/model';

@Component({
    selector: 'device-model-edit-component',
    templateUrl: 'device-model.edit.component.html'
})

export class DeviceModelEditComponent {

    deviceModel: DeviceModelBean = {} as DeviceModelBean;

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('deviceName', 'Device Name').require(),
        new Field('placeName', 'Place Name'),
        new Field('scale', 'Scale', FieldType.NUMBER),
        new Field('rotation', 'Rotation', FieldType.NUMBER),
        new Field('x', 'x', FieldType.NUMBER),
        new Field('y', 'y', FieldType.NUMBER),
    ];
    parent: KioskModelDetailsComponent;
    origDeviceModel: DeviceModelBean;
    availableDevices: DeviceTemplateBean[];
    compareBeans = Helper.compareBeans;
    recipeMakerQuestions;

    constructor(@Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(parent: KioskModelDetailsComponent, availableDevices: DeviceTemplateBean[], deviceModel: DeviceModelBean, title: string) {
        this.origDeviceModel = deviceModel;
        this.availableDevices = availableDevices;

        this.parent = parent;
        this.deviceModel = (deviceModel ? Helper.copyObject(deviceModel) : {} as DeviceModelBean);
        this.base.show(title);
    }

    save() {
        this.parent.addDevice(this.origDeviceModel, this.deviceModel);
    }

    onFileSelected($event): void {
        this.readFile($event.target);
    }

    readFile(inputValue: any): void {
        const file: File = inputValue.files[0];
        const myReader: FileReader = new FileReader();

        myReader.onloadend = () => {
            this.deviceModel.calibrationData = myReader.result as string;
        };

        myReader.readAsText(file);
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }
}

