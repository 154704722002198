import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EventLogBean, PageBean} from '../model/model';

@Injectable()
export class EventLogService extends BaseRequestService {

    getLogs(limit?: number, offset?: number, query?: string): Observable<PageBean<EventLogBean>> {
        const p = BaseRequestService.buildOffsetParams(limit, offset);
        if (query) {
            p['query'] = query;
        }
        return this.get('admin/event_log', p);
    }
}
