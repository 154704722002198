import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export interface LoaderState {
    show: boolean;
}

@Injectable()
export class ProgressLoaderService {
    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    constructor() {
    }

    start() {
        this.loaderSubject.next({show: true} as LoaderState);
    }

    done() {
        this.loaderSubject.next({show: false} as LoaderState);
    }
}
