import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {FieldsListComponent} from '../../../components/fields-list.component';
import {Field} from '../../../common/field';
import {KioskModelQuestionsComponent} from './kiosk-model-questions.component';
import {AppComponent} from '../../../app.component';
import {Helper} from '../../../common/helper';
import {StringBean} from '../../../model/custom-model';

@Component({
    selector: 'kiosk-model-questions-edit-component',
    templateUrl: 'kiosk-model-questions.edit.component.html'
})

export class KioskModelQuestionsEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    value: StringBean = {} as StringBean;

    fields: Field[] = [
        new Field('value', 'Value').require(),
    ];
    initValue: StringBean;

    constructor(@Inject(forwardRef(() => KioskModelQuestionsComponent)) private parent: KioskModelQuestionsComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    open(value: StringBean, title: string) {
        this.base.show(title);
        this.value = value ? Helper.copyObject(value) : {} as StringBean;
        this.initValue = value ? Helper.copyObject(value) : {} as StringBean;
    }

    save() {
        this.parent.addValue(this.initValue, this.value);
    }

}


