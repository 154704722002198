import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CouponCodeBean, KioskBean} from '../../../model/model';
import {Helper} from '../../../common/helper';
import {TableComponent} from '../../../components/table.component';
import {Column} from '../../../common/column';
import {KioskCouponsEditComponent} from './kiosk-coupons.edit.component';

@Component({
    selector: 'kiosk-coupons-component',
    templateUrl: 'kiosk-coupons.component.html'
})
export class KioskCouponsComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    compareBeans = Helper.compareBeans;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(KioskCouponsEditComponent, {static: false}) modal: KioskCouponsEditComponent;


    fields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('discount', 'Discount (0,01-1,00)'),
        new Column('oneTimePerUser', 'One Time Per User').checkbox(),
        new Column('startDate', 'Start Date').shortDate(),
        new Column('endDate', 'End Date').shortDate(),
        new Column('', 'Days of Week').data(this, this.getDays),
    ];

    days = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat'
    ];

    constructor() {
    }

    ngAfterViewInit(): void {
    }

    getDays(coupon: CouponCodeBean): string {
        let result: string = '';
        for (let i = 0; i < 7; i++) {
            if (coupon.workingHours[i] && coupon.workingHours[i].hourTo >
                coupon.workingHours[i].hourFrom) {
                result += (this.days[i] + ', ');
            }
        }
        if (result.length > 2) {
            result = result.slice(0, -2);
        }
        return result;
    }

    onShow(kiosk: KioskBean) {
        this.kiosk = kiosk;
        this.updateTable();
    }

    onEdit(items: CouponCodeBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit Coupon');
        }
    }

    onRemove(items) {
        for (const step of items) {
            const index = this.kiosk.coupons.indexOf(step);
            this.kiosk.coupons.splice(index, 1);
        }
        this.updateTable();
    }

    onAdd() {
        this.modal.open(null, 'Add Coupon');
    }

    addCoupon(coupon: CouponCodeBean) {

        if (coupon.id == 0 || coupon.id == null) {
            this.kiosk.coupons.push(coupon);

        } else {

            let ind = -1;
            let i = 0;
            for (const cp of this.kiosk.coupons) {
                if (cp.id == coupon.id) {
                    ind = i;
                    break;
                }
                i++;
            }

            console.log(ind);

            if (ind >= 0) {
                this.kiosk.coupons.splice(ind, 1);
                this.kiosk.coupons.splice(ind, 0, coupon);
            }
        }

        this.updateTable();
    }

    updateTable() {
        if (this.table) {
            this.table.setData(this.kiosk.coupons, this.fields);
        }
    }
}
