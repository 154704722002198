/* tslint:disable */
import {DeviceModelBean, DeviceOperationType, DeviceState, KioskState, OrderSize, OrderState, StepSnapshotBean, StepState} from './model';
import {DeviceView} from '../presentation/device.view';

export interface DeviceModelViewBean extends DeviceModelBean {
    views: fabric.Object[];
    presentation: DeviceView;
}

export class DevicesSnapshot {
    value: DevicesSnapshotValue;
}

export class DevicesSnapshotValue {
    devices: DeviceSnapshot[];
    kiosk_state: KioskState;
}

export class IngredientSnapshot {
    current_amount: number;
    max_amount: number;
    name: string;
}

export class DeviceSnapshot {
    error_code: number;
    error_msg: string;
    id: number;
    name: string;
    state: DeviceState;
    locked: boolean;
    locked_order_id: number;
    state_change_date: number;
    ingredients: IngredientSnapshot[];
    abstract_name: string;
    invalid: boolean;
    disabled: boolean;
    robot_safety_mode: string;
    operation_type: DeviceOperationType;
}

export class OrderSnapshot {
    complete_pct: number;
    customer_name: string;
    id: number;
    price: number;
    ready_pct: number;
    recipe: string;
    size: OrderSize;
    start_date: number;
    state: OrderState;
    state_change_date: number;
    delivery_time: number;
    wait_time: number;
    jar_name: string;
    jar_place: string;
}

export class OrdersSnapshotValue {
    value: OrderSnapshot[];
}

export class KioskStateValue {
    value: KioskState;
}

// export class StepSnapshotValue {
//     value: Snapshot;
// }

export class DiagnosticsState {
    state: string;
}

export class KioskNOC {
    devices_snapshot: DevicesSnapshot;
    orders_snapshot: OrdersSnapshotValue;
    step_snapshot: StepSnapshotBean;
    diagnostics: DiagnosticsState;
    order_steps_noc: OrderStepsNocSnapshot;
    kiosk_state: KioskStateValue;
}

export class OrderStepsNocSnapshot {
    value: { [key: number]: OrderStepsNoc };
}

export class OrderStepsNoc {
    recipe: string;
    user: string;
    date: number;
    steps: StepNoc[];
}

export class StepNoc {
    device_name: string;
    dispensed_weight: number;
    parameters: any;
    process: string;
    state: StepState;
}

export class StringBean {
    value: string;
}

export class StorageAccessToken {
    accessToken: string;
    expirationTime: number;
}

export class WeeklyAnalyticsBean {
    ordersTotal = 0;
    ordersKiosk = 0;
    ordersMealPlan = 0;
    ordersIOS = 0;
    ordersAndroid = 0;
    ordersReordered = 0;
    ordersCancelled = 0;
    errorsUser = 0;
    ts = 0;
    errorsComputed = 0;
    systemErrors = 0;
    maintenanceUserCritical = 0;
    maintenanceUserScheduled = 0;
}
