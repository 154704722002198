import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../../common/field';
import {RecipeListService} from '../../../services/recipe-list.service';
import {KioskBean, KioskModelBean, RecipeListBean} from '../../../model/model';
import {Observable} from 'rxjs';
import {AppComponent} from '../../../app.component';
import {HttpClient} from '@angular/common/http';
import {FieldsListComponent} from '../../../components/fields-list.component';
import {AppSettings} from '../../../app.settings';

@Component({
    selector: 'kiosk-description-component',
    templateUrl: 'kiosk-description.component.html'
})
export class KioskDescriptionComponent implements AfterViewInit {

    kiosk: KioskBean = {} as KioskBean;
    models: KioskModelBean[];
    @ViewChild('kioskFieldsListComponent', {static: false}) fieldsListComponent: FieldsListComponent;

    fields = [
        new Field('name', 'Name').require(),
        new Field('state', 'State', FieldType.SELECT).setOptions(['OUT_OF_SERVICE', 'WAITING', 'OPERATIONAL', 'MAINTENANCE', 'PROVISIONING', 'DECOMMISSIONED']),
        new Field('shortName', 'Short Name'),

        new Field('addressLine1', 'Address Line #1').require(),
        new Field('addressLine2', 'Address Line #2'),
        new Field('country', 'Country').require(),
        new Field('countryCode', 'Country Code'),
        new Field('city', 'City').require(),
        // new Field('county', 'County'),
        // new Field('zipCode', 'Zip Code'),
        // new Field('phoneNumber', 'Phone Number', FieldType.TEL),

        new Field('contactName', 'Contact Name'),
        new Field('contactEmail', 'Contact Email'),

        new Field('contactPhone', 'Contact Phone', FieldType.TEL),
        new Field('textPhone', 'Text Phone', FieldType.TEL),

        new Field('usual', 'Usual Recipes', FieldType.CHECKBOX),
        new Field('popular', 'Popular Recipes', FieldType.CHECKBOX),
        new Field('promo', 'Promo', FieldType.CHECKBOX),
        new Field('videoStreamId', 'Video Stream ID #1'),
        new Field('videoStreamId2', 'Video Stream ID #2'),
        new Field('tax', 'Tax', FieldType.NUMBER),

        new Field('squareLocationId', 'Square Location Id'),
        new Field('latitude', 'Latitude', FieldType.NUMBER).require(),
        new Field('longitude', 'Longitude', FieldType.NUMBER).require(),

        new Field('errorMsgKioskPaused', 'Paused Kiosk Error'),
        new Field('errorMsgWorkingHours', 'Working Hours Error'),
        new Field('msgJumbotronIdle', 'Jumbotron Idle Message', FieldType.TEXT_AREA),

        new Field('msgWorkingHours', 'Working Hours'),
        new Field('msgDisclaimers', 'Disclaimers'),
        new Field('msgKioskWelcome', 'Kiosk Welcome'),

        new Field('mealplanEnabled', 'Meal Plan', FieldType.CHECKBOX),
        new Field('production', 'Production', FieldType.CHECKBOX),
        new Field('ingredientRatioAdjustmentLogicOnDevice', 'Ingredient Ratio Adjustment Logic On Device', FieldType.CHECKBOX)
    ];

    fieldsPromo = [
        new Field('promoImageUrl', 'Promo Image', FieldType.FILE),
        new Field('promoDiscountPct', 'Promo Discount %', FieldType.NUMBER),
    ];

    recipesLists: RecipeListBean[];

    constructor(private recipesListsService: RecipeListService,
                private http: HttpClient,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadRecipesLists();
    }

    loadRecipesLists() {
        this.recipesListsService.getAllRecipeLists().subscribe(recipesLists => {
            this.recipesLists = recipesLists.list;
        });
    }

    onShow(kiosk: KioskBean, operatorId: number, models: KioskModelBean[]) {
        this.kiosk = kiosk;
        this.models = models;

        if (kiosk) {
            this.kiosk = kiosk; // new Kiosk().deserialize(kiosk);
        } else {
            this.kiosk = {} as KioskBean;
            this.kiosk.operatorId = operatorId;
        }

        console.log(kiosk.cleaningStepsVariant);
    }

    getLocation() {

        this.findFromAddress()
            .subscribe(response => {
                console.log(response);

                if (response.results.length > 0) {

                    this.kiosk.latitude = response.results[0].geometry.location.lat;
                    this.kiosk.longitude = response.results[0].geometry.location.lng;

                } else {
                    this.app.showError('Can\'t get location');
                }
            });
    }

    findFromAddress(): Observable<any> {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + this.getAddress() +
            '&key=' + AppSettings.getFirebaseConfig().apiKey;
        return this.http.get(url);
    }


    getAddress() {
        return (this.kiosk.addressLine1 ? this.kiosk.addressLine1 : '') + '' +
            (this.kiosk.addressLine2 ? this.kiosk.addressLine2 : '') + '' +
            (this.kiosk.city ? this.kiosk.city : '') + '' +
            (this.kiosk.county ? this.kiosk.county : '') + '' +
            (this.kiosk.country ? this.kiosk.country : '');
    }
}
