import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {TableComponent} from '../../components/table.component';
import {DeviceTemplatesEditComponent} from './device-templates.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {DeviceTemplateService} from '../../services/device-template.service';
import {DeviceService} from '../../services/device.service';
import {AbstractDeviceBean, DeviceTemplateBean} from '../../model/model';

@Component({
    templateUrl: 'device-templates.component.html',
})

export class DeviceTemplatesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(DeviceTemplatesEditComponent, {static: false}) modal: DeviceTemplatesEditComponent;

    fields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
        new Column('abstractDevice.name', 'Abstract Device'),
        new Column('image1', 'Image 1').thumbnail(),
        // new Column('image2', 'Image 2').thumbnail(),
        new Column('x0', 'Center X'),
        new Column('y0', 'Center Y'),
        new Column('a', 'Length'),
        new Column('b', 'Width'),
        new Column('c', 'Height'),
    ];
    devices: AbstractDeviceBean[];

    constructor(private deviceTemplateService: DeviceTemplateService,
                private deviceService: DeviceService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadDevices();
    }

    loadDevices() {
        this.deviceService.getDevices().subscribe(devices => {
            this.devices = devices.list;
            this.loadDeviceTemplates();
        });
    }

    loadDeviceTemplates() {
        this.deviceTemplateService.getDeviceTemplates().subscribe(deviceTemplates => {
            this.table.setData(deviceTemplates.list, this.fields);
        });
    }

    onEdit(items: DeviceTemplateBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], this.devices, 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const deviceTemplate of items) {

            this.deviceTemplateService.deleteDeviceTemplate(deviceTemplate).subscribe(response => {
                if (response.success) {
                    if (deviceTemplate == items.pop()) {
                        this.loadDeviceTemplates();
                    }

                } else {
                    this.app.showError('Can\'t delete Device Template');
                }
            }, error => {
                this.app.showError('Can\'t delete Device Template');
            });
        }
    }

    onAdd() {
        this.modal.open(null, this.devices, 'New Device Template');
    }
}

