import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {RecipeService} from '../../services/recipe.service';
import {TableComponent} from '../../components/table.component';
import {RecipesEditComponent} from './recipes.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute} from '@angular/router';
import {CategoryService} from '../../services/category.service';
import {Location} from '@angular/common';
import {ProcessEditComponent} from '../../components/process.edit.component';
import {DependsOnEditComponent} from '../../components/depends-on.edit.component';
import {Helper} from '../../common/helper';
import {CategoryBean, RecipeBean, RecipeStepBean} from '../../model/model';
import * as FileSaver from 'file-saver';
import {BaseRequestService} from '../../services/base-request.service';
import {DataService} from '../../services/data.service';

@Component({
    templateUrl: 'recipes.component.html',
})

export class RecipesComponent implements AfterViewInit {

    @ViewChild('recipesEditComponent', {static: false}) recipesEditComponent: RecipesEditComponent;
    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild('processEditComponent', {static: false}) processEditComponent: ProcessEditComponent;
    @ViewChild('dependsOnEditComponent', {static: false}) dependsOnEditComponent: DependsOnEditComponent;
    fields: Column[] = [
        new Column('imageUrl', 'Image').thumbnail(75),
        new Column('title', 'Title'),
        new Column('featured', 'Featured').checkbox(),
        new Column('favorites', 'Favorites'),
        new Column('servings', 'Orders'),
        new Column('vegan', 'Vegan').checkbox(),
    ];
    afuConfig = this.createAFUConfig();

    categories: CategoryBean[] = [];
    selectedCategory: CategoryBean;
    steps: RecipeStepBean[];
    selectedCategoryId: number;
    compareBeans = Helper.compareBeans;

    constructor(private recipeService: RecipeService,
                private categoryService: CategoryService,
                private dataService: DataService,
                private route: ActivatedRoute,
                private location: Location,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.route.queryParams.subscribe(params => {
            this.selectedCategoryId = params['category'] != null ? params['category'] : 0;
        });

        this.loadCategories();
    }

    public loadCategories() {
        this.categoryService.getCategories().subscribe(categories => {

            this.categories = categories;
            if (this.selectedCategoryId == 0) {
                this.selectedCategory = categories[0];
            } else {
                for (const category of categories) {
                    if (category.id == this.selectedCategoryId) {
                        this.selectedCategory = category;
                    }
                }
            }

            this.afuConfig = this.createAFUConfig();

            setTimeout(() => {
                $('#selectpicker').selectpicker('destroy').selectpicker();
            }, 1);

            this.loadRecipes();
        });
    }

    loadRecipes() {
        this.recipeService.getRecipes(this.selectedCategory.id).subscribe(recipes => {
            this.table.setData(recipes.list, this.fields);
        });
    }

    onCategoryChange() {
        this.loadRecipes();
        this.location.replaceState('/recipes?category=' + this.selectedCategory.id);
        this.afuConfig = this.createAFUConfig();
    }


    onEdit(items: RecipeBean[]) {
        if (items && items.length > 0) {
            this.recipesEditComponent.open(items[0], this.selectedCategory.id, 'Edit ' + items[0].title);
        }
    }


    onExport(items: RecipeBean[]) {
        if (items && items.length > 0) {
            this.recipeService.exportRecipes(items).subscribe(response => {
                FileSaver.saveAs(response, 'Recipes' + '.json');
            });
        }
    }

    onRemove(items) {

        for (const recipe of items) {

            this.recipeService.deleteRecipe(recipe).subscribe(response => {

                if (response.success) {
                    if (recipe == items[0]) {
                        this.loadRecipes();
                    }

                } else {
                    this.app.showError('Can\'t delete recipe: ' + response.errorDescription);
                }
            }, error => {
                this.app.showError('Can\'t delete recipe');
            });
        }
    }
    onAdd() {
        this.recipesEditComponent.open(null, this.selectedCategory.id, 'New Recipe');
    }

    addProcess(ev) {
        this.recipesEditComponent.addProcess(ev, this.steps);
    }

    openCreateStepModal(ev) {
        this.steps = ev.recipeSteps;
        this.processEditComponent.open(ev.step);
    }

    openDependsOnComponent(recipeSteps: RecipeStepBean[], step: RecipeStepBean) {
        this.dependsOnEditComponent.open(recipeSteps, step);
    }

    createAFUConfig() {
        return {
            formatsAllowed: '.json',
            uploadAPI: {
                url: BaseRequestService.apiServerUrl() + 'admin/recipe/import/to/'
                    + (this.selectedCategory != null ? this.selectedCategory.id : 0),
                headers: {
                    'Content-Type': 'text/plain;charset=UTF-8',
                    CompanyID: this.dataService.getCompanyId().toString(),
                    Authorization: this.dataService.getToken()
                }
            },
            replaceTexts: {
                selectFileBtn: 'Select JSON to import recipes',
            }
        };
    }

    onFileUploaded($event) {
        console.log($event);
        this.loadRecipes();
    }
}

