import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PageBean, ResponseBean, RobotCommandBean} from '../model/model';

@Injectable()
export class RobotCommandService extends BaseRequestService {

    getRobotCommands(kioskModelId: number, limit?: number, offset?: number): Observable<PageBean<RobotCommandBean>> {
        return this.get('admin/robot_commands/' + kioskModelId + '/list', BaseRequestService.buildOffsetParams(limit, offset));
    }

    deleteRobotCommand(kioskModelId: number, cmd: RobotCommandBean): Observable<ResponseBean> {
        return this.del('admin/robot_commands/' + kioskModelId + '/' + cmd.id + '/delete');
    }

    updateRobotCommand(kioskModelId: number, cmd: RobotCommandBean): Observable<ResponseBean> {
        return this.put('admin/robot_commands/' + kioskModelId + '/update', cmd);
    }

    addRobotCommand(kioskModelId: number, cmd: RobotCommandBean): Observable<ResponseBean> {
        return this.post('admin/robot_commands/' + kioskModelId + '/add', cmd);
    }
}
