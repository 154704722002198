import {publishReplay, refCount} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseRequestService} from './base-request.service';
import {Observable} from 'rxjs';
import {
    AnalyticsBean,
    BusinessOpsBean,
    ChartOrdersCountByRecipeBean,
    DashboardBean,
    DashboardDynamicsBean,
    OrderErrorsStatsBean,
    OrdersCountBean,
    ResponseItemBean,
    StatusBean
} from '../model/model';

@Injectable()
export class DashboardService extends BaseRequestService {

    private operationsDynamic: Observable<any>;
    private dashboard: Observable<any>[] = [];
    private status: Observable<any>;
    private businessOps: Observable<any>;

    getDashboard(kioskId: number): Observable<ResponseItemBean<DashboardBean>> {
        if (!this.dashboard[kioskId]) {
            const p = {};
            if (kioskId != 0) {
                p['kioskId'] = kioskId;
            }
            this.dashboard[kioskId] = this.get('admin/dashboard', p).pipe(
                publishReplay(1),
                refCount());
        }
        return this.dashboard[kioskId];
    }

    getAnalytics(kioskId: number, startTime: number, endTime: number, period: string): Observable<ResponseItemBean<AnalyticsBean[]>> {
        const p = {};
        if (kioskId != 0) {
            p['kioskId'] = kioskId;
        }
        if (startTime != null) {
            p['startTime'] = startTime;
        }
        if (endTime != null) {
            p['endTime'] = endTime;
        }
        if (period != null) {
            p['period'] = period;
        }

        return this.get('admin/analytics', p);
    }

    getOrders(operatorId?: number, kioskId?: number, categoryId?: number, recipeId?: number): Observable<ResponseItemBean<{
        [key: number]: { [key: number]: OrdersCountBean[] }
    }>> {
        const p = {};
        if (operatorId != null) {
            p['operatorId'] = operatorId;
        }
        if (kioskId != null) {
            p['kioskId'] = kioskId;
        }
        if (categoryId != null) {
            p['categoryId'] = categoryId;
        }
        if (recipeId != null) {
            p['recipeId'] = recipeId;
        }

        return this.get('admin/dashboard/orders', p);
    }

    getRecipes(kioskId?: number, startTime?: number, endTime?: number): Observable<ResponseItemBean<ChartOrdersCountByRecipeBean[]>> {
        const p = {};
        if (kioskId != null) {
            p['kioskId'] = kioskId;
        }
        if (startTime != null) {
            p['startTime'] = startTime;
        }
        if (endTime != null) {
            p['endTime'] = endTime;
        }
        return this.get('admin/dashboard/recipes', p);
    }

    getOperationsDynamic(): Observable<ResponseItemBean<DashboardDynamicsBean[]>> {
        if (!this.operationsDynamic) {
            this.operationsDynamic = this.get('admin/dashboard/operations/dynamic').pipe(
                publishReplay(1),
                refCount());
        }
        return this.operationsDynamic;
    }

    getStatus(production: boolean = false): Observable<ResponseItemBean<StatusBean>> {
        if (!this.status) {
            const p = {};
            p['production'] = production;
            this.status = this.get('admin/dashboard/status', p).pipe(
                publishReplay(1),
                refCount());
        }
        return this.status;
    }

    getBusinessOps(): Observable<ResponseItemBean<BusinessOpsBean>> {
        if (!this.businessOps) {
            this.businessOps = this.get('admin/dashboard/business_ops').pipe(
                publishReplay(1),
                refCount());
        }
        return this.businessOps;
    }

    getErrorStats(kioskId: number): Observable<ResponseItemBean<OrderErrorsStatsBean[]>> {
        return this.get('admin/dashboard/kiosk/' + kioskId + '/orders/error_stats');
    }

    getMaintenanceChartsCSV(kioskId: number, startTime: number, endTime: number): Observable<Blob> {
        const p = {};

        if (kioskId != 0) {
            p['kioskId'] = kioskId;
        }
        if (startTime != null) {
            p['startTime'] = startTime;
        }
        if (endTime != null) {
            p['endTime'] = endTime;
        }

        return this.blob('admin/analytics/maintenance_csv', p);
    }

    clear() {
        this.dashboard = null;
        this.operationsDynamic = null;
    }
}
