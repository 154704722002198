import {AfterViewInit, Component, forwardRef, Inject} from '@angular/core';
import {AppComponent} from '../../app.component';
import {DocsBean} from '../../model/model';
import {ClientService} from '../../services/client.service';

@Component({
    templateUrl: 'documentation.component.html',
})

export class DocumentationComponent implements AfterViewInit {

    docs: DocsBean;

    constructor(private clientService: ClientService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.getDocs();
    }

    getDocs() {
        this.clientService.getDocumentation().subscribe(response => {

            if (response) {
                this.docs = response.value;
                this.docs.introduction = this.docs.introduction.split('\\n').join('\n');
            }
        });
    }

    save() {
        this.clientService.updateDocumentation(this.docs).subscribe(response => {

            if (response.success) {
                this.app.showNotification('Updated');

            } else {
                this.app.showError('Can\'t update documentation');
            }
        }, error => {
            this.app.showError('Can\'t update documentation');
        });

    }
}

