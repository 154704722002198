import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {UNAUTHORIZED_MODULE_COMPONENTS, UNAUTHORIZED_MODULE_ROUTES} from './unauthorized.routes';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';

@NgModule({
    imports: [
        RouterModule.forChild(UNAUTHORIZED_MODULE_ROUTES),
        CommonModule,
        FormsModule,
        SharedModule,
    ],
    declarations: [UNAUTHORIZED_MODULE_COMPONENTS]
})

export class UnauthorizedModule {
}
