import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {Helper} from '../../common/helper';
import {KioskTemplatesComponent} from './kiosk-templates.component';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {KioskTemplateService} from '../../services/kiosk-template.service';
import {DeviceTemplateBean, KioskTemplateBean} from '../../model/model';

@Component({
    selector: 'kiosk-templates-edit-component',
    templateUrl: 'kiosk-templates.edit.component.html'
})

export class KioskTemplatesEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;

    kioskTemplate: KioskTemplateBean = {} as KioskTemplateBean;
    deviceTemplates: DeviceTemplateBean[];
    selectedDeviceTemplateIds = [];

    fields: Field[] = [

        new Field('id', 'Id').disable(),
        new Field('name', 'Name').require(),
        new Field('bgImage', 'Background', FieldType.FILE),
        new Field('angleA', 'angleA', FieldType.NUMBER),
        new Field('angleB', 'angleB', FieldType.NUMBER),
        new Field('x0', 'x0', FieldType.NUMBER),
        new Field('y0', 'y0', FieldType.NUMBER),
        new Field('a', 'A', FieldType.NUMBER),
        new Field('b', 'B', FieldType.NUMBER),
        new Field('c', 'C', FieldType.NUMBER),

    ];

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private kioskTemplateService: KioskTemplateService,
                @Inject(forwardRef(() => KioskTemplatesComponent)) private parent: KioskTemplatesComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(kioskTemplate: KioskTemplateBean, deviceTemplates: DeviceTemplateBean[], title: string) {
        this.deviceTemplates = deviceTemplates;
        this.kioskTemplate = (kioskTemplate ? Helper.copyObject(kioskTemplate) : {} as KioskTemplateBean);
        this.base.show(title);

        this.selectedDeviceTemplateIds = [];
        if (this.kioskTemplate.deviceTemplates) {
            for (const id of this.kioskTemplate.deviceTemplates) {
            this.selectedDeviceTemplateIds.push(id.id);
        }
        }

        setTimeout(() => {
            $('#select_devices').selectpicker('destroy').selectpicker();
        }, 1);
    }

    save() {

        this.kioskTemplate.deviceTemplates = [];
        for (const id of this.selectedDeviceTemplateIds) {
            this.kioskTemplate.deviceTemplates.push(id);
        }

        if (this.kioskTemplate.id == null) {

            this.kioskTemplateService.addKioskTemplate(this.kioskTemplate).subscribe(response => {

                if (response.success) {
                    this.parent.loadKioskTemplates();
                } else {
                    this.app.showError('Can\'t create Kiosk Template');
                }
            }, error => {
                this.app.showError('Can\'t create Kiosk Template');
            });

        } else {

            this.kioskTemplateService.updateKioskTemplate(this.kioskTemplate).subscribe(response => {

                if (response.success) {
                    this.parent.loadKioskTemplates();
                } else {
                    this.app.showError('Can\'t update Kiosk Template');
                }
            }, error => {
                this.app.showError('Can\'t update Kiosk Template');
            });
        }
    }

}


