import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {KioskService} from '../../services/kiosk.service';
import {KioskDevicesComponent} from './devices/kiosk-devices.component';
import {KioskSimulatorComponent} from './simulator/kiosk-simulator.component';
import {TabDirective} from 'ngx-bootstrap/tabs';
import {KioskDiagnosticsComponent} from './diagnostics/kiosk-diagnostics.component';
import {KioskDescriptionComponent} from './description/kiosk-description.component';
import {KioskTemplateService} from '../../services/kiosk-template.service';
import {KioskModelService} from '../../services/kiosk-model.service';
import {KioskDataService} from '../../services/kiosk-data.service';
import {KioskTestsComponent} from './tests/kiosk-tests.component';
import {KioskRobotComponent} from './robot/kiosk-robot.component';
import {DeviceLogsComponent} from './device-logs/device-logs.component';
import {AppComponent} from '../../app.component';
import {KioskActionsComponent} from './actions/kiosk-actions.component';
import {KioskRecipesListsComponent} from './recipes-lists/kiosk-recipes-lists.component';
import {KioskBean, KioskModelBean} from '../../model/model';
import {Helper} from '../../common/helper';
import {KioskRecipesComponent} from './recipes/kiosk-recipes.component';
import {KioskIngredientsComponent} from './ingredients/kiosk-ingredients.component';
import {DashboardLayoutComponent} from '../../layouts/dashboard-layout.component';
import {KioskManagersComponent} from './managers/kiosk-managers.component';
import {KioskHoursComponent} from './hours/kiosk-hours.component';
import {KioskCouponsComponent} from './coupons/kiosk-coupons.component';
import {KioskTestersComponent} from './testers/kiosk-testers.component';
import {KioskLogsComponent} from './kiosk-logs/kiosk-logs.component';
import {KioskMaintenanceOptionsComponent} from './maintenance-options/kiosk-maintenance-options.component';
import {KioskCustomRecipesComponent} from './custom-recipes/kiosk-custom-recipes.component';
import {KioskRewardsComponent} from './rewards/kiosk-rewards.component';

@Component({
    selector: 'kiosk-details-edit-component',
    templateUrl: 'kiosk-details.component.html'
})

export class KioskDetailsComponent implements AfterViewInit {

    kioskId: number;
    operatorId: number;
    kiosk: KioskBean = {} as KioskBean;
    models: KioskModelBean[];
    kioskLoaded: boolean;
    tab: string;

    @ViewChild(KioskDescriptionComponent, {static: false}) kioskDescriptionComponent: KioskDescriptionComponent;
    @ViewChild(KioskDevicesComponent, {static: false}) kioskDevicesComponent: KioskDevicesComponent;
    @ViewChild(KioskSimulatorComponent, {static: false}) kioskSimulatorComponent: KioskSimulatorComponent;
    @ViewChild(KioskDiagnosticsComponent, {static: false}) kioskDiagnosticsComponent: KioskDiagnosticsComponent;
    @ViewChild(KioskTestsComponent, {static: false}) kioskTestsComponent: KioskTestsComponent;
    @ViewChild(DeviceLogsComponent, {static: false}) deviceLogsComponent: DeviceLogsComponent;
    @ViewChild(KioskLogsComponent, {static: false}) kioskLogsComponent: KioskLogsComponent;
    @ViewChild(KioskRobotComponent, {static: false}) kioskRobotComponent: KioskRobotComponent;
    @ViewChild(KioskRecipesListsComponent, {static: false}) kioskRecipesListsComponent: KioskRecipesListsComponent;
    @ViewChild(KioskRecipesComponent, {static: false}) kioskRecipesComponent: KioskRecipesListsComponent;
    @ViewChild(KioskCustomRecipesComponent, {static: false}) kioskCustomRecipesComponent: KioskCustomRecipesComponent;

    @ViewChild(KioskIngredientsComponent, {static: false}) kioskIngredientsComponent: KioskIngredientsComponent;
    @ViewChild(KioskActionsComponent, {static: false}) kioskActionsComponent: KioskActionsComponent;
    @ViewChild(KioskManagersComponent, {static: false}) kioskManagersComponent: KioskManagersComponent;
    @ViewChild(KioskTestersComponent, {static: false}) kioskTestersComponent: KioskTestersComponent;
    @ViewChild(KioskMaintenanceOptionsComponent, {static: false}) kioskMaintenanceOptionsComponent: KioskMaintenanceOptionsComponent;
    @ViewChild(KioskHoursComponent, {static: false}) kioskHoursComponent: KioskHoursComponent;
    @ViewChild(KioskCouponsComponent, {static: false}) kioskCouponsComponent: KioskCouponsComponent;
    @ViewChild(KioskRewardsComponent, {static: false}) kioskRewardsComponent: KioskRewardsComponent;


    constructor(private route: ActivatedRoute,
                private router: Router,
                private kioskDataService: KioskDataService,
                private kioskTemplateService: KioskTemplateService,
                private kioskModelService: KioskModelService,
                private kioskService: KioskService,
                @Inject(forwardRef(() => DashboardLayoutComponent)) private layout: DashboardLayoutComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        this.kioskLoaded = false;
        this.route.queryParams.subscribe(params => {
            this.kioskId = params['kioskId'] ? params['kioskId'] : 0;
            this.operatorId = params['operatorId'] ? params['operatorId'] : 0;
            this.tab = params['tab'] ? params['tab'] : null;

            this.getKiosk();
        });
    }

    loadModels() {
        this.kioskModelService.getKioskModels().subscribe(models => {
                this.models = models.list;
                this.onDescriptionTabSelected(null);
            }
        );
    }

    getKiosk() {
        this.kioskService.getKiosk(this.operatorId, this.kioskId).subscribe(response => {
            this.kiosk = Helper.copyObject(response.value);

            console.log(this.kiosk);
            if (this.tab == 'devices') {
                this.onDevicesTabSelected(null);
            } else {
                this.onDescriptionTabSelected(null);
            }

            if (this.tab == 'ingredients') {
                this.onIngredientsTabSelected(null);
            }

            this.kioskLoaded = true;
            this.loadModels();

            if (this.kiosk) {
                setTimeout(() => {
                    this.layout.navbarComponent.setTitle(this.kiosk.name);
                }, 100);
            }
        });
    }

    onDevicesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskDevicesComponent.onShow(this.kiosk);
        }
    }

    onDiagnosticsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskDiagnosticsComponent.onShow(this.kiosk);
        }
    }

    onSimulatorTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskSimulatorComponent.onShow(this.kiosk);
        }
    }

    onTestsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskTestsComponent.onShow(this.kiosk);
        }
    }

    onLogsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.deviceLogsComponent.onShow(this.kiosk);
        }
    }

    onKioskLogsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskLogsComponent.onShow(this.kiosk);
        }
    }

    onRobotTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRobotComponent.onShow(this.kiosk);
        }
    }

    onDescriptionTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskDescriptionComponent.onShow(this.kiosk, this.operatorId, this.models);
        }
    }

    onRecipesListsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRecipesListsComponent.onShow(this.kiosk);
        }
    }

    onRecipesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRecipesComponent.onShow(this.kiosk);
        }
    }

    onCustomRecipesTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskCustomRecipesComponent.onShow(this.kiosk);
        }
    }

    onIngredientsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskIngredientsComponent.onShow(this.kiosk);
        }
    }

    onMangersTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskManagersComponent.onShow(this.kiosk);
        }
    }

    onTestersTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskTestersComponent.onShow(this.kiosk);
        }
    }

    onHoursTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskHoursComponent.onShow(this.kiosk);
        }
    }

    onCouponsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskCouponsComponent.onShow(this.kiosk);
        }
    }


    onActionsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskActionsComponent.onShow(this.kiosk);
        }
    }

    viewKiosk() {
        this.router.navigate(['/kiosk-view/'], {
            queryParams: {
                kioskId: this.kioskId,
                operatorId: this.operatorId
            }
        });
    }

    save() {


        console.log(this.kiosk.ingredientStates);
        if (!this.kioskDescriptionComponent.fieldsListComponent.valid()) {

            this.app.showError('Please check all required fields');
            return;
        }

        if (this.kiosk.id == null) {

            this.kioskService.addKiosk(this.kiosk).subscribe(response => {
                if (response.success) {
                    this.app.showNotification('Created');

                } else {
                    this.app.showError('Can\'t create kiosk');
                }
            }, error => {
                this.app.showError('Can\'t create kiosk');
            });

        } else {

            this.kioskService.updateKiosk(this.kiosk).subscribe(response => {
                if (response.success) {
                    this.app.showNotification('Updated');

                } else {
                    this.app.showError('Can\'t update kiosk');
                }
            }, error => {
                this.app.showError('Can\'t update kiosk');
            });
        }
    }

    isValid() {
        return true;
    }


    onMaintenanceOptionsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskMaintenanceOptionsComponent.onShow(this.kiosk);
        }
    }

    onRewardsTabSelected(ev) {
        if (!ev || ev instanceof TabDirective) {
            this.kioskRewardsComponent.onShow(this.kiosk);
        }
    }
}
