import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {Field, FieldType} from '../../common/field';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {AppComponent} from '../../app.component';
import {KioskModelDetailsComponent} from './kiosk-model-details.component';
import {DiagnosticsStepService} from '../../services/diagnosics-step.service';
import {ActionButton, TableComponent} from '../../components/table.component';
import {Column} from '../../common/column';
import {DiagnosticsStepSequenceBean, Entry} from '../../model/model';
import {Helper} from '../../common/helper';

@Component({
    selector: 'diagnostic-steps-sequence-edit-component',
    templateUrl: 'diagnostics-steps-sequence.edit.component.html'
})

export class DiagnosticsStepSequenceEditComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    @ViewChild('diagnosticsStepsTable', {static: false}) diagnosticsStepsTable: TableComponent;

    fields: Field[] = [
        new Field('id', 'ID').disable(),
        new Field('description', 'Description').require(),
        new Field('service', 'Service', FieldType.CHECKBOX),
        new Field('kioskSetup', 'Kiosk Setup', FieldType.CHECKBOX),
        new Field('kioskOperation', 'Kiosk Operation', FieldType.CHECKBOX),
        new Field('kioskCleanup', 'Kiosk Cleanup', FieldType.CHECKBOX),
        new Field('parallelExecution', 'Parallel Execution', FieldType.CHECKBOX),
    ];

    diagnosticStepFields: Column[] = [
        new Column('sequence.description', 'Sequence'),
        new Column('step.deviceName', 'Device Name'),
        new Column('step.process', 'Process').data(this, this.getProcessString),
    ];

    priorityButtons: ActionButton[] = [
        new ActionButton('Up', 'up', 'ti-arrow-up').click(this, this.incPriority),
        new ActionButton('Down', 'down', 'ti-arrow-down').click(this, this.decPriority)
    ];

    stepsSequence: DiagnosticsStepSequenceBean = {} as DiagnosticsStepSequenceBean;
    kioskModelId: number;

    isValid() {
        return () => {
            return this.fieldsListComponent && this.fieldsListComponent.valid();
        };
    }

    constructor(private diagnosticsStepService: DiagnosticsStepService,
                @Inject(forwardRef(() => KioskModelDetailsComponent)) private parent: KioskModelDetailsComponent,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    open(kioskModelId: number, stepsSequence: DiagnosticsStepSequenceBean, title: string) {

        this.kioskModelId = kioskModelId;
        this.stepsSequence = (stepsSequence ? Helper.copyObject(stepsSequence) : {} as DiagnosticsStepSequenceBean);
        if (!this.stepsSequence.steps) {
            this.stepsSequence.steps = [];
        }
        this.base.show(title);
        this.updateTable();
    }

    getProcessString(entry: Entry) {
        if (entry.step) {
            return entry.step.process ? entry.step.process.name : '-';
        } else if (entry.sequence) {
            return '-';
        }
        return '-';
    }


    incPriority(step: Entry) {
        const index = this.stepsSequence.steps.indexOf(step, 0);
        if (index > -1) {
            const steps = this.stepsSequence.steps;
            steps.splice(index - 1, 0, steps.splice(index, 1)[0]);
        }

        this.updateTable();
    }

    decPriority(step: Entry) {
        const index = this.stepsSequence.steps.indexOf(step, 0);
        if (index > -1) {
            const steps = this.stepsSequence.steps;
            steps.splice(index + 1, 0, steps.splice(index, 1)[0]);
        }

        this.updateTable();
    }

    save() {

        if (this.stepsSequence.id == null) {

            this.diagnosticsStepService.addStepSequence(this.kioskModelId, this.stepsSequence).subscribe(response => {

                if (response.success) {
                    this.parent.loadDiagnosticsSteps();
                } else {
                    this.app.showError('Can\'t create step');
                }
            }, error => {
                this.app.showError('Can\'t create step');
            });

        } else {

            this.diagnosticsStepService.updateStepSequence(this.kioskModelId, this.stepsSequence).subscribe(response => {

                if (response.success) {
                    this.parent.loadDiagnosticsSteps();
                } else {
                    this.app.showError('Can\'t update step');
                }
            }, error => {
                this.app.showError('Can\'t update step');
            });
        }
    }

    onDiagnosticStepEdit(items: Entry[]) {
        if (items && items.length > 0) {
            this.parent.openCreateDiagnosticsStepModal(items[0]);
        }
    }


    onDiagnosticStepRemove(items) {
        const index = this.stepsSequence.steps.indexOf(items[0]);
        if (index >= 0) {
            this.stepsSequence.steps.splice(index, 1);
        }

        this.updateTable();
    }

    onDiagnosticStepAdd() {
        this.parent.openCreateDiagnosticsStepModal(null);
    }

    addDiagnosticsStep(step: Entry, origStep: Entry) {

        if (origStep == null) {
            this.stepsSequence.steps.push(step);

        } else {

            let index = -1;

            for (let i = 0; i < this.stepsSequence.steps.length; i++) {
                if (this.stepsSequence.steps[i] && this.stepsSequence.steps[i] == origStep) { // fixme ids
                    index = i;
                    break;
                }
            }

            if (index >= 0) {
                this.stepsSequence.steps.splice(index, 1);
                this.stepsSequence.steps.splice(index, 0, step);
            }
        }

        this.updateTable();

    }

    updateTable() {
        this.diagnosticsStepsTable.setData(this.stepsSequence.steps, this.diagnosticStepFields);
    }
}

