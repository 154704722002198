import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {CompanyService} from '../../services/company.service';
import {TableComponent} from '../../components/table.component';
import {CompaniesEditComponent} from './companies.edit.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {Helper} from '../../common/helper';
import {CompanyBean} from '../../model/model';

@Component({
    templateUrl: 'companies.component.html',
})

export class CompaniesComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(CompaniesEditComponent, {static: false}) modal: CompaniesEditComponent;

    fields: Column[] = [
        new Column('id', 'Id'),
        new Column('name', 'Name'),
    ];
    compareBeans = Helper.compareBeans;

    constructor(private  companyService: CompanyService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.loadCompanies();
    }

    loadCompanies() {
        this.companyService.getCompanies().subscribe(companies => {
            this.table.setData(companies, this.fields);
        });
    }

    onEdit(items: CompanyBean[]) {
        if (items && items.length > 0) {
            this.modal.open(items[0], 'Edit ' + items[0].name);
        }
    }

    onRemove(items) {
        for (const company of items) {

            this.companyService.deleteCompany(company).subscribe(response => {
                if (response.success) {
                    if (company == items.pop()) {
                        this.loadCompanies();
                    }

                } else {
                    this.app.showError('Can\'t delete company');
                }
            }, error => {
                this.app.showError('Can\'t delete company');
            });
        }
    }

    onAdd() {
        this.modal.open(null, 'New Company');
    }
}

