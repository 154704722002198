import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SidebarComponent} from './sidebar.component';
import {CompaniesSelectComponent} from '../../dashboard/companies/companies.select.component';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared.module';
import {UserEditComponent} from '../../dashboard/user/user.edit.component';
import {TabsModule} from 'ngx-bootstrap/tabs';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        TabsModule.forRoot(),
    ],
    declarations: [
        SidebarComponent,
        UserEditComponent,
        CompaniesSelectComponent
    ],
    exports: [SidebarComponent]
})

export class SidebarModule {
}
