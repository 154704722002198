import {Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {KioskDevicesComponent} from './kiosk-devices.component';
import {AppComponent} from '../../../app.component';
import {BaseModalComponent} from '../../../components/base.modal.component';
import {KioskDataService} from '../../../services/kiosk-data.service';
import {DeviceTemplateService} from '../../../services/device-template.service';
import {DeviceBean, KioskBean} from '../../../model/model';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'kiosk-devices-view-component',
    templateUrl: 'kiosk-devices.view.component.html'
})

export class KioskDevicesViewComponent {

    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;

    device: DeviceBean = {} as DeviceBean;
    kiosk: KioskBean;
    ip: string;
    log: string;

    constructor(private kioskService: KioskService,
                private kioskDataService: KioskDataService,
                private sanitizer: DomSanitizer,
                private deviceTemplateService: DeviceTemplateService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent,
                @Inject(forwardRef(() => KioskDevicesComponent)) private parent: KioskDevicesComponent) {
    }


    open(kiosk: KioskBean, device: DeviceBean) {

        this.kiosk = kiosk;
        this.device = device;
        this.base.show(device == null ? 'View Device' : 'View ' + device.name);
    }


    reboot() {
        this.call('reboot');
    }

    restart() {
        this.call('restart');
    }

    setRobotIP() {
        this.call('set_ip&param=' + this.ip);
    }

    private call(command: string) {
        this.kioskService.executeShellCommand(this.kiosk, this.device.id, command).subscribe(response => {
            if (response.success) {
                this.log = response.value;
            } else {
                this.log = 'Can\'t execute command';
            }
        }, error => {
            this.log = 'Can\'t execute command';
        });
    }
}
