import {publishReplay, refCount} from 'rxjs/operators';
import {BaseRequestService} from './base-request.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KioskDataBean, ResponseItemBean} from '../model/model';

@Injectable()
export class KioskDataService extends BaseRequestService {

    private kioskData: Observable<any>;

    getData(): Observable<ResponseItemBean<KioskDataBean>> {

        if (!this.kioskData) {
            this.kioskData = this.get('admin/kiosk/data').pipe(
                publishReplay(1),
                refCount());
        }
        return this.kioskData;
    }

    clear() {
        this.kioskData = null;
    }
}
