import {AfterViewInit, Component, forwardRef, Inject, Input, OnDestroy} from '@angular/core';
import {KioskService} from '../../../services/kiosk.service';
import {
    AbstractDeviceBean,
    DeviceBean,
    DiagnosticsStepSequenceBean,
    IngredientBean,
    KioskBean,
    ProcessBean,
    ValueType
} from '../../../model/model';
import {DiagnosticsStepService} from '../../../services/diagnosics-step.service';
import {KioskDataService} from '../../../services/kiosk-data.service';
import {Helper} from '../../../common/helper';
import {AppSettings} from '../../../app.settings';
import {AppComponent} from '../../../app.component';
import {DataService} from '../../../services/data.service';

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

@Component({
    selector: 'kiosk-diagnostics-run-step-component',
    templateUrl: 'kiosk-diagnostics-run-step.component.html'
})
export class KioskDiagnosticsRunStepComponent implements AfterViewInit, OnDestroy {

    kiosk: KioskBean;
    devices: DeviceBean[] = [];
    processes: ProcessBean[] = [];
    selectedDevice: DeviceBean = {} as DeviceBean;
    selectedProcess: ProcessBean = {} as ProcessBean;
    selectedValueType: ValueType[] = [];
    selectedNumber: number[] = [];
    selectedDeviceOrPlace: DeviceBean[] = [];
    abstractDevices: AbstractDeviceBean[];
    log: string = '';
    diagnosticsStepSequence: DiagnosticsStepSequenceBean[];
    selectedDiagnosticsStepSequence: DiagnosticsStepSequenceBean;
    repeatCount = 1;
    compareBeans = Helper.compareBeans;
    selectedIngredient: IngredientBean;

    @Input() diagnostics: boolean;

    constructor(private diagnosticsStepService: DiagnosticsStepService,
                private kioskService: KioskService,
                private dataService: DataService,
                private kioskDataService: KioskDataService,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {
        this.kioskDataService.getData().subscribe(kioskData => {
            this.abstractDevices = kioskData.value.devices;
        });
    }

    ngOnDestroy() {
        try {
            firebase.app().delete();
        } catch (error) {
        }
    }

    onShow(kiosk: KioskBean) {
        console.log(this.kiosk);
        if (this.kiosk) {
            return;
        }

        this.kiosk = kiosk;
        this.loadDiagnosticsSteps();

        $('#select_sequence').selectpicker('destroy').selectpicker();
        $('#select_device').selectpicker('destroy').selectpicker();
        $('#select_process').selectpicker('destroy').selectpicker();
    }

    loadDiagnosticsSteps() {

        this.diagnosticsStepService.getStepsSequences(this.kiosk.kioskModelId).subscribe(steps => {

            this.diagnosticsStepSequence = steps.list;
            this.diagnosticsStepSequence.sort((a, b) => (a.description > b.description ? 1 : ((b.description > a.description) ? -1 : 0)));

            setTimeout(() => {
                $('#select_sequence').prop('disabled', false).selectpicker('destroy').selectpicker();
            }, 1);

            this.loadDevices();
        });

    }

    executeSequence() {
        this.kioskService.sendDiagnosticsStepSequence(this.kiosk, this.selectedDiagnosticsStepSequence, this.repeatCount)
            .subscribe(response => {
            console.log(response);
        });
    }

    haveParameters() {
        if (!this.selectedProcess || !this.selectedProcess.parameters) {
            return false;
        }
        if (this.selectedDevice.dispenserType == 'INGREDIENT') {
            return true;
        }

        for (const param of this.selectedProcess.parameters) {
            if (param.length > 0) {
                return true;
            }
        }
        return false;
    }

    onDeviceChange() {
        if (!this.selectedDevice) {
            return;
        }

        this.processes = [];
        for (const abstractDevice of this.abstractDevices) {
            if (abstractDevice.id == this.selectedDevice.abstractDeviceId) {
                this.processes = abstractDevice.processes;
                break;
            }
        }

        this.selectedProcess = this.processes[0];
        if (this.selectedDevice.dispenserType == 'INGREDIENT') {
            if (this.selectedDevice.canDispenseIngredients) {
                this.selectedIngredient = this.selectedDevice.canDispenseIngredients[0];
            }
        }

        setTimeout(() => {
            $('#select_process').prop('disabled', this.processes.length == 0).selectpicker('destroy').selectpicker();
        }, 1);

    }

    isNumberType(key) {
        return this.selectedValueType[key] == 'NUMBER';
    }

    isDeviceOrPlace(key) {
        return this.selectedValueType[key] == 'DEVICE' || this.selectedValueType[key] == 'DEVICE_PLACE';
    }


    public loadDevices() {

        if (!this.kiosk) {
            return;
        }
        this.kioskService.getDevices(this.kiosk).subscribe(devices => {

            this.devices = [];
            for (const device of devices.list) {
                this.devices.push(Helper.copyObject(device));
            }

            this.selectedDevice = this.devices[0];
            this.onDeviceChange();

            setTimeout(() => {
                $('#select_device').prop('disabled', false).selectpicker('destroy').selectpicker();
            }, 1);

        });
        this.openFirebaseMessaging();
    }

    openFirebaseMessaging() {
        if (!firebase.apps.length) {
            firebase.initializeApp(AppSettings.getFirebaseConfig());
        }

        firebase.auth().signInWithEmailAndPassword(AppSettings.FB_USER, AppSettings.FB_PASSWORD).then(response => {
            const channel = firebase.database()
                .ref('company')
                .child('' + this.dataService.getCompanyId())
                .child('kiosk')
                .child('' + this.kiosk.id)
                .child('diagnostics');

            channel.on('child_changed', data => {
                this.log += (data.val() + '\n');
            });
        });
    }

    clearLog() {
        this.log = '';
    }

    execute() {

        const obj = {};
        obj['device'] = {abstract: false, id: this.selectedDevice.id};
        obj['process'] = this.selectedProcess.name;
        obj['parameters'] = [];


        for (const param of this.selectedProcess.parameters) {
            if (param.length == 0) {
                continue;
            }

            const paramObj = {};
            paramObj['name'] = param;
            paramObj['value_type'] = this.selectedValueType[param];

            switch (this.selectedValueType[param]) {
                case 'NUMBER':
                    paramObj['value'] = this.selectedNumber[param];
                    break;

                case 'DELIVERY_PLACE':
                case 'DISPENSER':
                    break;

                case 'DEVICE':

                    const deviceObj = {};
                    deviceObj['id'] = this.selectedDeviceOrPlace[param].id;
                    deviceObj['name'] = this.selectedDeviceOrPlace[param].name;
                    deviceObj['abstract'] = false;

                    paramObj['value'] = deviceObj;
                    break;


                case 'DEVICE_PLACE':

                    const placeObj = {};
                    placeObj['id'] = this.selectedDeviceOrPlace[param].id;
                    placeObj['name'] = this.selectedDeviceOrPlace[param].name;
                    placeObj['place'] = this.selectedDeviceOrPlace[param].placeName;
                    placeObj['abstract'] = false;

                    paramObj['value'] = placeObj;
                    break;
            }

            obj['parameters'].push(paramObj);
        }


        if (this.selectedIngredient && this.selectedDevice.dispenserType == 'INGREDIENT') {

            const paramObj = {};
            for (const key in this.selectedDevice.canDispenseIngredients) {
                if (this.selectedIngredient.id == this.selectedDevice.canDispenseIngredients[key].id) {
                    paramObj['subDispenserId'] = key;
                }
            }
            paramObj['ingredientName'] = this.selectedIngredient.name;
            paramObj['parameters'] = obj['parameters'];
            obj['parameters'] = [paramObj];
        }


        this.kioskService.sendDiagnosticsStep(this.kiosk, JSON.stringify(obj)).subscribe(response => {
            console.log(response);
        });
    }

}
