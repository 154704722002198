import {Component, ViewChild} from '@angular/core';
import {BaseModalComponent} from '../../components/base.modal.component';
import {FieldsListComponent} from '../../components/fields-list.component';
import {OrderBean} from '../../model/model';
import {OrderService} from '../../services/order.service';
import {RightsGuard} from '../../common/auth.guard';

@Component({
    selector: 'orders-quickview-component',
    templateUrl: 'orders.quickview.component.html'
})

export class OrdersQuickViewComponent {


    @ViewChild(BaseModalComponent, {static: false}) base: BaseModalComponent;
    @ViewChild(FieldsListComponent, {static: false}) fieldsListComponent: FieldsListComponent;
    order: OrderBean;

    constructor(private orderService: OrderService) {
    }

    open(order: OrderBean) {

        this.order = (order ? order : {} as OrderBean);
        this.base.show('Order #' + order.id);
    }

    openWithId(orderId: number) {

        this.order = {} as OrderBean;
        this.base.show('Order #' + orderId);
        this.orderService.getOrder(orderId).subscribe(response => {
            console.log(response);
            if (response.success) {
                this.order = response.value;
            }
        });
    }

    isAdmin() {
        return RightsGuard.isAdmin();
    }

    save() {
        this.orderService.setOrderNote(this.order.id, this.order.note).subscribe(response => {
            console.log(response);
        });
    }
}

