import {AfterViewInit, Component, forwardRef, Inject, ViewChild} from '@angular/core';
import {OrderService} from '../../services/order.service';
import {TableComponent} from '../../components/table.component';
import {AppComponent} from '../../app.component';
import {Column} from '../../common/column';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {OperatorsService} from '../../services/operators.service';
import {KioskService} from '../../services/kiosk.service';
import {Helper} from '../../common/helper';
import {EntityNameBean, OrderBean, OrderState} from '../../model/model';
import {OrdersQuickViewComponent} from './orders.quickview.component';
import {RightsGuard} from '../../common/auth.guard';

@Component({
    templateUrl: 'orders.component.html',
})

export class OrdersComponent implements AfterViewInit {

    @ViewChild(TableComponent, {static: false}) table: TableComponent;
    @ViewChild(OrdersQuickViewComponent, {static: false}) ordersQuickViewComponent: OrdersQuickViewComponent;

    columnsAdmin: Column[] = [
        new Column('date', 'Date').date(),
        new Column('id', 'Id').hide(),
        new Column('operatorName', 'Operator').hide(),
        new Column('kioskName', 'Location'),
        new Column('stateCopy', 'State'),
        new Column('clientType', 'Client'),
        new Column('recipeName', 'Recipe'),
        new Column('customized', 'Customized').checkbox(),
        new Column('userName', 'User'),
        new Column('coupon.name', 'Coupon'),
        new Column('startDate', 'Start').date(),
        new Column('waitTime', 'Wait').shortTime(),
        new Column('processTime', 'Process').shortTime(),
        new Column('deliveryTime', 'Delivery Time').date(),
        new Column('finishDate', 'Finish').time().hide(),
    ];

    columnsUser: Column[] = [
        new Column('date', 'Date').date(),
        new Column('id', 'Id').hide(),
        new Column('operatorName', 'Operator').hide(),
        new Column('kioskName', 'Location'),
        new Column('stateCopy', 'State'),
        new Column('clientType', 'Client'),
        new Column('recipeName', 'Recipe'),
        new Column('customized', 'Customized').checkbox(),
        new Column('coupon.name', 'Coupon'),
        new Column('startDate', 'Start').date(),
        new Column('waitTime', 'Wait').shortTime(),
        new Column('processTime', 'Process').shortTime(),
        new Column('deliveryTime', 'Delivery Time').date(),
        new Column('finishDate', 'Finish').time().hide(),
    ];

    // operators: OperatorBean[] = [];
    // allOperators: OperatorBean = {} as OperatorBean;
    // selectedOperator: OperatorBean = {} as OperatorBean;
    // selectedOperatorId: number;

    kiosks: EntityNameBean[] = [];
    allKiosks: EntityNameBean = {} as EntityNameBean;
    selectedKiosk: EntityNameBean = {} as EntityNameBean;
    selectedKioskId: number;
    compareBeans = Helper.compareBeans;

    selectedState: OrderState;
    query: string;

    constructor(private orderService: OrderService,
                private operatorsService: OperatorsService,
                private kioskService: KioskService,
                private route: ActivatedRoute,
                private router: Router,
                private location: Location,
                @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngAfterViewInit(): void {

        $('#select_kiosk').selectpicker('destroy').selectpicker();

        // this.allOperators.id = 0;
        this.route.queryParams.subscribe(params => {
            // this.selectedOperatorId = params['location'] != null ? params['location'] : 0;
            this.selectedKioskId = params['kiosk'] != null ? params['kiosk'] : 0;

            if (params['state']) {
                this.selectedState = params['state'];
            } else {
                this.selectedState = null;
            }

            if (params['query']) {
                this.query = params['query'];
            } else {
                this.query = null;
            }


            this.loadKiosks();
        });
    }

    // public loadLocations() {
    //     this.operatorsService.getOperators().subscribe(operators => {
    //
    //
    //         this.operators = [];
    //         for (const operator of operators) {
    //             if (operator.status == 'ACTIVE') this.operators.push(operator);
    //         }
    //
    //         if (!this.selectedOperatorId) this.selectedOperator = this.allOperators;
    //         for (const location of operators) {
    //             if (location.id == this.selectedOperatorId)
    //                 this.selectedOperator = location;
    //         }
    //
    //         setTimeout(() => {
    //             $('#select_location').selectpicker('destroy').selectpicker();
    //         }, 1);
    //
    //         this.onOperatorChange();
    //     });
    // }

    loadOrders() {
        this.table.setData([], RightsGuard.isAdmin() ? this.columnsAdmin : this.columnsUser);
    }

    // onOperatorChange() {
    //     this.loadKiosks();
    //     this.updateLocation();
    // }

    onKioskChange() {
        this.loadOrders();
        this.updateLocation();
    }

    updateLocation() {
        let url = '/orders';
        // if (this.selectedOperator != this.allOperators) {
        //     url += ('?location=' + this.selectedOperator.id);
        if (this.selectedKiosk != this.allKiosks && this.selectedKiosk.id) {
            url += ('?kiosk=' + this.selectedKiosk.id); // &
            // }
            if (this.selectedState != null) {
                url += ('&state=' + this.selectedState);
            }
        } else {
            if (this.selectedState != null) {
                url += ('?state=' + this.selectedState);
            }
        }


        this.location.replaceState(url);
    }

    onStateChange() {
        // this.location.replaceState('/operatos?states=' + this.selectedStates)
        this.updateLocation();
        this.loadOrders();
    }

    loadKiosks() {
        // if (!this.selectedOperator.id) {
        this.kioskService.getActiveKiosksNames().subscribe(kiosks => {
            this.setKiosks(kiosks.value);
        });
        // } else {
        //     this.kioskService.getKiosks(this.selectedOperator.id).subscribe(kiosks => {
        //         this.setKiosks(kiosks.list);
        //     })
        // }
    }

    setKiosks(kiosks: EntityNameBean[]) {

        this.kiosks = kiosks;
        this.kiosks.sort((a, b) => a.name.localeCompare(b.name));

        if (!this.selectedKioskId) {
            this.selectedKiosk = this.allKiosks;
        }
        for (const kiosk of this.kiosks) {
            if (kiosk.id == this.selectedKioskId) {
                this.selectedKiosk = kiosk;
            }
        }

        setTimeout(() => {
            $('#select_kiosk').selectpicker('destroy').selectpicker();
            $('#select_state').selectpicker('destroy').selectpicker();
        }, 1);

        this.loadOrders();
    }

    onView(items: OrderBean[]) {
        if (items && items.length > 0) {
            this.router.navigate(['/order-details/'], {queryParams: {id: items[0].id}});
        }
    }

    onRemove(items) {
        const ids = [];
        for (const order of items) {
            ids.push(order.id);
        }

        this.orderService.cancelOrders(ids).subscribe(response => {
            if (response.success) {
                this.loadOrders();

            } else {
                this.app.showError('Can\'t cancel order');
            }
        }, error => {
            this.app.showError('Can\'t cancel order');
        });
    }

    getData(params) {

        this.orderService.getOrders(this.selectedState, this.query, this.selectedKiosk.id, params.data.limit, params.data.offset)
            .subscribe(response => {

            const orders = [];
            for (const order of response.list) {
                // for (const state of this.selectedStates) {
                //     if (state == order.state) {
                order['stateCopy'] = order.state;
                orders.push(order);
                // }
                // }
            }

            params.success({
                total: response.pagesCount * params.data.limit,
                rows: orders
            });
            params.complete();
        });
    }

    onClickRow(item: OrderBean) {
        this.ordersQuickViewComponent.open(item);
    }

}

