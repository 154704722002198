export class Rights {
    public static GUEST = 0;
    public static CUSTOMER = 0x1;
    public static MANAGER = 0x8 | Rights.CUSTOMER;
    public static MANAGE_LOCATIONS = 0x10 | Rights.MANAGER;
    public static MANAGE_KIOSKS = 0x20 | Rights.MANAGER;
    public static MANAGE_CUSTOMERS = 0x40 | Rights.MANAGER;
    public static MANAGE_USERS = 0x80 | Rights.MANAGER;
    public static MANAGE_CATEGORIES = 0x100 | Rights.MANAGER;
    public static MANAGE_RECIPES = 0x200 | Rights.MANAGER;
    public static MANAGE_INGREDIENTS = 0x400 | Rights.MANAGER;
    public static MANAGE_INVENTORY = 0x800 | Rights.MANAGER;
    public static MANAGE_REVIEWS = 0x1000 | Rights.MANAGER;
    public static MANAGE_ORDERS = 0x2000 | Rights.MANAGER;
    public static STORE_MANAGER = 0x4000 | Rights.MANAGER;
    public static HARDWARE_TESTS = 0x8000 | Rights.MANAGER;
    public static DASHBOARD = 0x10000 | Rights.MANAGER;

    public static COMPANY_ADMIN = Rights.MANAGE_LOCATIONS |
        Rights.MANAGE_KIOSKS |
        Rights.MANAGE_CUSTOMERS |
        Rights.MANAGE_USERS |
        Rights.MANAGE_CATEGORIES |
        Rights.MANAGE_RECIPES |
        Rights.MANAGE_INGREDIENTS |
        Rights.MANAGE_INVENTORY |
        Rights.MANAGE_REVIEWS |
        Rights.MANAGE_ORDERS |
        Rights.STORE_MANAGER;

    public static ROOT_ADMIN = 0xFFFFFFF;
}

export class RightsManager {
    public title: string;
    public right: number;

    constructor(title: string, right: number) {
        this.title = title;
        this.right = right;
    }
}
